import type { OrganizationProfile } from "../../../../../client";
import { Button, ImageButton } from "../../../../../components";
import { useUpdateLinkStatus } from "../../../../../services";

import { getImageUrl } from "../../../../../utils/image";
interface Props {
  skillName: string;
  handleClose?: () => void;
}

export const SkillStackDrawerHeader = ({ skillName, handleClose }: Props) => {
  return (
    <div className="flex flex-row justify-between items-center gap-4 px-4 sm:px-5 py-4 bg-gray-1 border-b border-gray-2 rounded-tl-2xl">
      <p className="text-sm hidden sm:block">{`We know ` + skillName + `!`}</p>
      <Button variant="primary" onClick={handleClose}>
        Close
      </Button>
    </div>
  );
};
