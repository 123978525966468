import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Auth0Provider } from "@auth0/auth0-react";

import "./i18n";
import { Provider } from "react-redux";
import { store } from "./store";

const redirectUrl = process.env.REACT_APP_SITE_URL
  ? String(process.env.REACT_APP_SITE_URL)
  : "https://voc.studio";

const root = createRoot(document.getElementById("root")!);

root.render(
  <React.StrictMode>
    <Auth0Provider
      domain="login.voc.studio"
      clientId="sAAAAjx8gXD7W056Bc46niEbTdfCBGMc"
      authorizationParams={{
        redirect_uri: redirectUrl + "/auth",
        audience: "https://profiletool-api.onrender.com",
        scope: "read:profile read:users",
      }}
    >
      <Provider store={store}>
        <App />
      </Provider>
    </Auth0Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
