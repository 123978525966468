import { useEffect, useState } from "react";
import type {
  ImageUpload,
  OrganisationSkillCount,
  OrganizationOverview,
  UserProfile,
} from "../types";
import { motion } from "framer-motion";
import { getOrganization, updateOrganizationLogo } from "../requests";
import Lottie from "react-lottie";
import animationData from "../assets/spinner-animation.json";
import Avatar from "../components/AvatarOld";
import NavigationHeader from "../components/NavigationHeader";
import Button, { ButtonStyleType } from "../components/atoms/Button";
import VkImageCropper from "../components/legacy/VkImageCropper";
import LogoDefault from "../assets/profile-default.png";
import OpOLogo from "../assets/op_o.png";
import SkillIcon from "../components/atoms/SkillIcon";
import SkillBar from "../components/atoms/SkillBar";
import { Dialog } from "@material-ui/core";
import OrgMemberRow from "../components/OrgMemberRow";
import { useNavigate } from "react-router-dom";
import { SkillCount } from "../client";

interface RouteProps {
  organizationId?: string;
}

function OrganizationOverviewPage({ organizationId }: RouteProps) {
  const navigate = useNavigate();
  const [organizationOverview, setOrganizationOverview] =
    useState<OrganizationOverview | null>(null);
  const [showCropTool, setShowCropTool] = useState<boolean>(false);
  const [showSkillDetailDialog, setShowSkillDetailDialog] =
    useState<boolean>(false);
  const [selectedSkill, setSelectedSkill] = useState<string>("");
  const [profileIdsForSelectedSkill, setProfileIdsForSelectedSkill] = useState<
    string[]
  >([]);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [isMouseHovering, setIsMouseHovering] = useState<boolean>(false);
  const [isUploadingNewImage, setIsUploadingNewImage] =
    useState<boolean>(false);
  const [topSkillStackMode, setTopSkillStackMode] =
    useState<string>("projects");
  const [hoverActiveOnSkillName, setHoverActiveOnSkillName] =
    useState<string>("projects");
  const [organisationSkillCount, setOrganisationSkillCount] = useState<
    OrganisationSkillCount[]
  >([]);
  const [maxoOrganisationSkillCount, setMaxOrganisationSkillCount] =
    useState<number>(0);

  useEffect(() => {
    if (!organizationId) return;
    getOrganization(organizationId).then(
      (organizationOverview: OrganizationOverview) => {
        setOrganizationOverview(organizationOverview);
        updateTopSkillStack(organizationOverview, "profiles");
      },
    );
  }, [organizationId]);

  function calcMaxNumberOfSkillsToShow() {
    return Math.floor((window.innerWidth * 0.8) / 43);
  }

  const currentUserIsManager = () => {
    return (
      organizationOverview?.currentOrgProfile.role === "manager" ||
      organizationOverview?.currentOrgProfile.role === "owner"
    );
  };

  var activeS = "";
  function updateTopSkillStack(
    organizationOverview: OrganizationOverview,
    mode: string,
  ) {
    var newOrganisationSkillCount: OrganisationSkillCount[] = [];
    for (var i = 0; i < organizationOverview.profiles.length; i++) {
      if (organizationOverview.profiles[i].profileData.profileSkillSummary) {
        for (
          var j = 0;
          j <
          organizationOverview.profiles[i].profileData.profileSkillSummary!
            .length;
          j++
        ) {
          newOrganisationSkillCount = addSkillCountToOrganisationSkillCount(
            newOrganisationSkillCount,
            organizationOverview.profiles[i].profileData.profileSkillSummary![
              j
            ],
            organizationOverview.profiles[i].profileId,
          );
        }
      }
    }

    setOrganisationSkillCount(newOrganisationSkillCount);
    if (newOrganisationSkillCount.length > 0) {
      let max = 0;
      if (mode === "projects") {
        max = newOrganisationSkillCount.sort(
          compareProjectsInOrganisationSkillCount,
        )[0].projectIds.length;
      } else {
        max = newOrganisationSkillCount.sort(
          compareProfilesInOrganisationSkillCount,
        )[0].profileIds.length;
      }
      setMaxOrganisationSkillCount(max);
      setTopSkillStackMode(mode);
    }
  }

  if (organizationOverview === null) {
    return (
      <div className="flex flex-1 h-[800px] flex-col justify-center align-center ">
        <div>
          <Lottie
            style={{ width: 80 }}
            options={{ animationData: animationData }}
          />
          <label className="font-bold">Booting up manager mode...</label>
        </div>
      </div>
    );
  }

  function compareSkillCount(a: SkillCount, b: SkillCount) {
    if (a.projectIds.length < b.projectIds.length) {
      return 1;
    } else if (a.projectIds.length > b.projectIds.length) {
      return -1;
    }
    return 0;
  }

  function compareProjectsInOrganisationSkillCount(
    a: OrganisationSkillCount,
    b: OrganisationSkillCount,
  ) {
    if (a.projectIds.length < b.projectIds.length) {
      return 1;
    } else if (a.projectIds.length > b.projectIds.length) {
      return -1;
    }
    return 0;
  }

  function compareProfilesInOrganisationSkillCount(
    a: OrganisationSkillCount,
    b: OrganisationSkillCount,
  ) {
    if (a.profileIds.length < b.profileIds.length) {
      return 1;
    } else if (a.profileIds.length > b.profileIds.length) {
      return -1;
    }
    return 0;
  }

  function getTop3Skills(profileSkillSummary: SkillCount[]) {
    const topSkills = profileSkillSummary.sort(compareSkillCount);

    var top3Skills: SkillCount[] = [];
    for (var i = 0; i < 3; i++) {
      if (topSkills[i] != null) {
        top3Skills.push(topSkills[i]);
      }
    }

    return top3Skills;
  }

  function addSkillCountToOrganisationSkillCount(
    organisationSkillCounts: OrganisationSkillCount[],
    skillCount: SkillCount,
    profileId: string,
  ) {
    var found = false;

    for (var i = 0; i < organisationSkillCounts.length; i++) {
      if (organisationSkillCounts[i].name === skillCount.name) {
        organisationSkillCounts[i].projectIds = [
          ...organisationSkillCounts[i].projectIds,
          ...skillCount.projectIds,
        ];
        organisationSkillCounts[i].profileIds.push(profileId);
        found = true;
        break;
      }
    }

    if (!found) {
      organisationSkillCounts.push({
        name: skillCount.name,
        projectIds: skillCount.projectIds,
        profileIds: [profileId],
      });
    }

    return organisationSkillCounts;
  }

  function getProfilesForProjectIds(profileIds: string[]) {
    const profilesWithSkill: UserProfile[] = [];
    for (var i = 0; i < organizationOverview!.profiles.length; i++) {
      if (
        profileIds.indexOf(organizationOverview!.profiles[i].profileId) > -1
      ) {
        const p = organizationOverview!.profiles[i];
        profilesWithSkill.push(p);
      }
    }

    return profilesWithSkill;
  }

  if (showCropTool) {
    return (
      <VkImageCropper
        originalImage={uploadedImage}
        onImageCropperCancelled={() => {
          setShowCropTool(false);
        }}
        onImageCropperComplete={(base64ImageString: string) => {
          // profile.picture_base64_string = base64ImageString;
          // setProfile(profile);
          //saveAndRefresh(profile);
          //setUploadedImage(null);
          setShowCropTool(false);
        }}
      />
    );
  }

  if (!organizationOverview.profiles) {
    return (
      <div>
        <NavigationHeader
          backButtonPath="/controlboard"
          backButtonText="Back"
        />
        <div className="container mx-auto">
          <div className="flex flex-1 flex-col justify-center items-center text-center min-h-[600px]">
            <h1 className="text-2xl font-bold mt-2">Nothing here yet!</h1>

            <label className="font-bold mt-2">
              Your are not managing any other profiles
            </label>
            {organizationOverview.currentOrgProfile.inviteCode != null ? (
              <div className="flex flex-col mt-2 items-center">
                <label>Send the link to people you want to manage</label>
                <label className="font-semibold underline mb-2 mt-6">
                  {"oneprofile.dev/" + organizationOverview.inviteCode}
                </label>
                <Button
                  text="Copy"
                  onButtonClick={() => {
                    const baseUrl = process.env.REACT_APP_SITE_URL
                      ? String(process.env.REACT_APP_SITE_URL)
                      : "https://oneprofile.dev";
                    navigator.clipboard
                      .writeText(
                        baseUrl + "/invites/" + organizationOverview.inviteCode,
                      )
                      .then(
                        () => {
                          /* clipboard successfully set */
                        },
                        () => {
                          /* clipboard write failed */
                        },
                      );
                  }}
                  style={ButtonStyleType.secondary}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
  return (
    <div>
      {organizationOverview.isAdmin &&
      organizationOverview.id !== localStorage.getItem("current-org-id") ? (
        <div className="bg-op-blue w-[100%] h-[30px] text-white flex items-center">
          <label onClick={() => navigate("/admin")} className="underline">
            Back to Admin
          </label>
        </div>
      ) : null}
      <div className="container mx-auto px-[50px] pt-8 pb-10">
        <div className="flex flex-1 items-start">
          <div
            className="relative flex justify-center items-center max-w-[120px] min-h-[120px]"
            onClick={() => {
              document.getElementById("logo-uploader")?.click();
            }}
            onMouseEnter={() => {
              setIsMouseHovering(true);
            }}
            onMouseLeave={() => {
              setIsMouseHovering(false);
            }}
          >
            {!isUploadingNewImage ? (
              <div className="mt-[4px]">
                <img
                  src={
                    organizationOverview.logoId == ""
                      ? OpOLogo
                      : "https://pub-4b15203a4a0f4b26ab71deeffce0164a.r2.dev/" +
                        organizationOverview.logoId
                  }
                  className={`object-scale-contain max-w-[110px] min-w-[110px]`}
                />
                <div
                  style={{
                    position: "absolute",
                    bottom: -2,
                    right: 0,
                    width: "100%",
                    backgroundColor: "black",
                    height: "35px",
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                    visibility: isMouseHovering ? "visible" : "hidden",
                  }}
                >
                  <label
                    style={{
                      color: "#fff",
                      fontSize: "12px",
                      textAlign: "center",
                    }}
                  >
                    Click to change
                  </label>
                </div>
              </div>
            ) : (
              <Lottie
                style={{ width: 80 }}
                options={{ animationData: animationData }}
              />
            )}
          </div>

          <input
            className="hidden"
            type="file"
            id="logo-uploader"
            onChange={(event) => {
              if (event.target.files === null) return;

              const file = event.target.files[0];
              const reader = new FileReader();

              reader.onload = (e: ProgressEvent<FileReader>) => {
                const res = (e.target?.result as string).split(",");
                const fileType = res[0].split(":")[1].split(";")[0];
                const base64String = res[1];

                const imageUpload: ImageUpload = {
                  base64File: base64String,
                  fileType: fileType,
                };
                setIsUploadingNewImage(true);
              };

              reader.readAsDataURL(file);
            }}
          ></input>
          <div className="ml-2 max-w-[60%] flex flex-col justify-between pt-[2px]">
            <div>
              <label className="text-blue-600 text-sm font-semibold">
                {currentUserIsManager() ? "Manager of" : "Member of"}
              </label>
              <h1 className="text-2xl font-bold">
                {organizationOverview.name}
              </h1>
            </div>
            <h6 className="">
              {currentUserIsManager()
                ? "Experience centralized profile management. Customize profiles for all your colleagues, ensuring simplicity and brand awareness. You are in control right here, further down on this page. Happy hunting!"
                : "Join your colleagues in building a strong work experience foundation at Amply. Create your profile, showcase your latest projects, and collaborate with your manager to find or prepare for the next perfect project. Once up and running, you can create multiple versions based on your oneprofile, with just a few clicks. Effortless, fun, sophisticated."}
            </h6>
          </div>
        </div>

        {organisationSkillCount.length > 0 ? (
          <div className="h-[200px] min-h-[200px] flex flex-col mt-14 ">
            <div className="flex flex-1 justify-between">
              <div>
                <h6 className="font-semibold flex-1">Total skill stack</h6>
                <label className="text-sm">{`Showing top ${calcMaxNumberOfSkillsToShow()} skills`}</label>
              </div>

              <div className="flex-1 flex justify-end items-center">
                <Button
                  text="Profiles"
                  onButtonClick={() => {
                    updateTopSkillStack(organizationOverview, "profiles");
                    setOrganizationOverview(organizationOverview);
                  }}
                  style={ButtonStyleType.link}
                  fontWeight={
                    topSkillStackMode === "projects"
                      ? "font-regular"
                      : "font-bold"
                  }
                />
                <div className="min-w-[10px] font-bold">
                  <label>/</label>
                </div>
                <Button
                  text="Projects"
                  onButtonClick={() => {
                    updateTopSkillStack(organizationOverview, "projects");
                    setOrganizationOverview(organizationOverview);
                  }}
                  style={ButtonStyleType.link}
                  fontWeight={
                    topSkillStackMode === "profiles"
                      ? "font-regular"
                      : "font-bold"
                  }
                />
              </div>
            </div>
            <div className="min-h-[80px] flex flex-row border-b-2 border-op-dark items-bottom items-end mt-6">
              {organisationSkillCount
                .sort(
                  topSkillStackMode === "projects"
                    ? compareProjectsInOrganisationSkillCount
                    : compareProfilesInOrganisationSkillCount,
                )
                .map((osc, index) => {
                  if (index < calcMaxNumberOfSkillsToShow()) {
                    if (maxoOrganisationSkillCount > 0) {
                      var height =
                        topSkillStackMode === "projects"
                          ? osc.projectIds.length / maxoOrganisationSkillCount
                          : osc.profileIds.length / maxoOrganisationSkillCount;
                      height = height * 100;
                      return (
                        <SkillBar
                          index={index}
                          height={height}
                          skillName={osc.name}
                          clickable={topSkillStackMode === "profiles"}
                          onHoverStart={(skillName: string) => {
                            setHoverActiveOnSkillName(skillName);
                          }}
                          onHoverEnd={(skillName: string) => {
                            if (skillName === activeS) {
                              setHoverActiveOnSkillName("");
                            }
                          }}
                          onClick={(skillName: string) => {
                            if (topSkillStackMode !== "profiles") {
                              return;
                            }
                            setSelectedSkill(skillName);
                            setProfileIdsForSelectedSkill(osc.profileIds);
                            setShowSkillDetailDialog(true);
                          }}
                          hoverSkillName={hoverActiveOnSkillName}
                          count={
                            topSkillStackMode === "projects"
                              ? osc.projectIds.length
                              : osc.profileIds.length
                          }
                          profiles={getProfilesForProjectIds(osc.profileIds)}
                        />
                      );
                    }
                  }
                })}
            </div>

            <div className="inline-flex flex-1 justify-start mt-2">
              {organisationSkillCount
                .sort(
                  topSkillStackMode === "projects"
                    ? compareProjectsInOrganisationSkillCount
                    : compareProfilesInOrganisationSkillCount,
                )
                .map((osc, index) => {
                  if (index < calcMaxNumberOfSkillsToShow()) {
                    return (
                      <motion.div
                        initial={{ opacity: 0, y: 10, scaleY: 0 }}
                        whileInView={{ opacity: 1, y: 0, scaleY: 1 }}
                        viewport={{ once: true }}
                        transition={{
                          duration: 0.3,
                          damping: 80,
                          delay: index / 20,
                        }}
                        key={index}
                      >
                        <SkillIcon skillName={osc.name} styling="mr-2" />
                      </motion.div>
                    );
                  }
                })}
            </div>
          </div>
        ) : null}

        {organizationOverview.currentOrgProfile.profileId !== "" ? (
          <div>
            <div className="flex flex-row mb-2 mt-14 flex-1 font-semibold min-h-[40px] max-h-[70px] justify-between items-center border-b-[1px] border-b-op-dark">
              <div className="flex-1">
                <h6 className="font-semibold">Your profile</h6>
              </div>
              <div className="flex-1">
                <h6>Title</h6>
              </div>
              <div className="flex-1 text-right">
                <h6>Top skills</h6>
              </div>
            </div>
            <OrgMemberRow
              userProfile={
                organizationOverview.profiles.filter(
                  (up) =>
                    up.profileId ===
                    organizationOverview.currentOrgProfile.profileId,
                )[0]
              }
              handleClick={() =>
                navigate(
                  "/profiles/" +
                    organizationOverview.currentOrgProfile.profileId,
                )
              }
            />
          </div>
        ) : null}

        <div>
          <div className="flex flex-row mb-2 mt-14 flex-1 font-semibold min-h-[40px] max-h-[70px] justify-between items-center border-b-[1px] border-b-op-dark">
            <div className="flex-1">
              <h6 className="font-semibold">Members</h6>
            </div>
            <div className="flex-1">
              <h6>Title</h6>
            </div>
            <div className="flex-1 text-right">
              <h6>Top skills</h6>
            </div>
          </div>
          {organizationOverview.profiles.map((p, index) => {
            if (
              p.profileId !== organizationOverview.currentOrgProfile.profileId
            ) {
              return (
                <OrgMemberRow
                  userProfile={p}
                  key={index}
                  handleClick={() => navigate("/profiles/" + p.profileId)}
                />
              );
            }
          })}

          {organizationOverview.currentOrgProfile.role === "manager" ||
          organizationOverview.currentOrgProfile.role === "owner" ? (
            <div className="flex flex-1 px-2 py-2 min-h-[70px] justify-between items-center border-b-[1px] border-b-op-gray">
              <div>
                <h6 className="text-lg font-semibold">Add members?</h6>
                {organizationOverview.inviteCode != null ? (
                  <div className="flex flex-col mt-2 items-start">
                    <label>
                      Send the link to people you want to add to your
                      organization
                    </label>
                  </div>
                ) : null}
              </div>
              <div>
                <label
                  className="text-op-dark-gray
              underline mb-2 mt-2 mr-4"
                >
                  {"oneprofile.dev/invites/" +
                    organizationOverview.currentOrgProfile.inviteCode}
                </label>
                <Button
                  text="Copy"
                  onButtonClick={() => {
                    const baseUrl = process.env.REACT_APP_SITE_URL
                      ? String(process.env.REACT_APP_SITE_URL)
                      : "https://oneprofile.dev";
                    navigator.clipboard
                      .writeText(
                        baseUrl +
                          "/invites/" +
                          organizationOverview.currentOrgProfile.inviteCode,
                      )
                      .then(
                        () => {
                          /* clipboard successfully set */
                        },
                        () => {
                          /* clipboard write failed */
                        },
                      );
                  }}
                  style={ButtonStyleType.secondary}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <Dialog
        open={showSkillDetailDialog}
        onClose={(event: object, reason: string) => {
          setShowSkillDetailDialog(false);
        }}
        fullWidth
      >
        <div className="p-6">
          <h3 className="text-2xl font-bold mt-4">{selectedSkill}</h3>

          <div className="mt-4">
            {getProfilesForProjectIds(profileIdsForSelectedSkill).map(
              (profile, index) => {
                return (
                  <div
                    className="flex flex-row flex-1 px-2 py-2 min-h-[70px] max-h-[70px] justify-between items-center border-b-[1px] border-b-op-gray cursor-pointer hover:bg-gray-200"
                    onClick={() => {
                      navigate("/profiles/" + profile.profileId);
                    }}
                    key={index}
                  >
                    <div className="flex-1 flex items-center">
                      <div className="min-w-[60px] max-w-[60px]">
                        <Avatar
                          isEditable={false}
                          onShowCropTool={false}
                          imageString={
                            profile.profilePhotoId !== null
                              ? "https://pub-4b15203a4a0f4b26ab71deeffce0164a.r2.dev/" +
                                profile.profilePhotoId
                              : LogoDefault
                          }
                          roundMode={false}
                        />
                      </div>
                      <label>{profile.profileData.name}</label>
                    </div>
                    <div className="flex-1">
                      <p>{profile.profileData.title}</p>
                    </div>
                  </div>
                );
              },
            )}
          </div>
          <div className="flex flex-1 justify-between mt-6">
            <Button
              text="Close"
              onButtonClick={() => {
                setShowSkillDetailDialog(false);
              }}
              style={ButtonStyleType.link}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default OrganizationOverviewPage;
