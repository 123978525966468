import * as PopoverPrimitive from "@radix-ui/react-popover";
import {
  type ComponentPropsWithoutRef,
  type ElementRef,
  forwardRef,
  createContext,
  useContext,
  useState,
  useEffect,
} from "react";
import { cn } from "../utils/cn";

// Create a context to track open popovers
type PopoverContextType = {
  openPopoverId: string | null;
  setOpenPopoverId: (id: string | null) => void;
};

const PopoverContext = createContext<PopoverContextType>({
  openPopoverId: null,
  setOpenPopoverId: () => {},
});

// Create a provider component
export const PopoverProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [openPopoverId, setOpenPopoverId] = useState<string | null>(null);

  return (
    <PopoverContext.Provider value={{ openPopoverId, setOpenPopoverId }}>
      {children}
    </PopoverContext.Provider>
  );
};

// Create a hook to use the context
export const usePopoverContext = () => useContext(PopoverContext);

// Create a unique ID for each popover
let nextId = 0;

// Modified Root component
const Popover = ({ children, ...props }: PopoverPrimitive.PopoverProps) => {
  const [id] = useState(() => `popover-${nextId++}`);
  const { openPopoverId, setOpenPopoverId } = usePopoverContext();

  // Auto-close when another popover opens
  useEffect(() => {
    if (openPopoverId && openPopoverId !== id && props.open) {
      props.onOpenChange?.(false);
    }
  }, [openPopoverId, id, props]);

  const handleOpenChange = (open: boolean) => {
    props.onOpenChange?.(open);
    if (open) {
      setOpenPopoverId(id);
    } else if (openPopoverId === id) {
      setOpenPopoverId(null);
    }
  };

  return (
    <PopoverPrimitive.Root {...props} onOpenChange={handleOpenChange}>
      {children}
    </PopoverPrimitive.Root>
  );
};

// Rest of the components remain largely the same
const PopoverTrigger = forwardRef<
  ElementRef<typeof PopoverPrimitive.Trigger>,
  ComponentPropsWithoutRef<typeof PopoverPrimitive.Trigger>
>(({ className, ...props }, ref) => (
  <PopoverPrimitive.Trigger
    ref={ref}
    className={cn("data-[state=open]:z-50", className)}
    {...props}
  />
));

PopoverTrigger.displayName = PopoverPrimitive.Trigger.displayName;

const PopoverClose = PopoverPrimitive.Close;

const PopoverContent = forwardRef<
  ElementRef<typeof PopoverPrimitive.Content>,
  ComponentPropsWithoutRef<typeof PopoverPrimitive.Content>
>(({ className, align = "center", sideOffset = 4, ...props }, ref) => (
  <PopoverPrimitive.Portal>
    <PopoverPrimitive.Content
      ref={ref}
      align={align}
      sideOffset={sideOffset}
      onPointerDownOutside={(e) => e.preventDefault()}
      onInteractOutside={(e) => e.preventDefault()}
      className={cn(
        "z-[9999] rounded-2xl border border-gray-1 bg-white p-4 text-black shadow-lg outline-none",
        "min-w-[200px] w-fit",
        "data-[state=open]:animate-in data-[state=closed]:animate-out",
        "data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
        "data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95",
        "data-[side=bottom]:slide-in-from-top-2",
        "data-[side=left]:slide-in-from-right-2",
        "data-[side=right]:slide-in-from-left-2",
        "data-[side=top]:slide-in-from-bottom-2",
        className,
      )}
      {...props}
    />
  </PopoverPrimitive.Portal>
));

PopoverContent.displayName = PopoverPrimitive.Content.displayName;

export { Popover, PopoverClose, PopoverContent, PopoverTrigger };
