import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import type React from "react";
import { useState, useEffect } from "react";
import { darkModeBlack } from "../../colors";
import Button, { ButtonStyleType } from "../atoms/Button";
import "../components.css";
import EditorTextField from "../EditorTextField";
import MonthYearSelect from "../MonthYearSelect";
import type { Workplace, WorkplaceItem } from "../../types";
import EditorTextButton from "../atoms/EditorTextButton";
import { getWorkplaces, createWorkplace } from "../../requests";
interface Props {
  open: boolean;
  darkModeActive: boolean;
  onBackdropClick: () => void;
  onSave: (workplace: WorkplaceItem) => void;
  onCancel: () => void;
  onClose: () => void;
  workplaces: WorkplaceItem[];
  isSideProject: boolean;
  onSetAsSideProject: (checked: boolean) => void;
}

const TextInputDialog = (props: Props) => {
  const [input, setInput] = useState<string>("");
  const [workplaceOptions, setWorkplaceOptions] = useState<Workplace[]>([]);

  useEffect(() => {}, []);

  if (!props.open) return null;

  return (
    <div
      className="bg-op-dark absolute top-0 left-0 w-full h-full bg-opacity-50"
      onClick={() => props.onBackdropClick()}
    >
      <div
        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-8 rounded-lg max-h-[90vh] overflow-y-auto min-w-[600px] max-w-[600px]"
        onClick={(e) => e.stopPropagation()}
      >
        {props.workplaces && props.workplaces.length > 0 ? (
          <div className="mb-10">
            <h3 className="text-lg font-semibold">
              Select one of your saved workplaces
            </h3>
            <div className="flex flex-row overflow-x-scroll">
              {props.workplaces.map((option, index) => {
                return (
                  <div key={index}>
                    <EditorTextButton
                      disableEditing={false}
                      isDarkModeActive={props.darkModeActive}
                      text={option.name}
                      handleClick={() => props.onSave(option)}
                      centerText
                    />
                  </div>
                );
              })}
            </div>
            <label>
              Missing one? Add a workplace to your experience section and it
              will show up here
            </label>
            <div className="w-full h-[2px] bg-op-dark mt-4" />
          </div>
        ) : null}

        <div className="flex items-center">
          <input
            type="checkbox"
            checked={props.isSideProject}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              props.onSetAsSideProject(e.currentTarget.checked)
            }
          />
          <label className="ml-1">Set as side project</label>
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flex: 1,
            justifyContent: "flex-end",
            marginTop: 20,
          }}
        >
          <Button
            text="Close"
            onButtonClick={props.onClose}
            style={ButtonStyleType.primary}
          />
        </div>
      </div>
    </div>
  );
};

export default TextInputDialog;
