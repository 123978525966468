import {
  type ComponentProps,
  FocusEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import type { SkillTag, WorkplaceItem } from "../../../../../client";
import {
  Checkbox,
  Input,
  InputRef,
  isString,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../../../components";
import {
  getIconByName,
  iconForName,
} from "../../../../../components/TechIcons";
import { cn } from "../../../../../components/utils/cn";
import { isDefined } from "../../../../../utils/guards";
import { ProjectSettingsContainer } from "./ProjectSettingsContainer";
import { WorkplaceHeader } from "./WorkplaceHeader";
import EditorSkillTagButton from "../../../../../components/atoms/EditorSkillTagButton";
import {
  DynamicInput,
  DynamicInputRef,
} from "../../../../../components/input/DynamicInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBullseye, faInfo } from "@fortawesome/free-solid-svg-icons";
import { TooltipProvider } from "@radix-ui/react-tooltip";

interface Props extends ComponentProps<typeof WorkplaceHeader> {
  description?: string;
  skillTags: SkillTag[];
  showSkills?: boolean;
  showDescription?: boolean;
  checked?: boolean;
  onCheckedChange?: (checked: boolean) => void;
  isEditiable: boolean;
  onWorkplaceEdit: (description: string) => void;
  isEdited: boolean;
}

export const Workplace = ({
  description,
  skillTags,
  showSkills,
  showDescription,
  checked,
  onCheckedChange,
  isEditiable,
  onWorkplaceEdit,
  isEdited,
  ...props
}: Props) => {
  const inputRef = useRef<DynamicInputRef>(null);

  return (
    <div className="flex flex-row items-start gap-4 w-full h-full">
      <div>
        <Checkbox checked={checked} onCheckedChange={onCheckedChange} />
        {isEdited && showDescription ? (
          <TooltipProvider delayDuration={300}>
            <Tooltip>
              <TooltipTrigger asChild>
                <div className="h-full justify-center items-center flex mt-5">
                  <FontAwesomeIcon icon={faBullseye} color="#5167FF" />
                </div>
              </TooltipTrigger>
              <TooltipContent>
                <div className="flex flex-row items-center justify-center p-4 gap-2">
                  <FontAwesomeIcon icon={faInfo} />
                  <p>
                    Content have changed and will not sync with the master
                    profile.
                  </p>
                </div>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        ) : null}
      </div>
      <ProjectSettingsContainer
        className="flex flex-col w-full min-w-0"
        enabled={checked ?? true}
      >
        <WorkplaceHeader {...props} />
        <div className="relative">
          <DynamicInput
            className={cn(
              "text-xs whitespace-pre-line",
              "transition-all duration-300 overflow-hidden",
              showDescription ? "opacity-100" : "max-h-0 opacity-0 pt-0",
            )}
            inputClassName="text-xs"
            disabled={!isEditiable}
            label=""
            validate={isString}
            value={description}
            ref={inputRef}
            rows={1}
            onBlur={(
              event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
            ) => {
              const inputValue = event.target.value;
              onWorkplaceEdit(inputValue);
            }}
          />
        </div>
        <div
          className={cn(
            "flex flex-row flex-wrap gap-2 pt-2 overflow-y-auto max-w-full",
            "transition-all duration-300",
            showSkills && skillTags && skillTags.length > 0
              ? "min-h-[3rem] max-h-none opacity-100 pt-4" // Changed from h-12 to allow dynamic height
              : "max-h-0 opacity-0 scale-y-0 pt-0",
          )}
        >
          {skillTags?.map((skillTag) => (
            <div
              key={skillTag.name}
              className="flex flex-row items-center gap-1 mb-2" // Added margin-bottom for spacing between rows
            >
              <EditorSkillTagButton
                profileSkillTag={skillTag}
                text={skillTag.name}
                disableEditing={true}
                handleClick={() => {}}
                keepLeftPadding={true}
                isStatic={true}
              />
            </div>
          ))}
        </div>
      </ProjectSettingsContainer>
    </div>
  );
};
