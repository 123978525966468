import { useEffect, useState } from "react";
import type {
  Profile,
  ProfileLinkConfig,
  SkillCategoryBlock,
  SkillCount,
  SkillTag,
  UserProfile,
} from "../../../../../client";

import EditorSkillTagButton from "../../../../../components/atoms/EditorSkillTagButton";

interface Props {
  editable: boolean;
  skillCategoryBlocks?: SkillCategoryBlock[];
  hideIcons?: boolean;
  pdfMode?: boolean;
}

export const SkillsBlock = ({
  editable,
  skillCategoryBlocks,
  hideIcons,
  pdfMode,
}: Props) => {
  const [allSkillTags, setAllSkillTags] = useState<SkillTag[]>([]);

  return (
    <div className="flex flex-row flex-wrap w-full gap-1">
      {skillCategoryBlocks
        ?.flatMap((block) => block.skillTags.map((skillTag) => skillTag))
        ?.sort((a, b) => a.name.localeCompare(b.name))
        .map((skillSummary, index) => {
          return (
            <EditorSkillTagButton
              key={index}
              profileSkillTag={skillSummary}
              text={skillSummary.name}
              disableEditing={true}
              handleClick={() => {}}
              keepLeftPadding={true}
              hideIcon={hideIcons ?? false}
              displayInfoOnHover={true}
              pdfMode={pdfMode}
            />
          );
        })}
    </div>
  );
};
