import { useEffect, useState } from "react";
import {
  deleteProfileManager,
  fetchOverview,
  fetchProfileManagerOverview,
  fetchProfileOverview,
  fetchProfileOverviewV2,
} from "../requests";
import { CloseButtonIcon } from "../components/ButtonIcon";
import {
  AccountOverview,
  ProfilePreview,
  type ProfileVisitDisplayModel,
  type ProfileLink,
  type Profile,
  type UIConfig,
  type GuestProfileLink,
  type ProfileManager,
  type UserProfile,
  type ProfileOverview,
} from "../types";
import Button, { ButtonStyleType } from "../components/atoms/Button";
import TextField from "../components/TextField";
import SiteMenu from "../components/SiteMenu";
import PersonalDetailsSection from "../components/PersonalDetailsSection";
import Lottie from "react-lottie";
import animationData from "../assets/spinner-animation.json";
import type { AxiosError } from "axios";
import NavigationHeader from "../components/NavigationHeader";
import Pill from "../components/atoms/Pill";
import ImageButton from "../components/atoms/ImageButton";
import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import { current } from "@reduxjs/toolkit";
import { useNavigate } from "react-router-dom";

interface OverviewProps {
  managerProfileId?: string;
  profileId?: string;
}

function Overview({ managerProfileId, profileId }: OverviewProps) {
  const navigate = useNavigate();

  const [accountProfileId, setAccountProfileId] = useState<string>("");
  const [profileLinks, setProfileLinks] = useState<ProfileLink[]>([]);
  const [darkModeActive, setDarkModeActive] = useState<boolean>(false);
  const [displayProfilePicture, setDisplayProfilePicture] =
    useState<boolean>(false);
  const [newProfileLinkName, setNewProfileLinkName] = useState<string>("");
  const [profilePhotoId, setProfilePhotoId] = useState<string>("");

  const [profileManagers, setProfileManagers] = useState<ProfileManager[]>([]);
  const [isManagerProfile, setIsManagerProfile] = useState<boolean>(false);
  const [profileOverview, setProfileOverview] =
    useState<ProfileOverview | null>(null);

  const [profileIsLoading, setProfileIsLoading] = useState<boolean>(true);
  const [isOnboarding, setIsOnboarding] = useState<boolean>(false);
  const [profileVisitDisplayModels, setProfileVisitDisplayModels] = useState<
    ProfileVisitDisplayModel[]
  >([]);

  const [isViewingAsManager, setIsViewingAsManager] = useState<boolean>(false);
  const [showProfileManagerDialog, setShowProfileManagerDialog] =
    useState<boolean>(false);
  const [isAdminUser, setIsAdminUser] = useState<boolean>(false);

  const [selectedProfileManager, setSelectedProfileManager] =
    useState<UserProfile | null>(null);
  const [guestProfileLinks, setGuestProfileLinks] = useState<
    GuestProfileLink[]
  >([]);

  useEffect(() => {
    if (document.URL.indexOf("onboarding=true") > -1) {
      setIsOnboarding(true);
    }

    refreshPage();
  }, []);

  function refreshPage() {
    let currentProfileId = "";
    if (!profileId) {
      const token = localStorage.getItem("profiletool-auth-token");
      if (token) {
        var base64Url = token.split(".")[1];
        var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        var jsonPayload = decodeURIComponent(
          atob(base64)
            .split("")
            .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
            .join(""),
        );
        const json = JSON.parse(jsonPayload);
        currentProfileId = json.profile_id;
      }
    } else {
      currentProfileId = profileId;
    }

    fetchProfileOverviewV2(currentProfileId)
      .then((profileOverview: ProfileOverview) => {
        setAccountProfileId(profileOverview.profileId);
        const profileLinks: ProfileLink[][] = [];
        for (var i = 0; i < profileOverview.profileLinks.length; i++) {}
        setProfileLinks(profileOverview.profileLinks);
        setProfileOverview(profileOverview);
        setShowProfileManagerDialog(false);
        setGuestProfileLinks(profileOverview.guestProfileLinks ?? []);
        setProfilePhotoId(profileOverview.profilePhotoId ?? "");
        setProfileIsLoading(false);
        setIsAdminUser(document.URL.indexOf("admin") > -1);
        setIsManagerProfile(profileOverview.isManagerProfile);
        if (profileOverview.organizationProfile) {
          setIsViewingAsManager(
            profileOverview.organizationProfile.role == "manager",
          );
        }
      })
      .catch((error: AxiosError) => {
        if (error?.response?.status === 401) {
          localStorage.removeItem("profiletool-auth-token");
          navigate("/auth");
        }
      });
  }

  function getFullLink(linkCode: string) {
    const baseUrl = process.env.REACT_APP_SITE_URL
      ? String(process.env.REACT_APP_SITE_URL)
      : "https://oneprofile.dev";
    return baseUrl + "/p/" + linkCode;
  }

  if (profileIsLoading || profileOverview === null) {
    return (
      <div className="flex flex-1 h-[800px] flex-col justify-center align-center ">
        <div>
          <Lottie
            style={{ width: 80 }}
            options={{ animationData: animationData }}
          />
          <label className="font-bold">
            Loading the controlboard of your career...
          </label>
        </div>
      </div>
    );
  }

  if (!profileOverview) {
    return (
      <div>
        <SiteMenu currentPath="dashboard" isSignedIn={true} />
        <div className="container mx-auto">
          <div className="flex flex-1 flex-col w-full items-center justify-center mt-40">
            <div className="text-center w-[600px] mb-8">
              <div>
                <h2 className="bold text-2xl font-extrabold">
                  Start creating - stop creating
                </h2>
                <p className="mt-4">
                  Once you start building your profile, this is where you will
                  be able to create and manage your profile links and follow any
                  activity! Meaning that you never have to create another
                  profile from skratch again!
                </p>
              </div>
            </div>
            <Button
              text={"Start creating"}
              onButtonClick={() => {
                navigate("onboarding/" + accountProfileId);
              }}
              style={ButtonStyleType.secondary}
            />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      {profileOverview.organizationProfile?.organizationId !== "" ? (
        <NavigationHeader
          backButtonText="Back to organisation"
          backButtonPath={
            "/organizations/" +
            profileOverview.organizationProfile?.organizationId
          }
        ></NavigationHeader>
      ) : (
        <SiteMenu currentPath="controlboard" isSignedIn={true} />
      )}

      {profileOverview.isAdmin &&
      !profileOverview.isCurrentProfile &&
      !profileOverview.isManagerForCurrentProfile ? (
        <div className="bg-op-blue w-[100%] h-[30px] text-white flex items-center">
          <label onClick={() => navigate("/admin")} className="underline">
            Back to Admin
          </label>
        </div>
      ) : null}
      <div className="container mx-auto pb-8">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            paddingTop: "20px",
            paddingLeft: "50px",
            paddingRight: "50px",
            marginBottom: "30px",
          }}
        >
          <PersonalDetailsSection
            isDarkModeActive={false}
            profile={profileOverview.profile.profileData}
            profilePhotoId={profileOverview.profile.profilePhotoId}
            isEditable={false}
            roundedProfilePicture={false}
            onShowCropTool={() => {}}
            onProfileUpdate={() => {}}
            useProfilePicture={true}
            onAddProfileTagClick={() => {}}
            onProfileTagsUpdated={() => {}}
            hidePitch={true}
            isUploadingPhoto={false}
            removePadding={true}
          >
            {profileOverview.isCurrentProfile ||
            profileOverview.isManagerForCurrentProfile ||
            profileOverview.isAdmin ? (
              <div className="flex flex-row">
                <Button
                  text={isOnboarding ? "Create profile" : "Edit profile"}
                  onButtonClick={() => {
                    if (isOnboarding) {
                      navigate("onboarding/" + accountProfileId);
                      return;
                    }
                    navigate("/editor/" + accountProfileId);
                  }}
                  style={ButtonStyleType.primary}
                />
                <div className="w-4" />
              </div>
            ) : null}
          </PersonalDetailsSection>
        </div>
        {isOnboarding ||
        profileLinks.filter((l) => !l.config.dynamic).length === 0 ? (
          <div className="flex flex-1 flex-col w-full items-center justify-center">
            <div className="text-center w-[600px] mb-8">
              <div>
                <h2 className="bold text-2xl font-extrabold">
                  Create your first link
                </h2>
                <p className="mt-4">
                  When creating a link you can decide what to show. This means
                  that you dont need to create different profiles for different
                  applications. Just simply pick what to show from your
                  Oneprofile!
                </p>
              </div>
            </div>
            <Button
              text="Create link"
              onButtonClick={() => {
                if (profileOverview.isManagerForCurrentProfile) {
                  navigate(
                    `/manager/${
                      profileOverview.organizationProfile!.profileId
                    }/profiles/${profileId}/links`,
                  );
                  return;
                }
                navigate("/add-link");
              }}
              style={ButtonStyleType.secondary}
            />
          </div>
        ) : (
          <div className="px-[50px]">
            <div className="flex flex-row justify-between align-baseline mt-5">
              <div>
                <h2 className="text-2xl font-extrabold">
                  {isViewingAsManager
                    ? profileOverview.profile.profileData.name + "'s links"
                    : "Your links"}
                </h2>
                <p>
                  {isViewingAsManager
                    ? "You can use these links directly or create new ones"
                    : "This is your oneprofile links. Imagine it as versions based on your master."}
                </p>
              </div>
            </div>
            <div className="flex flex-col mt-8">
              <div className="flex flex-row flex-1 font-semibold border-b-[1px] border-op-dark pb-2 text-lg">
                <div className="flex-1">
                  <label>Name</label>
                </div>
                <div className="flex-1 hidden md:block">
                  <label>Link</label>
                </div>
                <div className="flex-1 text-right md:text-left">
                  <label>Visits</label>
                </div>
                <div className="flex-1 text-right hidden md:block">
                  <label># of projects</label>
                </div>
              </div>
              {profileLinks
                .filter(
                  (l) =>
                    !l.config.dynamic &&
                    !guestProfileLinks.find((gpl) => {
                      return gpl.link === l.link;
                    }),
                )
                .map((profileLink, index) => {
                  if (
                    profileLink.profileLinkOwner === null &&
                    !profileOverview.isCurrentProfile &&
                    !profileOverview.isManagerForCurrentProfile
                  ) {
                    return null;
                  }
                  return (
                    <div
                      className="flex flex-row flex-1 py-4 justify-between items-center min-h-[65px] border-b-[1px] border-b-op-gray cursor-pointer hover:bg-gray-200"
                      onClick={() => {
                        if (isViewingAsManager) {
                          navigate(
                            `/p/${profileLink.link}?newLinkManagerId=${managerProfileId}&profileId=${profileId}`,
                          );
                        } else {
                          navigate("/p/" + profileLink.link);
                        }
                      }}
                      key={index}
                    >
                      <div className="flex-1">
                        <label>{profileLink.config.name}</label>
                      </div>
                      <div className="flex-1 hidden md:block">
                        <label>{profileLink.link}</label>
                      </div>
                      <div className="flex-1 text-right md:text-left">
                        <label>
                          {profileLink.visitCount +
                            " unique / " +
                            profileLink.totalVisitCount +
                            " total"}
                        </label>
                      </div>
                    </div>
                  );
                })}

              {profileOverview.isCurrentProfile ||
              profileOverview.isManagerForCurrentProfile ||
              profileOverview.isAdmin ? (
                <div className="mt-4 pb-8">
                  <Button
                    text="Create new link"
                    onButtonClick={() => {
                      if (
                        (profileOverview.isManagerForCurrentProfile ||
                          profileOverview.isAdmin) &&
                        !profileOverview.isCurrentProfile
                      ) {
                        navigate(
                          `/manager/${
                            profileOverview.organizationProfile!.profileId
                          }/profiles/${profileId}/links`,
                        );
                        return;
                      }
                      navigate("/add-link");
                    }}
                    style={ButtonStyleType.secondary}
                  />
                </div>
              ) : null}
            </div>
          </div>
        )}
      </div>
      <Dialog
        open={showProfileManagerDialog}
        onClose={(event: object, reason: string) => {
          setShowProfileManagerDialog(false);
          setTimeout(() => {
            setSelectedProfileManager(null);
          }, 500);
        }}
        fullWidth
      >
        <div className="p-6">
          <h3 className="text-2xl font-bold mt-4">
            {`${selectedProfileManager?.profileData.name} can manage your profile`}
          </h3>
          <h4 className="text-xl font-semibold mt-8">What does this mean?</h4>
          <div className="px-8 mb-6">
            <ul className="list-disc">
              <li className="mt-2">
                <label>
                  People who can manage your profile can view your existing
                  Profile Links and create new ones
                </label>
              </li>
              <li className="mt-2">
                <label>
                  They will not be able to edit your profile information or
                  ProfileLinks created by you.
                </label>
              </li>
              <li className="mt-2">
                <label>You can remove this connection at any time</label>
              </li>
            </ul>
          </div>

          <label className="mt-16 font-semibold">
            If you revoke access to the manager any links that they have created
            from your profile will be removed
          </label>
          <div className="flex flex-1 justify-between mt-6">
            <Button
              text="Close"
              onButtonClick={() => {
                setShowProfileManagerDialog(false);
              }}
              style={ButtonStyleType.link}
            />
            <Button
              text="Revoke access"
              onButtonClick={() => {
                setShowProfileManagerDialog(false);
                deleteProfileManager(selectedProfileManager!.profileId).then(
                  () => {
                    setShowProfileManagerDialog(false);
                    refreshPage();
                    setTimeout(() => {
                      setSelectedProfileManager(null);
                    }, 500);
                  },
                );
              }}
              style={ButtonStyleType.danger}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default Overview;
