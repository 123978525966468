import {
  getYears,
  getSelectedMonthValue,
  getSelectedYearValue,
  monthNames,
  monthValues,
} from "../utils";
import "./components.css";
import type { FocusEvent } from "react";
interface YearSelectProps {
  firstOption: string;
  selected?: string;
  label: string;
  onYearSelect: (selectedYear: string) => void;
  disabled?: boolean;
}

const years = getYears();

function YearSelect({
  firstOption,
  selected,
  label,
  onYearSelect,
  disabled,
}: YearSelectProps) {
  return (
    <div className="month-year-select">
      <label className="control-label">{label}</label>
      <div style={{ marginTop: "5px" }}>
        <select
          disabled={disabled}
          value={getSelectedYearValue(selected ?? "")}
          onChange={(e: FocusEvent<any>) => {
            onYearSelect(e.target.value);
          }}
        >
          <option>{firstOption}</option>

          {years.map((year: string) => {
            return (
              <option key={year} value={year}>
                {year}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
}

export default YearSelect;
