import { useMemo } from "react";
import type {
  Profile,
  ProfileLinkConfig,
  SideProject,
} from "../../../../../client";
import { BulletSectionTitle } from "../../../../../components";
import { Workplace } from "./Workplace";

interface Props {
  profileData?: Profile;
  linkConfigData: ProfileLinkConfig;
  handleUpdateLink: (data: Partial<ProfileLinkConfig>) => void;
}

export const LinkCreatorProjects = ({
  profileData,
  linkConfigData,
  handleUpdateLink,
}: Props) => {
  const sortedProjects = useMemo(() => {
    const editedSideProjects = linkConfigData?.editedSideProjects ?? [];

    return profileData?.side_projects_section?.side_projects.map((wp) => {
      const editedSideProject = editedSideProjects.find((w) => w.id === wp.id);
      return editedSideProject ?? wp;
    });
  }, [
    profileData?.side_projects_section?.side_projects,
    linkConfigData?.editedSideProjects,
  ]);

  const handleUpdatedProject = (sideProject: SideProject) => {
    if (!linkConfigData) {
      return;
    }

    // Create a deep copy of the sideProject to avoid reference issues
    const updatedProject = { ...sideProject };

    // Initialize editedSideProjects if needed
    const currentEditedProjects = [
      ...(linkConfigData.editedSideProjects ?? []),
    ];

    // Use findIndex instead of for loop
    const existingIndex = currentEditedProjects.findIndex(
      (project) => project.id === updatedProject.id,
    );

    if (existingIndex >= 0) {
      // Replace existing project
      currentEditedProjects[existingIndex] = updatedProject;
    } else {
      // Add new project
      currentEditedProjects.push(updatedProject);
    }

    // Update with the new array
    handleUpdateLink({ editedSideProjects: currentEditedProjects });
  };

  const handleCheckedChange = (checked: boolean, projectId: string) => {
    const projectsWithout =
      linkConfigData.profileLinkProjects
        ?.sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
        .filter((p) => p.projectId !== projectId && !!p.projectId)
        .map(({ projectId }, index) => ({ projectId, order: index })) ?? [];

    handleUpdateLink({
      profileLinkProjects: checked
        ? [
            ...(projectsWithout ?? []),
            { projectId, order: projectsWithout.length },
          ]
        : projectsWithout,
    });
  };

  const getWorkplaceName = (workplaceId: string) => {
    if (
      profileData &&
      profileData.workplaces.filter((wp) => wp.id === workplaceId).length > 0
    ) {
      return profileData.workplaces.filter((wp) => wp.id === workplaceId)[0]
        .name;
    }
    return "";
  };

  if (!sortedProjects || (sortedProjects && sortedProjects.length === 0)) {
    return null;
  }

  return (
    <div className="flex flex-col gap-4">
      <BulletSectionTitle>Projects</BulletSectionTitle>
      <div className="flex flex-col gap-6">
        {sortedProjects &&
          sortedProjects.map((project) => (
            <Workplace
              key={project.id}
              checked={
                !linkConfigData.profileLinkProjects ||
                linkConfigData.profileLinkProjects.some(
                  (p) => p.projectId === project.id,
                )
              }
              onCheckedChange={(checked) =>
                handleCheckedChange(checked, project.id)
              }
              name={
                project.isSideProject
                  ? "Side project"
                  : getWorkplaceName(project.workplaceId ?? "")
              }
              skillTags={project.skillTags}
              title={project.title}
              description={project.pitch}
              showSkills={linkConfigData.projectsLinkConfig?.displaySkills}
              showDescription={
                linkConfigData.workplaceLinkConfig?.displayDescription
              }
              isEditiable={true}
              onWorkplaceEdit={(description: string) => {
                var updatedProject = { ...project };
                updatedProject.pitch = description;
                handleUpdatedProject(updatedProject);
              }}
              isEdited={
                linkConfigData?.editedSideProjects?.find(
                  (w) => w.id === project.id,
                ) !== undefined
              }
            />
          ))}
      </div>
    </div>
  );
};
