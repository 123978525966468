import React, { useState, useEffect } from "react";
import Button, { ButtonStyleType } from "./atoms/Button";
import {
  type ProfileSkillTag,
  type SideProject,
  Workplace,
  type ProfileComponentProps,
  ImageUpload,
  ProjectPhoto,
  type ContentSuggestionRequestBody,
  type WorkplaceItem,
  type WorkplaceLinkConfig,
  EducationItem,
} from "../types";
import { GitHub, Link, Loader, Plus, Settings, Target } from "react-feather";
import "./components.css";
import EditorTextButton from "./atoms/EditorTextButton";
import SkillTag from "./SkillTag";
import EditorSkillTagButton from "./atoms/EditorSkillTagButton";
import EditorIconButton from "./atoms/EditorIconButton";
import VkTextArea from "./legacy/VkTextArea";
import EditorTextField from "./EditorTextField";
import { Menu } from "react-feather";
import dayjs from "dayjs";
import animationData from "../assets/spinner-animation.json";
import { getContentImprovementSuggestion } from "../requests";
import Lottie from "react-lottie";
interface WorkplaceEditorProps extends ProfileComponentProps {
  education: any;
  isEditable: boolean;
  isNew: boolean;
  onSaveItem: (education: EducationItem) => void;
  onCancelEdit?: () => void;
  onRemove: () => void;
  onShowDialog: (dialogType: string, educationId: number) => void;
  isBeingEdited: boolean;
  isLastInOrder: boolean;
  onContentImprovementStarted?: () => void;
  onContentImprovementEnded?: () => void;
  workplaceName?: string;
  workplaceLinkConfig?: WorkplaceLinkConfig;
  numberOfConnectedProjects?: number;
  removePadding?: boolean;
  pdfMode?: boolean;
  staticMode?: boolean;
  onMoveUp?: (order: number) => void;
  onMoveDown?: (order: number) => void;
  educationCount?: number;
}

function EducationEditor({
  isDarkModeActive,
  isEditable,
  education,
  isNew,
  onSaveItem,
  isBeingEdited,
  onCancelEdit,
  onRemove,
  onShowDialog,
  isLastInOrder,
  onContentImprovementStarted,
  onContentImprovementEnded,
  workplaceLinkConfig,
  numberOfConnectedProjects,
  removePadding,
  pdfMode,
  staticMode,
  onMoveUp,
  onMoveDown,
  educationCount,
}: WorkplaceEditorProps) {
  const [currentEducation, setCurrentEducation] = useState<EducationItem>(
    () => {
      return { ...education };
    },
  );

  useEffect(() => {
    setCurrentEducation({ ...education });
  }, [education]);

  const [contentSuggestionRequestBody, setContentSuggestionRequestBody] =
    useState<ContentSuggestionRequestBody | null>(null);
  const [isMouseHovering, setIsMouseHovering] = useState<boolean>(false);
  const [showSidebar, setShowSidebar] = useState<boolean>(false);
  const [pitchHaveFocus, setPitchHaveFocus] = useState<boolean>(false);

  const [isLoadingContentSuggestion, setIsLoadingContentSuggestion] =
    useState<boolean>(false);
  const [currentImproventSuggestions, setCurrentImproventSuggestions] =
    useState<string>("");

  const [showWriterAssistant, setShowWriterAssistant] =
    useState<boolean>(false);

  const contentSuggestionActions = [
    "Less formal",
    "More formal",
    "Shorter",
    "Funnier",
    "Less braggy",
  ];

  const onTitleInput = (input: string) => {
    const newEducation = { ...currentEducation, name: input };
    onSaveItem(newEducation);
    setCurrentEducation(newEducation);
  };

  const parseDate = (dateString: string) => {
    if (dateString === "" || dateString === "End Year") {
      return "";
    }

    return dayjs(dateString, "YYYY").format("YYYY");
  };

  return (
    <div
      className={"education-editor"}
      style={{
        position: "relative",
        display: "flex",
        flexDirection: "row",
        flex: 1,
      }}
      onMouseEnter={() => setIsMouseHovering(true)}
      onMouseLeave={() => setIsMouseHovering(false)}
    >
      <div className="flex flex-1 flex-row">
        {showSidebar ? (
          <div className="flex-1 flex flex-col items-center align-center">
            <EditorTextButton
              text="Remove"
              handleClick={() => onRemove()}
            ></EditorTextButton>
            {currentEducation.order > 0 ? (
              <EditorTextButton
                text="Move up"
                handleClick={() => {
                  if (onMoveUp) {
                    onMoveUp(currentEducation.order);
                    setShowSidebar(false);
                  }
                }}
              ></EditorTextButton>
            ) : null}
            {educationCount && currentEducation.order < educationCount - 1 ? (
              <EditorTextButton
                text="Move down"
                handleClick={() => {
                  if (onMoveDown) {
                    onMoveDown(currentEducation.order);
                    setShowSidebar(false);
                  }
                }}
              ></EditorTextButton>
            ) : null}
          </div>
        ) : null}

        <div className="flex flex-[5] flex-col">
          <div style={{ flex: 1, flexDirection: "row" }}>
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {removePadding === true ? null : (
                <div
                  className={
                    "border-[1px] border-op-dark mb-[4px] max-w-[18px] max-h-[18px] min-w-[18px] min-h-[18px] flex justify-center items-center" +
                    (isMouseHovering && isEditable ? " visible" : " invisible")
                  }
                  onClick={() => {
                    setShowSidebar(!showSidebar);
                  }}
                >
                  <Settings size={12} color="#202124" />
                </div>
              )}

              <div
                className={
                  "flex flex-1 pr-1 " +
                  (staticMode === true
                    ? ""
                    : removePadding === true
                      ? "pl-6"
                      : "pl-4")
                }
              >
                <div className="grow">
                  {isEditable ? (
                    <EditorTextField
                      disableEditing={!isEditable}
                      disableHoverUnderline={!isEditable}
                      isDarkModeActive={isDarkModeActive}
                      placeholder="Education, course or certificate name"
                      onLeaveField={onTitleInput}
                      inputStyles={{
                        fontSize: 14,
                        fontWeight: "normal",
                        marginBottom: "8px",
                      }}
                      value={currentEducation.name}
                    />
                  ) : (
                    <span className="text-[14px] font-normal mb-[8px]">
                      {currentEducation.name}
                    </span>
                  )}
                </div>
                <div>
                  {isEditable ? (
                    <EditorTextButton
                      disableEditing={!isEditable}
                      text={
                        currentEducation.startDate === ""
                          ? "when"
                          : parseDate(currentEducation.startDate) +
                            (currentEducation.startDate &&
                            currentEducation.endDate
                              ? " - "
                              : "") +
                            (currentEducation.endDate === "" ||
                            currentEducation.endDate === "End Year"
                              ? ""
                              : parseDate(currentEducation.endDate))
                      }
                      handleClick={() => {
                        onShowDialog("education-dates", 0);
                      }}
                    />
                  ) : (
                    <label className="text-details-text whitespace-nowrap">
                      {currentEducation.startDate === ""
                        ? ""
                        : parseDate(currentEducation.startDate) +
                          (currentEducation.startDate &&
                          currentEducation.endDate
                            ? " - "
                            : "") +
                          (currentEducation.endDate === "" ||
                          currentEducation.endDate === "End Year"
                            ? ""
                            : parseDate(currentEducation.endDate))}
                    </label>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EducationEditor;
