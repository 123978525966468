import {
  type ComponentProps,
  type ComponentPropsWithoutRef,
  type ElementRef,
  forwardRef,
  useState,
} from "react";
import { Drawer as DrawerPrimitive } from "vaul";
import { cn } from "../utils/cn";
import { DrawerPortal } from "./Drawer";
import { DrawerOverlay } from "./Overlay";

type Direction = ComponentProps<typeof DrawerPrimitive.Root>["direction"];
type OnClose = ComponentProps<typeof DrawerPrimitive.Root>["onClose"];

const DrawerContent = forwardRef<
  ElementRef<typeof DrawerPrimitive.Content>,
  ComponentPropsWithoutRef<typeof DrawerPrimitive.Content> & {
    direction?: Direction;
    onClose?: OnClose;
  }
>(({ className, children, direction = "bottom", ...props }, ref) => {
  const [isDragging, setIsDragging] = useState(true);

  return (
    <DrawerPortal>
      <DrawerOverlay onClick={props.onClose} />
      <DrawerPrimitive.Content
        ref={ref}
        data-dragging={isDragging}
        style={{
          // Prevent drawer movement when dragging
          touchAction: isDragging ? "none" : "auto",
          userSelect: isDragging ? "none" : "auto",
        }}
        className={cn(
          "fixed z-50 flex flex-col bg-white",
          (direction === "bottom" || direction === "top") && "inset-x-0",
          (direction === "left" || direction === "right") && "inset-y-0",
          direction === "bottom" && "bottom-0 rounded-t-2xl mt-24",
          direction === "top" && "top-0 rounded-b-2xl mb-24",
          direction === "left" && "left-0 rounded-r-2xl mr-12",
          direction === "right" && "right-0 rounded-l-2xl ml-12",
          className,
        )}
        {...props}
      >
        {children}
      </DrawerPrimitive.Content>
    </DrawerPortal>
  );
});

DrawerContent.displayName = "DrawerContent";

export { DrawerContent };
