import { queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import { getV1OrganizationsByOrganizationIdProfilesByProfileIdMatchOptions } from "../../../client/@tanstack/react-query.gen";
import type { QueryConfig } from "../../utils/query-config";

export const organizationProfileSkillsQueryOptions = (
  organizationId: string,
  profileId: string,
  skills: string[] = [],
  enabled: boolean = false,
) => {
  return queryOptions({
    ...getV1OrganizationsByOrganizationIdProfilesByProfileIdMatchOptions({
      path: { organizationID: organizationId, profileID: profileId },
      query: { skills },
    }),
    enabled, // Pass the flag explicitly here
  });
};

export const useGetOrganizationProfileSkills = (
  organizationId: string,
  profileId: string,
  skills: string[] = [],
  config?: QueryConfig,
) => {
  return useSuspenseQuery({
    ...organizationProfileSkillsQueryOptions(
      organizationId,
      profileId,
      skills,
      config?.enabled ?? true,
    ),
    ...config,
  });
};
