import { useParams } from "react-router-dom";
import { useGetOrganization, useGetProfile } from "../../../../../services";
import type { Lead } from "../../../../../types";
import { MemberItem } from "./MemberItem";
import {
  BulletSectionTitle,
  Button,
  IconButton,
  Image,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../../../components";
import { LeadRowCardSection } from "../card/LeadRowCardSection";
import { homeState } from "../../../../../state/home";
import { getImageUrl } from "../../../../../utils/image";
import {
  AssignmentInfo,
  ProfileVersion,
  UserProfile,
} from "../../../../../client";
import { faClose, faUser } from "@fortawesome/free-solid-svg-icons";
import { useAnimate } from "../../../../../hooks";
import { LeadRowCard } from "../card/LeadRowCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "sonner";
import { useGetMatch } from "../../../../../services/organtization/get-match";
import { useMemo } from "react";
import { iconForName } from "../../../../../components/TechIcons";
import { TooltipProvider } from "@radix-ui/react-tooltip";
import { cn } from "../../../../../components/utils/cn";
import SkillsIcon from "../../../../../components/atoms/SkillsIcon";

interface Props {
  profileId?: string;
  profileVersions: ProfileVersion[];
  onMutateLead: (lead: Partial<Lead>) => void;
  profileLinkId?: string;
  leadId?: string;
  assignmentInfo?: AssignmentInfo;
  profileCanBeReplaced: boolean;
}

export const MemberPanel = ({
  profileId,
  profileVersions,
  onMutateLead,
  profileLinkId,
  leadId,
  assignmentInfo,
  profileCanBeReplaced,
}: Props) => {
  const { organizationId } = useParams();
  if (!organizationId) throw new Error("Organization ID is required");
  const [parent] = useAnimate();
  const { data: profileData } = useGetProfile({
    organizationId,
    profileId: profileId ?? "",
  });

  const { data, error } = useGetOrganization(organizationId);

  let link = `${window.location.origin}/p/`;

  const { data: skillMatchData, error: skillMatchError } = useGetMatch(
    {
      organizationId: organizationId,
      skills: assignmentInfo?.skillTags?.map((sk) => sk.name) ?? [],
    },
    { enabled: !!assignmentInfo },
  );

  const onEdit = (profileId: string) => {
    updateHomeState({
      linkCreatorLeadId: leadId ?? null,
    });
  };

  const updateHomeState = (updates: Partial<typeof homeState>) => {
    Object.assign(homeState, updates);
  };

  const getMatchingSkills = (profileId: string) => {
    if (
      skillMatchData &&
      skillMatchData.some((skillMatch) => skillMatch.profileId === profileId)
    ) {
      // Find the matching skills array for the given profileId
      const matchingSkills =
        skillMatchData.find((skillMatch) => skillMatch.profileId === profileId)
          ?.matchingSkillTags || [];

      // Return the items from 'skills' that match the names in 'matchingSkills'
      if (skills) {
        return skills.filter((skill) =>
          matchingSkills.some(
            (matchingSkill) => matchingSkill.name === skill?.name,
          ),
        );
      }
    }
    return [];
  };

  const skills = useMemo(() => {
    return assignmentInfo?.skillTags?.map((skill) => skill).filter(Boolean); // Remove undefined values
  }, [assignmentInfo?.skillTags]);

  if (profileId && !profileData) {
    return <p>j</p>;
  }

  if (error || !data) throw new Error("Error fetching organization data");

  var selectedProfile: UserProfile | null = null;
  if (profileId) {
    selectedProfile = data.profiles.filter((p) => p.profileId === profileId)[0];
  }

  return (
    <div
      className="flex flex-1 flex-col md:flex-row md:max-h-[400px] gap-6"
      ref={parent}
    >
      <LeadRowCard
        className={cn(
          "overflow-scroll md:max-h-[400px] md:flex",
          selectedProfile && "hidden",
        )}
      >
        <LeadRowCardSection title="Pick Member for Lead">
          {data.profiles
            .filter((p) => p.profileId !== profileId)
            .sort((a, b) => {
              const matchingSkillsA = getMatchingSkills(a.profileId);
              const matchingSkillsB = getMatchingSkills(b.profileId);
              return matchingSkillsB.length - matchingSkillsA.length; // Descending order
            })
            .map((profile) => (
              <MemberItem
                selected={profile.profileId === profileId}
                onClick={() => {
                  onMutateLead({
                    profileId: profile.profileId,
                  });
                }}
                key={profile.profileId}
                disabled={!(!selectedProfile && profileId !== "")}
                {...profile}
                matchingSkills={getMatchingSkills(profile.profileId)}
              />
            ))}
        </LeadRowCardSection>
      </LeadRowCard>

      <LeadRowCard className="flex-1 max-h-[400px]">
        {selectedProfile && profileId ? (
          <div className="h-full min-h-[400px]">
            <LeadRowCard
              type="dashed"
              className="text-sm px-4 mb-4 rounded-2xl flex flex-1 flex-row justify-between"
            >
              <BulletSectionTitle>Lead Profile</BulletSectionTitle>
              <div>
                <div className="flex flex-1 items-center gap-3">
                  <Button
                    variant="secondary"
                    onClick={() => {
                      window.open(link + `${profileLinkId}`, "_blank");
                    }}
                  >
                    View
                  </Button>
                  <Button
                    variant="primary"
                    onClick={() => {
                      onEdit(profileId);
                    }}
                  >
                    Edit
                  </Button>
                </div>
              </div>
            </LeadRowCard>
            <div className="flex flex-col justify-center items-center">
              <div className="flex flex-1 flex-col items-center justify-center relative mt-6">
                <div className="relative">
                  <Image
                    src={
                      selectedProfile.profilePhotoId
                        ? getImageUrl(selectedProfile.profilePhotoId)
                        : undefined
                    }
                    alt={
                      typeof selectedProfile.profileData.name === "string"
                        ? selectedProfile.profileData.name
                        : "Profile"
                    }
                    className="aspect-square w-24 h-24 rounded-2xl md:w-24 md:h-24"
                  />
                  {profileCanBeReplaced === true && (
                    <IconButton
                      className="absolute top-[-5px] right-[-5px] bg-gray-2 rounded-md z-10"
                      size="small"
                      icon={faClose}
                      iconProps={{
                        className: "text-black", // Pass custom class for the icon
                      }}
                      onClick={() =>
                        onMutateLead({
                          profileId: undefined,
                        })
                      }
                    />
                  )}
                </div>
                <h5 className="text-md font-semibold mt-2">
                  {typeof selectedProfile.profileData.name === "string"
                    ? selectedProfile.profileData.name
                    : "Profile"}
                </h5>
                <p className="text-sm whitespace-pre-line text-center">
                  {`This Lead Profile is unique to the
                    assignment. You can customise it
                    right here without changing the
                    master profile.`}
                </p>

                {getMatchingSkills(selectedProfile.profileId) && (
                  <div className="flex gap-1 my-2">
                    {getMatchingSkills(selectedProfile.profileId).map(
                      (skill, index) => (
                        <TooltipProvider
                          key={`${skill.name}-${index}`}
                          delayDuration={0}
                        >
                          <Tooltip>
                            <TooltipTrigger asChild>
                              <div className="w-6 h-6">
                                <SkillsIcon text="" profileSkillTag={skill} />
                              </div>
                            </TooltipTrigger>
                            <TooltipContent className="mb-2 z-10 bg-white">
                              <p>{skill.name}</p>
                            </TooltipContent>
                          </Tooltip>
                        </TooltipProvider>
                      ),
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="h-full min-h-[400px] flex flex-1 justify-center items-center">
            <LeadRowCard className="bg-red-2 text-black text-sm">
              <div className="flex flex-1 items-center gap-3">
                <FontAwesomeIcon
                  width={14}
                  height={14}
                  icon={faUser}
                  className="w-4 h-4 shrink-0 text-red-3"
                />
                <p>
                  <span className="font-bold">Pick a Member</span> to generate a{" "}
                  <br />
                  unique Lead Profile for this Lead
                </p>
              </div>
            </LeadRowCard>
          </div>
        )}
      </LeadRowCard>
    </div>
  );
};

MemberPanel.displayName = "MemberPanel";
