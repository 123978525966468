import { Options } from "@hey-api/client-fetch";
import {
  PostV1OrganizationsByOrganizationIdProfilesData,
  PostV1OrganizationsByOrganizationIdProfilesError,
  PostV1OrganizationsByOrganizationIdProfilesResponse,
  PostV1SkillsCategoriesData,
  PostV1SkillsCategoriesError,
  PostV1SkillsCategoriesResponse,
} from "../../client";
import {
  useMutation,
  UseMutationOptions,
  useQueryClient,
} from "@tanstack/react-query";
import {
  postV1OrganizationsByOrganizationIdProfilesMutation,
  postV1SkillsCategoriesMutation,
} from "../../client/@tanstack/react-query.gen";

const mutationKey = ["getSkillCategories"];

export const useGetSkillCategories = (
  options: UseMutationOptions<
    PostV1SkillsCategoriesResponse,
    PostV1SkillsCategoriesError,
    Options<PostV1SkillsCategoriesData>
  > = {},
) => {
  const queryClient = useQueryClient();
  return useMutation({
    ...postV1SkillsCategoriesMutation(),
    mutationKey,
    ...options,
    onSuccess: (categoriesResponse, vars, context) => {
      options?.onSuccess?.(categoriesResponse, vars, context);
    },
  });
};
