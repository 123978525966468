import { useParams } from "react-router-dom";
import {
  Button,
  LoadingSpinner,
  Notice,
  Section,
  SectionTitle,
} from "../../../components";
import { useAnimate } from "../../../hooks";
import {
  useCreateLead,
  useGetLeads,
  useGetOrganization,
} from "../../../services";
import { ExistingLeadRow } from "./components/ExistingLeadRow";
import { LeadRow } from "./components/LeadRow";
import { MobileMenuDrawer } from "./components/MobileMenuDrawer";
import { LinkCreatorDrawer } from "./components/link-creator/LinkCreatorDrawer";
import React, { Suspense, useCallback, useEffect, useState } from "react";
import { ArchivedLeads } from "./components/ArchivedLeads";
import { Loading } from "../layout/components/Loading";
import { ContractDrawer } from "./components/contracts/ContractDrawer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { homeState } from "../../../state/home";
import { SkillStackDrawer } from "./components/skills/SkillStackDrawer";
import { jsonParse } from "../../../services/utils/parse";
import { Lead } from "../../../client";
import { useSnapshot } from "valtio";
import { useMediaQuery } from "react-responsive";

const SkillStackSection = React.lazy(
  () => import("../home/components/SkillStackSection"),
);

const blankLead: Lead = {
  id: "",
  clientId: "",
  title: "",
  description: "",
  contactPersonName: "",
  contactPersonEmail: "",
  contactPersonPhone: "",
  contractId: "",
  status: "",
  numberOfUnreadEvents: 0,
  // Will always get overridden before being sent to the backend
  orgId: "",
  profileVersions: [],
};

const Component = () => {
  const { organizationId } = useParams();
  if (!organizationId) throw new Error("Organization ID is required");
  const [shouldFetch, setShouldFetch] = useState(false);

  const { data } = useGetLeads(organizationId);
  const { data: orgData, error } = useGetOrganization(organizationId);

  useEffect(() => {
    if (!homeState.organizationIconName) {
      homeState.organizationIconName = orgData.iconLogoId
        ? orgData.iconLogoId
        : null;
    }
  }, [orgData]);

  const createLead = useCreateLead({
    onSuccess: (lead) => {},
  });

  const [parent] = useAnimate();

  if (!data) return <p>No data</p>;

  return (
    <LinkCreatorDrawer>
      <ContractDrawer>
        <SkillStackDrawer>
          <MobileMenuDrawer>
            <div className="max-h-[300px] mb-10">
              <Suspense fallback={<LoadingSpinner size={"small"} />}>
                <SkillStackSection
                  organizationId={organizationId}
                  enabled={false}
                  ref={parent}
                  onBarClick={(skillName: string) => {
                    homeState.skillStackSkillName = skillName;
                  }}
                />
              </Suspense>
            </div>
            <Section
              title="Leads"
              className="lg:overflow-x-scroll"
              containerComponent="ul"
              ref={parent}
            >
              <div
                className="rounded-2xl border border-gray-1 h-[72px] bg-white overflow-hidden group flex items-center justify-center hover:cursor-pointer hover:border-2 hover:border-gray-2"
                onClick={() => {
                  createLead.mutate({
                    body: {
                      ...blankLead,
                      orgId: organizationId,
                    },
                    path: {
                      organizationID: organizationId,
                    },
                  });
                }}
              >
                <FontAwesomeIcon
                  width={10}
                  height={10}
                  size="sm"
                  icon={faCirclePlus}
                  className="text-red-3 w-8 h-8 group-hover:scale-150 transition-transform duration-200"
                />
              </div>

              {data
                .filter((l) => !l.contractId)
                .map((lead) => (
                  <ExistingLeadRow
                    key={lead.id}
                    organizationId={organizationId}
                    lead={lead}
                  />
                ))}
            </Section>

            <Notice
              className="bg-transparent"
              label="Contracts can be found under each members page"
            />

            {shouldFetch ? (
              <div>
                <div className="flex flex-1 w-full justify-center items-center">
                  <Button onClick={() => setShouldFetch(false)}>
                    Hide archived leads
                  </Button>
                </div>
                <div>
                  <Suspense fallback={<Loading />}>
                    <ArchivedLeads organizationId={organizationId} />
                  </Suspense>
                </div>
              </div>
            ) : (
              <div className="flex flex-1 w-full justify-center items-center pb-20">
                <Button onClick={() => setShouldFetch(true)}>
                  Show archived leads
                </Button>
              </div>
            )}
          </MobileMenuDrawer>
        </SkillStackDrawer>
      </ContractDrawer>
    </LinkCreatorDrawer>
  );
};

Component.displayName = "DashboardHome";

export default Component;
