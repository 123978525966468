import {
  queryOptions,
  useQuery,
  UseQueryOptions,
  useSuspenseQuery,
} from "@tanstack/react-query";
import type {
  GetV1OrganizationsByOrganizationIdMatchData,
  GetV1OrganizationsByOrganizationIdMatchError,
  GetV1OrganizationsByOrganizationIdMatchResponse,
  GetV1OrganizationsByOrganizationIdResponse,
} from "../../client";
import {
  getV1OrganizationsByOrganizationIdLeadsByLeadIdMatchOptions,
  getV1OrganizationsByOrganizationIdMatchOptions,
  getV1OrganizationsByOrganizationIdOptions,
} from "../../client/@tanstack/react-query.gen";
import { jsonParse } from "../utils/parse";
import { QueryConfig } from "../utils/query-config";

export const getLeadMatchOptions = (
  organizationId: string,
  leadId: string,
  skills?: string[],
) =>
  queryOptions({
    ...getV1OrganizationsByOrganizationIdLeadsByLeadIdMatchOptions({
      path: { organizationID: organizationId, leadID: leadId },
      query: skills ? { skills: skills } : undefined,
    }),
    staleTime: 0, // 5 minutes
  });

type GetMatchInput = {
  organizationId: string;
  leadId: string;
  skills?: string[];
};

export const useGetLeadMatch = (
  { organizationId, leadId, skills }: GetMatchInput,
  config?: QueryConfig,
) => {
  return useQuery({
    ...getLeadMatchOptions(organizationId, leadId, skills),
    ...config,
  });
};
