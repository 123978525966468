import type { PropsWithChildren } from "react";
import { cn } from "../utils/cn";
import { PopoverClose, PopoverContent } from "./Popover";

type Props = PropsWithChildren<{
  className?: string;
}>;

export const PopoverMenu = ({ children, className }: Props) => (
  <PopoverContent className={cn("z-[9999] flex flex-col p-4", className)}>
    {children}
  </PopoverContent>
);

type ItemProps = PropsWithChildren<{
  onClick: () => void;
  className?: string;
}>;

const PopoverMenuItem = ({ children, className, onClick }: ItemProps) => (
  <PopoverClose asChild>
    <button
      type="button"
      onClick={onClick}
      className={cn(
        "z-[9999] flex flex-row items-center gap-2 rounded-2xl px-4 py-5 text-sm transition-all hover:bg-gray-1 active:scale-95",
        className,
      )}
    >
      {children}
    </button>
  </PopoverClose>
);

PopoverMenu.Item = PopoverMenuItem;

PopoverMenu.displayName = "PopoverMenu";
