import { useState, useEffect } from "react";
import { getStatsigClient } from "../statsig";
import type { StatsigClient } from "@statsig/js-client";
export const useCurrentOrgId = (): string | null => {
  const [orgId, setOrgId] = useState<string | null>(null);

  useEffect(() => {
    const storedOrgId = localStorage.getItem("current-org-id");
    if (storedOrgId) {
      setOrgId(storedOrgId);
    }
  }, []);

  return orgId;
};

export const useCurrentProfileId = (): string | null => {
  const [currentProfileId, setCurrentProfileId] = useState<string | null>(null);

  useEffect(() => {
    const storedProfileId = localStorage.getItem("current-profile-id");
    if (storedProfileId) {
      setCurrentProfileId(storedProfileId);
    }
  }, []);

  return currentProfileId;
};

export function useStatsigClient() {
  const [statsig, setStatsig] = useState<StatsigClient | null>(null);

  useEffect(() => {
    try {
      const client = getStatsigClient();
      setStatsig(client);
    } catch (error) {
      console.error("Failed to get Statsig client:", error);
    }
  }, []);

  return statsig;
}
