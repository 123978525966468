import { queryOptions, useSuspenseQuery } from "@tanstack/react-query";
import type { GetV1LinksByProfileLinkResponse } from "../../client";
import { getV1LinksByProfileLinkOptions } from "../../client/@tanstack/react-query.gen";
import { jsonParse } from "../utils/parse";
import type { QueryConfig } from "../utils/query-config";

export const getLinkOptions = (profileLink: string) =>
  queryOptions({
    ...getV1LinksByProfileLinkOptions({
      path: { profileLink },
    }),
    staleTime: 0,
  });

export const useGetLink = (profileLink: string, config?: QueryConfig) => {
  return useSuspenseQuery({
    ...getLinkOptions(profileLink),
    ...config,
    staleTime: 0,
    networkMode: "always",
    select: (data) => jsonParse<GetV1LinksByProfileLinkResponse>(data),
  });
};
