import { Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { darkModeBlack } from "../../colors";
import Button, { ButtonStyleType } from "../atoms/Button";
import "../components.css";
import YearSelect from "../YearSelect";
interface Props {
  open: boolean;
  darkModeActive: boolean;
  onBackdropClick: () => void;
  onCancel: () => void;
  onClose: () => void;
  onDateUpdate: (startDate: string, endDate: string) => void;
  selectedStartDate: string;
  selectedEndDate: string;
}

const WorkplaceDatesDialog = (props: Props) => {
  const [selectedStartYearValue, setSelectedStartYearValue] =
    useState<string>("");
  const [selectedEndYearValue, setSelectedEndYearValue] = useState<string>("");

  useEffect(() => {
    if (props.selectedStartDate !== "") {
      setSelectedStartYearValue(props.selectedStartDate);
    }

    if (props.selectedEndDate !== "") {
      setSelectedEndYearValue(props.selectedEndDate);
    }
  }, []);

  return (
    <div
      className="bg-op-dark absolute top-0 left-0 w-full h-full bg-opacity-50"
      onClick={() => props.onBackdropClick()}
    >
      <div
        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-8 rounded-lg max-h-[90vh] overflow-y-auto"
        onClick={(e) => e.stopPropagation()}
      >
        <h6 className="text-xl font-bold">Workplace dates</h6>

        <YearSelect
          firstOption="Start Year"
          label="When did you start here?"
          onYearSelect={(selectedYear: string) => {
            setSelectedStartYearValue(selectedYear);
            props.onDateUpdate(selectedYear, selectedEndYearValue);
          }}
          selected={selectedStartYearValue}
        />

        <YearSelect
          disabled={!selectedStartYearValue}
          firstOption="End Year"
          label="When (if) did you leave this position? Leave empty if this is your current position"
          onYearSelect={(selectedYear: string) => {
            setSelectedEndYearValue(selectedYear);
            props.onDateUpdate(selectedStartYearValue, selectedYear);
          }}
          selected={selectedEndYearValue}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flex: 1,
            justifyContent: "flex-end",
            marginTop: 20,
          }}
        >
          <Button
            text="Close"
            onButtonClick={props.onClose}
            style={ButtonStyleType.primary}
          />
        </div>
      </div>
    </div>
  );
};

export default WorkplaceDatesDialog;
