import { useState } from "react";
import type { ClientWithContact, UserProfile } from "../../../../../client";
import { Button, ImageButton } from "../../../../../components";
import { useUpdateLinkStatus } from "../../../../../services";

import { getImageUrl } from "../../../../../utils/image";
interface Props {
  profile?: UserProfile;
  client?: ClientWithContact;
  handleClose?: () => void;
  handleReset?: () => void;
}

export const LinkCreatorHeader = ({
  profile,
  client,
  handleClose,
  handleReset,
}: Props) => {
  const status = useUpdateLinkStatus();
  const [showConfirm, setShowConfirm] = useState<boolean>(false);

  const isPending = status === "pending";

  return (
    <div className="flex flex-row justify-between items-center gap-4 px-4 sm:px-5 py-4 bg-gray-1 border-b border-gray-2 rounded-tl-2xl">
      <div className="flex flex-row sm:px-4 items-center justify-center gap-2">
        <ImageButton
          imageUrl={
            profile?.profilePhotoId
              ? getImageUrl(profile.profilePhotoId)
              : undefined
          }
          alt={
            typeof profile?.profileData.name === "string"
              ? profile.profileData.name
              : "Profile"
          }
          indicatorProps={
            client?.logoId
              ? {
                  variant: "image",
                  imageUrl: getImageUrl(client.logoId),
                  alt: "Logo",
                }
              : undefined
          }
        />
        <p className="text-sm hidden sm:block">Edit unique Lead Profile</p>
      </div>
      <div className="flex gap-3">
        {showConfirm ? (
          <div className="flex flex-row text-xs max-w-[450px] gap-1">
            <label>
              Are you sure you want to reset the profile? All custom changes for
              this Profile Lead will be removed
            </label>
            <Button
              variant="secondary"
              onClick={() => setShowConfirm(false)}
              disabled={false}
            >
              {"Cancel"}
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                setShowConfirm(false);
                if (handleReset) {
                  handleReset();
                }
              }}
              disabled={isPending}
            >
              {"Confirm"}
            </Button>
          </div>
        ) : (
          <div className="flex flex-row gap-2">
            <Button
              variant="secondary"
              onClick={() => setShowConfirm(true)}
              disabled={false}
            >
              {"Reset"}
            </Button>
            <Button
              variant="primary"
              onClick={handleClose}
              disabled={isPending}
            >
              {isPending ? "Saving..." : "Close"}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
