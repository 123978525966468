import { proxy } from "valtio";

export type LeadRowActiveState =
  | "client"
  | "assignment"
  | "member"
  | "qr"
  | "events"
  | false;

type SelectedLead = {
  leadId: string | null;
  state: LeadRowActiveState;
};

export const homeState = proxy<{
  selectedLead: SelectedLead | null;
  linkCreatorLeadId: string | null;
  contractCreatorLeadId: string | null;
  skillStackSkillName: string | null;
  organizationIconName: string | null;
}>({
  selectedLead: null,
  linkCreatorLeadId: null,
  contractCreatorLeadId: null,
  skillStackSkillName: null,
  organizationIconName: null,
});

export const getNextLeadRowActiveState = (
  prev: LeadRowActiveState,
  state: LeadRowActiveState,
) => (prev === state ? false : state);
