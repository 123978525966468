import type { Options } from "@hey-api/client-fetch";
import {
  useMutation,
  useMutationState,
  useQueryClient,
} from "@tanstack/react-query";
import type {
  PutV1LinksByProfileLinkResetData,
  PutV1LinksByProfileLinkResetError,
  PutV1LinksByProfileLinkResetResponse,
} from "../../client";
import { putV1LinksByProfileLinkResetMutation } from "../../client/@tanstack/react-query.gen";
import { getLinkOptions } from "./get-link";

const mutationKey = ["resetLink"];

const getLinkQueryKey = (profileLink: string) =>
  getLinkOptions(profileLink).queryKey;

export const useResetLink = () => {
  const queryClient = useQueryClient();
  return useMutation<
    PutV1LinksByProfileLinkResetResponse,
    PutV1LinksByProfileLinkResetError,
    Options<PutV1LinksByProfileLinkResetData>
  >({
    ...putV1LinksByProfileLinkResetMutation(),
    mutationKey,
    onSettled: async (_, __, { path: { profileLink } }) => {
      await queryClient.invalidateQueries({
        queryKey: getLinkQueryKey(profileLink),
      });
    },
  });
};

export const useUpdateLinkStatus = () => {
  return useMutationState({
    filters: { mutationKey },
    select: (mutation) => mutation.state.status,
  }).at(-1);
};
