import type { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faBuilding, faUser } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dayjs from "dayjs";
import { type PropsWithChildren, useMemo } from "react";
import defaultUserImage from "../../../../assets/op_o.png";
import type { UserProfile } from "../../../../client";
import { Image } from "../../../../components";
import { iconForName } from "../../../../components/TechIcons";
import { getImageUrl } from "../../../../utils/image";

interface Props {
  organizationLogo?: string | null;
  profileName: string;
  profilePhotoId?: string;
}

export const NewMemberCard = ({
  profileName,
  profilePhotoId,
  organizationLogo,
}: Props) => {
  return (
    <div className="rounded-4xl border border-gray-1 bg-white overflow-hidden group">
      <div className="aspect-square overflow-hidden">
        <Image
          alt={profileName}
          src={profilePhotoId ? getImageUrl(profilePhotoId) : defaultUserImage}
          className="object-cover group-hover:scale-110 group-active:scale-105 transition-transform"
        />
      </div>
      <div className="px-2 pt-4 pb-8 sm:px-4 flex flex-col gap-6 md:gap-4 text-sm text-black">
        <ContentRow>
          <div className="grow-0 flex items-center">
            {organizationLogo && (
              <ImageOrIcon src={organizationLogo} icon={faUser} />
            )}
          </div>
          <div className="grow shrink flex flex-col min-w-0">
            <h3 className="font-bold line-clamp-1">{profileName}</h3>
          </div>
        </ContentRow>
      </div>
    </div>
  );
};

NewMemberCard.displayName = "NewMemberCard";

const ContentRow = ({ children }: PropsWithChildren) => (
  <div className="flex flex-row grow items-center gap-1 md:gap-2">
    {children}
  </div>
);

type ImageOrIconProps = {
  src?: string;
  icon?: IconProp;
};

const ImageOrIcon = ({ src, icon }: ImageOrIconProps) => (
  <>
    <Image
      alt="Logo"
      src={src}
      className="aspect-square w-10 rounded-xl hidden md:block"
    />
    {icon && (
      <FontAwesomeIcon
        width={14}
        height={14}
        icon={icon}
        className="text-black p-2 block md:hidden"
      />
    )}
  </>
);
