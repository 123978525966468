import type { Profile, SkillCategoryBlock } from "../../../../../client";
import { cn } from "../../../../../components/utils/cn";
import EditorSkillTagButton from "../../../../../components/atoms/EditorSkillTagButton";
import { faDisplay } from "@fortawesome/free-solid-svg-icons";

interface SkillsDisplayProps {
  skillCategoryBlocks: SkillCategoryBlock[];
  displayIcons?: boolean;
  pdfMode?: boolean;
}

const CategoryBlock = ({
  category,
  displayIcons,
  pdfMode,
}: {
  category: SkillCategoryBlock;
  displayIcons?: boolean;
  pdfMode?: boolean;
}) => {
  return (
    <div className="flex-1 min-w-0">
      <h3 className="text-md font-semibold mb-2">{category.title}</h3>
      <div className={cn("flex flex-wrap gap-2 rounded-lg flex-row")}>
        {category.skillTags
          ?.sort((a, b) => a.name.localeCompare(b.name))
          .map((skillTag) => (
            <div key={skillTag.name} className="inline-block">
              <EditorSkillTagButton
                profileSkillTag={skillTag}
                text={skillTag.name}
                disableEditing={true}
                handleClick={() => {}}
                keepLeftPadding={true}
                hideIcon={!displayIcons}
                displayInfoOnHover={true}
                pdfMode={pdfMode}
              />
            </div>
          ))}
        {(!category.skillTags || category.skillTags.length === 0) && (
          <div className="text-gray-400 italic">No skills in this category</div>
        )}
      </div>
    </div>
  );
};

export const SkillSummary = ({
  skillCategoryBlocks,
  displayIcons = true,
  pdfMode,
}: SkillsDisplayProps) => {
  // Helper function to split skills into rows of two
  const getSkillRows = () => {
    const rows = [];
    for (let i = 0; i < skillCategoryBlocks.length; i += 2) {
      rows.push(skillCategoryBlocks.slice(i, i + 2));
    }
    return rows;
  };

  return (
    <div className="flex flex-col gap-4 pl-7">
      {getSkillRows().map((row, rowIndex) => (
        <div
          key={`row-${rowIndex}`}
          className="flex flex-col gap-4 mb-2 justify-between"
        >
          {row
            .filter((c) => c.skillTags && c.skillTags.length > 0)
            .map((category) => (
              <CategoryBlock
                key={category.title}
                category={category}
                displayIcons={displayIcons}
                pdfMode={pdfMode}
              />
            ))}
          {row.length === 1 && <div className="flex-1 min-w-0"></div>}
        </div>
      ))}
    </div>
  );
};
