import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import type {
  AccountProfile,
  Branding,
  Logotype,
  Profile,
  ProfileContactDetails,
  ProfileLinkConfig,
  ProfileLinkPersonalInfoConfig,
  ProfileLinkProject,
  SectionTypeDescription,
  SideProject,
  WorkplaceItem,
} from "../types";
import {
  fetchProfile,
  fetchProfileLinkSettings,
  fetchProfileLinkSettingsAsGuest,
  fetchProfileManagerProfile,
  getCurrentOrgManagers,
  updateNewProfileLink,
} from "../requests";
import Lottie from "react-lottie";
import animationData from "../assets/spinner-animation.json";
import { darkModeBlack } from "../colors";
import Button, { ButtonStyleType } from "./atoms/Button";
import ContentToggle from "./ContentToggle";
import PersonalDetailsSection from "./PersonalDetailsSection";
import VkTextArea from "./legacy/VkTextArea";
import EditorTitle from "./EditorTitle";
import WorkplaceSection from "./WorkplaceSection";
import SideProjectSection from "./SideProjectSection";
import ContactBox from "./ContactBox";
import LinkSetingToggle from "./LinkSettingToggle";
import { Slider } from "@material-ui/core";

interface Props {
  profileLinkId?: string;
  managerProfileId?: string;
  profileId?: string;
}

function LinkEditor({ profileLinkId, profileId, managerProfileId }: Props) {
  const navigate = useNavigate();
  const [darkModeActive, setDarkModeActive] = useState<boolean>(false);
  const [isGuestUser, setIsGuestUser] = useState<boolean>(false);
  const [currentOrgManagers, setCurrentOrgManagers] = useState<
    ProfileContactDetails[]
  >([]);
  const [profileLink, setProfileLink] = useState("");
  const [localAddedLogo, setLocalAddedLogo] = useState<string | null>(null);
  const [showLinkNameSettings, setShowLinkNameSettings] =
    useState<boolean>(false);
  const [logoSize, setLogoSize] = useState<number>(40);
  const [profileLinkConfig, setProfileLinkConfig] =
    useState<ProfileLinkConfig | null>(null);
  const [currentProfileSideProjects, setCurrentProfileSideProjects] = useState<
    SideProject[]
  >([]);
  const [profilePhotoId, setProfilePhotoId] = useState<string>("");

  const contentRef = useRef<any>(null);

  const [profile, setProfile] = useState<Profile>({
    id: "",
    profile_name: "",
    name: "",
    title: "",
    pitch: "",
    ui_config: { dark_mode: false, use_profile_picture: true },
    roles_section: { order: 0, title: "", roles: [] },
    skills_section: { order: 0, title: "", skill_blocks: [] },
    side_projects_section: { order: 0, title: "", side_projects: [] },
    profile_tags: [],
    workplaces: [],
    educations: [],
  });

  useEffect(() => {
    if (profileLinkId !== undefined) {
      if (document.URL.indexOf("manager") > -1) {
        setIsGuestUser(true);
        fetchProfileLinkSettingsAsGuest(managerProfileId!, profileLinkId).then(
          (accountProfile) => {
            prepareLinkData(accountProfile, profileLinkId, true);
          },
        );
      } else {
        fetchProfileLinkSettings(profileLinkId).then((accountProfile) => {
          prepareLinkData(accountProfile, profileLinkId, false);
        });
      }
    } else {
      if (document.URL.indexOf("manager") > -1) {
        setIsGuestUser(true);
        if (managerProfileId && profileId) {
          fetchProfileManagerProfile(managerProfileId, profileId).then(
            (accountProfile) => {
              prepareView(accountProfile);
            },
          );
        }
      } else {
        if (localStorage.getItem("profiletool-auth-token") !== null) {
          const decodedToken = parseJwt(
            localStorage.getItem("profiletool-auth-token"),
          );
          fetchProfileById(decodedToken.profile_id);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (profileLinkConfig) {
      updateNewProfileLink(profileLink, profileLinkConfig)
        .then(() => {})
        .catch((err) => {
          console.log(err);
        });
    }
  }, [profileLinkConfig]);

  const getSelectedContactDetails = () => {
    if (profileLinkConfig?.contactUsLinkConfig.contactProfileId !== "") {
      return currentOrgManagers.filter(
        (om) =>
          om.profileId ===
          profileLinkConfig?.contactUsLinkConfig.contactProfileId,
      )[0];
    }
  };

  function prepareLinkData(
    accountProfile: AccountProfile,
    profileLinkId: string,
    dynamic: boolean,
  ) {
    setProfile(accountProfile.profile);
    setProfilePhotoId(accountProfile.profilePhotoId ?? "");
    setProfileLink(profileLinkId);
    if (accountProfile.linkConfig) {
      setDarkModeActive(accountProfile.linkConfig.darkMode);
    }

    for (
      let i = 0;
      i < accountProfile.profile.side_projects_section!.side_projects!.length;
      i++
    ) {
      accountProfile.linkConfig?.profileLinkProjects?.forEach((project) => {
        if (
          project.projectId ===
          accountProfile.profile.side_projects_section!.side_projects![i].id
        ) {
          accountProfile.profile.side_projects_section!.side_projects![
            i
          ].order = project.order;
        }
      });
    }

    const sortedSideProjects =
      accountProfile.profile.side_projects_section?.side_projects?.sort(
        (a: SideProject, b: SideProject) => {
          return a.order - b.order;
        },
      ) || [];

    setCurrentProfileSideProjects(sortedSideProjects);

    const existingLinkConfig = { ...accountProfile.linkConfig! };

    if (existingLinkConfig.workplaceLinkConfig === undefined) {
      existingLinkConfig.workplaceLinkConfig = {
        displaySkills: true,
        displayConnectedProjects: true,
        displayDescription: true,
      };
    }
    if (existingLinkConfig.projectsLinkConfig === undefined) {
      existingLinkConfig.projectsLinkConfig = { displaySkills: true };
    }

    if (existingLinkConfig.contactUsLinkConfig === undefined) {
      existingLinkConfig.contactUsLinkConfig = {
        displayContactUs: false,
        contactProfileId: "",
        phone: "",
      };
    }

    setProfileLinkConfig(existingLinkConfig);
    if (accountProfile.linkConfig?.branding) {
      setLogoSize(accountProfile.linkConfig?.branding.logotypes[0].size || 40);
    }

    fetchCurrentManagers(accountProfile);
  }

  function fetchProfileById(profileId: string) {
    fetchProfile(profileId).then((accountProfile) => {
      prepareView(accountProfile);
    });
  }

  function prepareView(accountProfile: AccountProfile) {
    setProfile(accountProfile.profile);
    setDarkModeActive(accountProfile.profile.ui_config.dark_mode);
    setProfilePhotoId(accountProfile.profilePhotoId ?? "");
    setCurrentProfileSideProjects(
      accountProfile.profile.side_projects_section?.side_projects || [],
    );

    const newProfileLinkPersonalInfoConfig: ProfileLinkPersonalInfoConfig = {
      showName: true,
      showPersonalInfo: true,
      showPitch: true,
      roundMode: false,
    };

    const workplaceIds =
      accountProfile.profile.workplaces != undefined
        ? [...accountProfile.profile.workplaces.map((wp) => wp.id)]
        : [];

    const newProfileLinkConfig: ProfileLinkConfig = {
      darkMode: accountProfile.profile.ui_config.dark_mode,
      useProfilePicture: true,
      name: "",
      profileLinkPersonalInfoConfig: newProfileLinkPersonalInfoConfig,
      profileLinkProjects: [],
      dynamic: false,
      showCustomPitch: false,
      customPitch: "",
      profileLinkWorkplaceIds: [...workplaceIds],
      workplaceLinkConfig: {
        displaySkills: true,
        displayDescription: true,
        displayConnectedProjects: true,
      },
      projectsLinkConfig: {
        displaySkills: true,
      },
      contactUsLinkConfig: {
        displayContactUs: false,
        contactProfileId: "",
        phone: "",
      },
      showAssignmentPitch: false,
      assignmentPitch: "",
      displayEducationSection: true,
      skillsSummaryConfig: {},
      editedWorkplaces: [],
      editedSideProjects: [],
    };
    setProfileLinkConfig(newProfileLinkConfig);

    fetchCurrentManagers(accountProfile);
  }

  const fetchCurrentManagers = (accountProfile: AccountProfile) => {
    const currentOrgId = localStorage.getItem("current-org-id");
    if (currentOrgId) {
      getCurrentOrgManagers(currentOrgId).then(
        (userContactDetails: ProfileContactDetails[]) => {
          if (
            userContactDetails.filter(
              (user) => user.profileId === accountProfile.profile.id,
            ).length === 0
          ) {
            const user: ProfileContactDetails = {
              profileId: accountProfile.profile.id,
              name: accountProfile.profile.name,
              email: accountProfile.email,
              profilePhotoId: accountProfile.profilePhotoId ?? "",
              logoId: "",
              phoneNumber: "",
              website: "",
            };
            userContactDetails.push(user);
          }
          setCurrentOrgManagers(userContactDetails);
        },
      );
    }
  };

  function parseJwt(token: string | null) {
    if (token) {
      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
          .join(""),
      );

      return JSON.parse(jsonPayload);
    }
  }

  function getAssetUrl(branding: Branding | null) {
    if (branding === null) return null;
    if (branding.logotypes.length > 0) {
      return branding.logotypes[0].assetUrl;
    }
    return null;
  }

  const getSectionsInOrder = () => {
    const availableSections: SectionTypeDescription[] = [];
    if (profile.roles_section !== null && profile.roles_section !== undefined) {
      const role: SectionTypeDescription = {
        title: "Work Experience",
        info: "Describe your previous roles and workplaces",
        type: "roles",
        order: profile.roles_section!.order,
      };
      availableSections.push(role);
    }

    if (
      profile.skills_section !== null &&
      profile.skills_section !== undefined
    ) {
      const skills: SectionTypeDescription = {
        title: "Skills",
        info: "Express your skills",
        type: "skills",
        order: profile.skills_section!.order,
      };
      availableSections.push(skills);
    }

    if (
      profile.side_projects_section !== null &&
      profile.side_projects_section !== undefined &&
      profile.side_projects_section.side_projects.length > 0
    ) {
      const skills: SectionTypeDescription = {
        title: "Projects",
        info: "Add any other project that does not fit under work experience",
        type: "side-projects",
        order: profile.side_projects_section!.order,
      };
      availableSections.push(skills);
    }

    const sectionsInOrder = availableSections.sort((a, b) => a.order - b.order);
    return sectionsInOrder;
  };

  if (profileLinkConfig === null) {
    return (
      <div className="flex flex-1 h-[800px] flex-col justify-center align-center ">
        <div>
          <Lottie
            style={{ width: 80 }}
            options={{ animationData: animationData }}
          />
          <label className="font-bold">Loading Profile Link...</label>
        </div>
      </div>
    );
  }

  const linkConfig = profileLinkConfig;
  return (
    <div
      style={{
        color: darkModeActive ? "white" : darkModeBlack,
      }}
    >
      <div
        className="flex flex-col xl:flex-row xl:overflow-scroll"
        style={{
          height: "100%",
        }}
        ref={contentRef}
      >
        <div className="flex flex-2 px-8 pt-[24px] flex-col border-b-2 xl:border-r-2 border-op-dark xl:overflow-scroll overscroll-contain">
          <div>
            <h3 className="text-xl font-bold mb-7">Pick what to include</h3>

            <ContentToggle
              toggled={
                linkConfig.profileLinkPersonalInfoConfig.showPersonalInfo
              }
              dragHandle={() => {
                return <label></label>;
              }}
              renderToggleOnContent={() => {
                return (
                  <PersonalDetailsSection
                    isDarkModeActive={linkConfig.darkMode}
                    profile={profile}
                    profilePhotoId={profilePhotoId}
                    isEditable={false}
                    useProfilePicture={linkConfig.useProfilePicture}
                    roundedProfilePicture={
                      linkConfig.profileLinkPersonalInfoConfig.roundMode
                    }
                    onShowCropTool={() => {}}
                    onProfileUpdate={() => {}}
                    onAddProfileTagClick={() => {}}
                    onProfileTagsUpdated={() => {}}
                    hidePitch={true}
                    logoAssetUrl={
                      localAddedLogo ?? getAssetUrl(linkConfig.branding || null)
                    }
                    logoAssetSize={logoSize}
                    isUploadingPhoto={false}
                    removePadding={true}
                  />
                );
              }}
              renderToggleOffContent={() => {
                return (
                  <div>
                    <label>Personal details</label>
                  </div>
                );
              }}
              onToggleOn={() => {
                setProfileLinkConfig({
                  ...linkConfig,
                  profileLinkPersonalInfoConfig: {
                    ...linkConfig.profileLinkPersonalInfoConfig,
                    showPersonalInfo: true,
                  },
                });
              }}
              onToggleOff={() => {
                setProfileLinkConfig({
                  ...linkConfig,
                  profileLinkPersonalInfoConfig: {
                    ...linkConfig.profileLinkPersonalInfoConfig,
                    showPersonalInfo: false,
                  },
                });
              }}
            />

            {profile.pitch !== "" ? (
              <ContentToggle
                dragHandle={() => {
                  return <label></label>;
                }}
                renderToggleOffContent={() => {
                  return (
                    <h4 className="self-center">
                      {profile.pitch !== ""
                        ? "Show profile pitch"
                        : "Add a profile pitch in the Profile Editor to include it in your links"}
                    </h4>
                  );
                }}
                renderToggleOnContent={() => {
                  return (
                    <div className="w-[100%]">
                      <VkTextArea
                        disableHoverUnderline={true}
                        isEditable={false}
                        isDarkModeActive={false}
                        placeholder="Describe yourself, your profession, your passion or anything you would like to share with potential colleagues"
                        onLeaveField={(input: string) => {}}
                        value={profile.pitch}
                        onTextAreaFocus={() => {}}
                        inputType="pitch"
                      />
                    </div>
                  );
                }}
                onToggleOff={() => {
                  setProfileLinkConfig({
                    ...linkConfig,
                    profileLinkPersonalInfoConfig: {
                      ...linkConfig.profileLinkPersonalInfoConfig,
                      showPitch: false,
                    },
                  });
                }}
                onToggleOn={() => {
                  setProfileLinkConfig({
                    ...linkConfig,
                    profileLinkPersonalInfoConfig: {
                      ...linkConfig.profileLinkPersonalInfoConfig,
                      showPitch: true,
                    },
                  });
                }}
                toggled={
                  linkConfig.profileLinkPersonalInfoConfig.showPitch &&
                  profile.pitch !== ""
                }
              />
            ) : null}

            <ContentToggle
              dragHandle={() => {
                return <label></label>;
              }}
              renderToggleOffContent={() => (
                <h4 className="self-center">Display custom link pitch</h4>
              )}
              renderToggleOnContent={() => {
                return (
                  <div className="w-[100%]">
                    <VkTextArea
                      disableHoverUnderline={false}
                      isEditable={true}
                      isDarkModeActive={false}
                      placeholder="Add an unique pitch to address something extra in this profile link"
                      onLeaveField={(input: string) => {
                        setProfileLinkConfig({
                          ...linkConfig,
                          customPitch: input,
                        });
                      }}
                      value={linkConfig.customPitch}
                      onTextAreaFocus={() => {}}
                      inputType="pitch"
                    />
                  </div>
                );
              }}
              onToggleOff={() => {
                setProfileLinkConfig({
                  ...linkConfig,
                  showCustomPitch: false,
                });
              }}
              onToggleOn={() => {
                setProfileLinkConfig({
                  ...linkConfig,
                  showCustomPitch: true,
                });
              }}
              toggled={linkConfig.showCustomPitch}
            />

            <div className="mt-10 mb-7">
              <EditorTitle title="Work experience" />
            </div>

            <WorkplaceSection
              configurable={true}
              sideProjectSection={{
                ...profile.side_projects_section!,
                side_projects: currentProfileSideProjects,
              }}
              isDarkModeActive={darkModeActive}
              sectionCount={getSectionsInOrder().length}
              workplaces={profile.workplaces}
              isEditable={false}
              onAddItem={(newSideProject) => {}}
              onUpdateItem={(updatedSideProject) => {}}
              onRemoveItem={(sideProjectToRemove) => {}}
              onDeleteSectionClick={() => {}}
              onShowDialog={() => {}}
              onProjectOrderChange={(projects: SideProject[]) => {}}
              onToggle={(workplace: WorkplaceItem, toggled: boolean) => {
                const config = Object.assign({}, profileLinkConfig);
                if (toggled) {
                  if (!config.profileLinkWorkplaceIds) {
                    config.profileLinkWorkplaceIds = [];
                  }
                  setProfileLinkConfig({
                    ...config,
                    profileLinkWorkplaceIds: [
                      ...config.profileLinkWorkplaceIds,
                      workplace.id,
                    ],
                  });
                } else {
                  setProfileLinkConfig({
                    ...config,
                    profileLinkWorkplaceIds: [
                      ...config.profileLinkWorkplaceIds.filter(
                        (wpId) => wpId !== workplace.id,
                      ),
                    ],
                  });
                }
              }}
              toggledWorkplaceIds={profileLinkConfig.profileLinkWorkplaceIds}
              workplaceLinkConfig={profileLinkConfig.workplaceLinkConfig}
              sideProjects={profile.side_projects_section?.side_projects}
            />

            <div className="mt-4 mb-7">
              <EditorTitle title="Projects" />
            </div>

            <SideProjectSection
              profileId={profile.id}
              configurable={true}
              sideProjectSection={{
                ...profile.side_projects_section!,
                side_projects: currentProfileSideProjects,
              }}
              isDarkModeActive={darkModeActive}
              projectPhotos={[]}
              sectionCount={getSectionsInOrder().length}
              workplaces={profile.workplaces}
              isEditable={false}
              onAddSideProject={(newSideProject) => {}}
              onUpdateSideProject={(updatedSideProject) => {}}
              onRemoveSideProject={(sideProjectToRemove) => {}}
              onDeleteSectionClick={() => {}}
              onShowDialog={() => {}}
              onProjectOrderChange={(projects: SideProject[]) => {
                setCurrentProfileSideProjects(projects);
                const newListOfIncludedSideProjects: ProfileLinkProject[] = [
                  ...profileLinkConfig.profileLinkProjects,
                ];
                for (let i = 0; i < projects.length; i++) {
                  for (
                    let j = 0;
                    j < newListOfIncludedSideProjects.length;
                    j++
                  ) {
                    if (
                      projects[i].id ===
                      profileLinkConfig.profileLinkProjects[j].projectId
                    ) {
                      newListOfIncludedSideProjects[j].order = i;
                    }
                  }
                }

                const config = Object.assign({}, profileLinkConfig);
                setProfileLinkConfig({
                  ...config,
                  profileLinkProjects: newListOfIncludedSideProjects,
                });
              }}
              onProjectToggle={(project: SideProject, toggled: boolean) => {
                const config = Object.assign({}, profileLinkConfig);
                if (toggled) {
                  const indexOfProject =
                    currentProfileSideProjects.indexOf(project);

                  setProfileLinkConfig({
                    ...config,
                    profileLinkProjects: [
                      ...config.profileLinkProjects,
                      { projectId: project.id, order: indexOfProject },
                    ],
                  });
                } else {
                  const newProjects = config.profileLinkProjects.filter(
                    (p) => p.projectId !== project.id,
                  );
                  setProfileLinkConfig({
                    ...config,
                    profileLinkProjects: newProjects,
                  });
                }
              }}
              toggledProjectIds={profileLinkConfig.profileLinkProjects.map(
                (p) => p.projectId,
              )}
              projectLinkConfing={profileLinkConfig.projectsLinkConfig}
            />

            {profileLinkConfig.contactUsLinkConfig.displayContactUs === true &&
            getSelectedContactDetails() !== undefined ? (
              <div className="pr-8">
                <ContactBox
                  editable={true}
                  profileContactDetails={getSelectedContactDetails()!}
                  onUpdatePhone={(phone: string) => {
                    setProfileLinkConfig({
                      ...profileLinkConfig,
                      contactUsLinkConfig: {
                        ...profileLinkConfig.contactUsLinkConfig,
                        phone: phone,
                      },
                    });
                  }}
                  phone={profileLinkConfig.contactUsLinkConfig.phone}
                />
              </div>
            ) : null}

            <div className="min-h-[100px] w-full" />
          </div>
        </div>
        <div
          className={`flex flex-col flex-1 min-w-[300px] p-4 border-b-2 xl:border-r-2 border-op-dark xl:overflow-scroll ${
            showLinkNameSettings ? "hidden" : ""
          }`}
        >
          <div className={linkConfig.dynamic && !isGuestUser ? "hidden" : ""}>
            <h3 className="text-large font-bold mb-3">General</h3>
            {profilePhotoId === "" ? (
              <p>Upload a profile picture to use these settings</p>
            ) : null}
            <div
              className={
                profilePhotoId === ""
                  ? "opacity-50 mb-2 mt-2 pointer-events-none	"
                  : ""
              }
            >
              <LinkSetingToggle
                title="Display profile picture"
                checked={profileLinkConfig?.useProfilePicture}
                onChange={(e) => {
                  const config = Object.assign({}, profileLinkConfig);
                  config.useProfilePicture = e.target.checked;
                  setProfileLinkConfig(config);
                }}
              />
              <LinkSetingToggle
                title="Rounded profile picture"
                checked={
                  profileLinkConfig?.profileLinkPersonalInfoConfig.roundMode
                }
                onChange={(e) => {
                  const config = Object.assign({}, profileLinkConfig);
                  config.profileLinkPersonalInfoConfig.roundMode =
                    e.target.checked;
                  setProfileLinkConfig(config);
                }}
              />

              <h3 className="text-large font-bold mb-3 mt-10">
                Work experience
              </h3>
              <LinkSetingToggle
                title="Display skills"
                checked={profileLinkConfig?.workplaceLinkConfig.displaySkills}
                onChange={(e) => {
                  const config = Object.assign({}, profileLinkConfig);
                  config.workplaceLinkConfig.displaySkills = e.target.checked;
                  setProfileLinkConfig(config);
                }}
              />
              <LinkSetingToggle
                title="Display descriptions"
                checked={
                  profileLinkConfig?.workplaceLinkConfig.displayDescription
                }
                onChange={(e) => {
                  const config = Object.assign({}, profileLinkConfig);
                  config.workplaceLinkConfig.displayDescription =
                    e.target.checked;
                  setProfileLinkConfig(config);
                }}
              />
              <LinkSetingToggle
                title="Display connected projects"
                checked={
                  profileLinkConfig?.workplaceLinkConfig
                    .displayConnectedProjects
                }
                onChange={(e) => {
                  const config = Object.assign({}, profileLinkConfig);
                  config.workplaceLinkConfig.displayConnectedProjects =
                    e.target.checked;
                  setProfileLinkConfig(config);
                }}
              />
              <h3 className="text-large font-bold mb-3 mt-10">Projects</h3>
              <LinkSetingToggle
                title="Display skills"
                checked={profileLinkConfig?.projectsLinkConfig.displaySkills}
                onChange={(e) => {
                  const config = Object.assign({}, profileLinkConfig);
                  config.projectsLinkConfig.displaySkills = e.target.checked;
                  setProfileLinkConfig(config);
                }}
              />
            </div>
            <h3 className="text-large font-bold mb-3 mt-10">Contact</h3>
            <LinkSetingToggle
              title="Show contact information"
              checked={profileLinkConfig.contactUsLinkConfig.displayContactUs}
              onChange={(e) => {
                const config = Object.assign({}, profileLinkConfig);
                config.contactUsLinkConfig.displayContactUs = e.target.checked;
                config.contactUsLinkConfig.contactProfileId =
                  currentOrgManagers[0].profileId;
                setProfileLinkConfig(config);
              }}
            />
            <h5 className="text-md font-bold mb-3 mt-4">Select manager</h5>
            <select
              value={profileLinkConfig.contactUsLinkConfig.contactProfileId}
              onChange={(e) => {
                const config = Object.assign({}, profileLinkConfig);
                config.contactUsLinkConfig.contactProfileId = e.target.value;
                setProfileLinkConfig(config);
              }}
            >
              {currentOrgManagers.map((com, index) => {
                return (
                  <option key={index} value={com.profileId}>
                    {com.name}
                  </option>
                );
              })}
            </select>

            <h3 className="text-large font-bold mb-3 mt-10 pb-4">Branding</h3>
            <input
              className="hidden"
              type="file"
              id="link-logo-uploader"
              onChange={(event) => {
                if (event.target.files === null) return;

                const file = event.target.files[0];
                const reader = new FileReader();

                reader.onload = (e: ProgressEvent<FileReader>) => {
                  const base64String = (e.target?.result as string).split(
                    ",",
                  )[1];

                  setLocalAddedLogo(e.target?.result as string);
                  setLogoSize(40);
                  const logotypes: Logotype[] = [
                    {
                      assetUrl: base64String,
                      placement: "top-right",
                      size: logoSize,
                    },
                  ];
                  const branding: Branding = { logotypes: logotypes };
                  setProfileLinkConfig({
                    ...linkConfig,
                    branding: branding,
                  });
                };

                reader.readAsDataURL(file);
              }}
            ></input>

            {(localAddedLogo ?? getAssetUrl(linkConfig.branding || null)) ? (
              <div className="flex flex-col items-center">
                <img
                  src={
                    localAddedLogo ??
                    "https://pub-4b15203a4a0f4b26ab71deeffce0164a.r2.dev/" +
                      getAssetUrl(linkConfig.branding || null)
                  }
                  className="object-scale-contain"
                  style={{ maxHeight: logoSize ?? 40 }}
                />
                <div className="flex flex-col flex-1 w-full mb-5">
                  <label className="align-left">Logo size</label>
                  <Slider
                    value={logoSize}
                    min={20}
                    max={80}
                    step={1}
                    aria-labelledby="Zoom"
                    onChange={(e, size) => {
                      console.log(size);
                      setLogoSize(Math.round(size as number));
                    }}
                    style={{ color: "#171212" }}
                  />
                </div>

                <Button
                  text="Remove logo"
                  onButtonClick={() => {
                    setLocalAddedLogo(null);
                    setProfileLinkConfig({
                      ...linkConfig,
                      branding: null,
                    });
                  }}
                  style={ButtonStyleType.danger}
                />
              </div>
            ) : (
              <Button
                text="Add logo"
                onButtonClick={() => {
                  document.getElementById("link-logo-uploader")?.click();
                }}
                style={ButtonStyleType.secondary}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LinkEditor;
