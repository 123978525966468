import { useEffect, useState } from "react";
import {
  acceptOrganizationInvite,
  fetchProfile,
  updateProfile,
} from "../requests";
import Button, { ButtonStyleType } from "../components/atoms/Button";
import TextField from "../components/TextField";
import type { Profile } from "../types";
import { useNavigate } from "react-router-dom";

interface OnboardingProps {
  externalProfileId?: string;
  inviteLinkId?: string;
}

function Onboarding({ externalProfileId, inviteLinkId }: OnboardingProps) {
  const navigate = useNavigate();

  const [name, setName] = useState<string>("");
  const [professionalTitle, setProfessionalTitle] = useState<string>("");
  const [firstProjectTitle, setFirstProjectTitle] = useState<string>("");

  const [textInput, setTextInput] = useState<string>("");
  const [textInputValid, setTextInputValid] = useState<boolean>(false);
  const [onboardingStep, setOnboardingStep] = useState<number>(1);

  const [profile, setProfile] = useState<Profile>({
    id: "",
    profile_name: "",
    name: "",
    title: "",
    pitch: "",
    ui_config: { dark_mode: false, use_profile_picture: true },
    roles_section: { order: 0, title: "", roles: [] },
    skills_section: { order: 0, title: "", skill_blocks: [] },
    side_projects_section: { order: 0, title: "", side_projects: [] },
    profile_tags: [],
    workplaces: [],
    educations: [],
  });

  useEffect(() => {
    if (externalProfileId !== undefined) {
      fetchProfile(externalProfileId).then((accountProfile) => {
        if (accountProfile.profile.name !== "") {
          navigate("/editor/" + externalProfileId);
        }
      });
    }
    if (inviteLinkId) {
    }
  }, []);

  function title() {
    switch (onboardingStep) {
      case 1:
        return "Hi, what´s your name?";
      case 2:
        if (name.indexOf(" ") > -1) {
          const firstName = name.split(" ")[0];
          return "Hi " + firstName + ", what is your professional title?";
        } else {
          return "Hi " + name + ", what is your professional title?";
        }
      default:
        return "Hi, what´s your name?";
    }
  }

  function placeholder() {
    switch (onboardingStep) {
      case 1:
        return "Tracy Smith";
      case 2:
        return "Frontend Developer";

      default:
        return "Your name";
    }
  }

  function skipText() {
    switch (onboardingStep) {
      case 1:
        return "Skip, I like blank pages";
      case 2:
        return "Skip rest of onboarding";
      default:
        return "";
    }
  }

  function infoText() {
    switch (onboardingStep) {
      case 1:
        return "This onboarding will help you get started. What’s worse than starting on a blank page?!";
      case 2:
        return "";
      default:
        return "Skip!";
    }
  }

  function nextStep(input: string) {
    if (onboardingStep === 1) {
      setName(input);
      if (professionalTitle !== "") {
        setTextInput(professionalTitle);
      } else {
        setTextInput("");
      }
    } else if (onboardingStep === 2) {
      completeOnboarding(input);
      return;
    }

    setOnboardingStep(onboardingStep + 1);
  }

  function previousStep() {
    setTextInputValid(true);
    if (onboardingStep === 2) {
      setTextInput(name);
    } else if (onboardingStep === 3) {
      setTextInput(professionalTitle);
    } else if (onboardingStep === 4) {
      setTextInput(firstProjectTitle);
    }
    setOnboardingStep(onboardingStep - 1);
  }

  const completeOnboarding = (professionalTitle: string) => {
    var updatedProfile = { ...profile };
    updatedProfile.name = name;
    updatedProfile.title = professionalTitle;
    updatedProfile.side_projects_section!.side_projects = [];

    updatedProfile.id = externalProfileId!;
    updateProfile(updatedProfile).then((updatedProileData) => {
      if (localStorage.getItem("op-pending-org-invite-code")) {
        const inviteCode = localStorage.getItem("op-pending-org-invite-code");
        acceptOrganizationInvite(inviteCode!).then((orgId: string) => {
          navigate("/org/" + orgId);
        });
        return;
      }

      const currentProfileId = localStorage.getItem("current-profile-id");
      navigate("/profiles/" + currentProfileId);
    });
  };

  return (
    <div className="flex justify-end align-end min-h-[500px]">
      <div className="mx-auto p-2 md:p-0 md:w-1/2 flex flex-col h-full">
        <div className="min-h-[80px] h-[80px] mt-20">
          <h4 className="text-2xl font-bold">{title()}</h4>
        </div>

        <div className="px-12">
          <TextField
            placeholder={placeholder()}
            onLeaveField={(input) => {
              setTextInput(input);
            }}
            onLeaveValidatedField={(input, isValid) => {
              setTextInput(input);
            }}
            onChange={(input) => {
              setTextInputValid(input.length >= 2);
            }}
            inputStyles={{
              marginBottom: 15,
              padding: 10,
              marginTop: 30,
            }}
            showValidation={textInputValid}
            validationRules={["NOT-EMPTY", "NO-SPECIAL-CHARS"]}
            value={textInput}
            blurOnEnter
            handleEnterClick={(input, isValid) => {
              nextStep(input);
            }}
          />

          <div className="flex flex-row">
            <div className="flex flex-1 flex-col justify-end pr-5">
              {onboardingStep > 1 ? (
                <Button
                  text="Back"
                  onButtonClick={() => {
                    previousStep();
                  }}
                  style={ButtonStyleType.secondary}
                />
              ) : null}
            </div>
            <div className="flex flex-1 flex-col justify-end text-right pl-5">
              <label className="font-bold mb-2">{onboardingStep}/2</label>
              <Button
                text="Next"
                onButtonClick={() => {
                  nextStep(textInput);
                  if (onboardingStep === 1 && professionalTitle.length >= 2) {
                    setTextInputValid(true);
                  } else {
                    setTextInputValid(false);
                  }
                }}
                disabled={!textInputValid}
                style={ButtonStyleType.primary}
              />
            </div>
          </div>

          <div className="min-h-[160px] h-[160px]">
            <h4 className="text-xl font-semibold text-center mt-[50px]">
              {infoText()}
            </h4>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
}

export default Onboarding;
