import { useEffect, useState } from "react";
import SideProjectEditor from "./SideProjectEditor";
import SectionHolder from "./SectionHolder";

import {
  type SideProject,
  type ProfileComponentProps,
  type SideProjectsSession,
  Workplace,
  ProjectPhoto,
  type WorkplaceItem,
  type WorkplaceLinkConfig,
  EducationItem,
} from "../types";
import AddNewItemBlock from "./AddNewItemBlock";
import "./components.css";
import {
  DragDropContext,
  Draggable,
  Droppable,
  type DropResult,
} from "@hello-pangea/dnd";
import { AlignJustify, Plus } from "react-feather";
import ContentToggle from "./ContentToggle";
import EditorSkillTagButton from "./atoms/EditorSkillTagButton";
import WorkplaceEditor from "./WorkplaceEditor";
import dayjs from "dayjs";
import EditorTextIconButton from "./EditorTextIconButton";
import EducationEditor from "./EducationEditor";

interface EducationSectionProps extends ProfileComponentProps {
  isEditable: boolean;
  sectionCount: number;
  onAddItem: (education: EducationItem) => void;
  onUpdateItem: (education: EducationItem) => void;
  onRemoveItem: (education: EducationItem) => void;
  onDeleteSectionClick: () => void;
  onShowDialog: (dialogType: string, educationId: string) => void;
  onToggle?: (workplace: WorkplaceItem, toggleState: boolean) => void;
  toggledWorkplaceIds?: string[];
  educations: EducationItem[];
  onMoveUp?: (order: number) => void;
  onMoveDown?: (order: number) => void;
  pdfMode?: boolean;
}

function EducationSection({
  isEditable,
  sectionCount,
  onAddItem,
  onUpdateItem,
  onRemoveItem,
  onDeleteSectionClick,
  onShowDialog,
  isDarkModeActive,
  onToggle,
  toggledWorkplaceIds,
  educations,
  onMoveUp,
  onMoveDown,
  pdfMode,
}: EducationSectionProps) {
  const [isAddNewSkillProjectSelected, setIsAddNewSkillProjectSelected] =
    useState<boolean>(false);
  const [contentImprovementIsActive, setContentImprovementIsActive] =
    useState<boolean>(false);

  const [currentEducations, setCurrentEducations] = useState<EducationItem[]>(
    [],
  );
  const [isDragging, setIsDragging] = useState<boolean>(false);

  const [workplaceSideProject, setWorkplaceSideProject] = useState<SideProject>(
    {
      id: "",
      title: "",
      pitch: "",
      order: -1,
      githubUrl: "",
      websiteUrl: "",
      workplace: null,
      skillTags: [],
      isSideProject: false,
    },
  );

  useEffect(() => {
    setCurrentEducations(educations != undefined ? educations : []);
  }, [educations]);

  if (!isEditable) {
    return (
      <SectionHolder
        isDarkModeActive={isDarkModeActive}
        onDeleteSectionClick={onDeleteSectionClick}
        title={""}
        order={2}
        totalSectionCount={sectionCount}
        isEditable={isEditable}
      >
        {currentEducations.map((education, index) => {
          return (
            <EducationEditor
              isEditable={isEditable}
              isDarkModeActive={isDarkModeActive}
              key={index}
              isBeingEdited={true}
              isNew={false}
              education={education}
              onSaveItem={(education: EducationItem) => {
                onUpdateItem(education);
                //onUpdateSideProject(savedSideProject);
              }}
              onCancelEdit={() => {}}
              onRemove={() => {}}
              onShowDialog={() => {}}
              isLastInOrder={false}
              onContentImprovementStarted={() => {}}
              onContentImprovementEnded={() => {}}
              pdfMode={pdfMode}
              removePadding={true}
            />
          );
        })}
      </SectionHolder>
    );
  }

  return (
    <SectionHolder
      isDarkModeActive={isDarkModeActive}
      onDeleteSectionClick={onDeleteSectionClick}
      title={""}
      order={2}
      totalSectionCount={sectionCount}
      isEditable={isEditable}
    >
      {isEditable ? (
        <div>
          {currentEducations.map((education, index) => {
            return (
              <EducationEditor
                isEditable={isEditable}
                isDarkModeActive={isDarkModeActive}
                key={education.id}
                isBeingEdited={true}
                isNew={false}
                education={education}
                onSaveItem={(educationItem: EducationItem) => {
                  onUpdateItem(educationItem);
                  //onUpdateSideProject(savedSideProject);
                }}
                onCancelEdit={() => {}}
                onRemove={() => {
                  onRemoveItem(education);
                }}
                onShowDialog={(dialogType: string) => {
                  onShowDialog(dialogType, education.id);
                }}
                isLastInOrder={false}
                onContentImprovementStarted={() => {
                  setContentImprovementIsActive(true);
                }}
                onContentImprovementEnded={() => {
                  setContentImprovementIsActive(false);
                }}
                onMoveUp={onMoveUp}
                onMoveDown={onMoveDown}
                educationCount={educations.length}
              />
            );
          })}

          {isAddNewSkillProjectSelected ? (
            <EducationEditor
              key="new-education-editor"
              isEditable={!isEditable}
              isDarkModeActive={isDarkModeActive}
              education={null}
              onSaveItem={(newEducation) => {
                setIsAddNewSkillProjectSelected(false);
                //onAddSideProject(sideProject);
              }}
              isBeingEdited={false}
              isNew={true}
              onRemove={() => {}}
              onCancelEdit={() => setIsAddNewSkillProjectSelected(false)}
              onShowDialog={(dialogType: string) => {
                //onShowDialog(dialogType, -1)
              }}
              isLastInOrder={false}
            />
          ) : (
            <div>
              <EditorTextIconButton
                text="Add new education, course or certificate"
                handleClick={() => {
                  setIsAddNewSkillProjectSelected(false);
                  const newEducation: EducationItem = {
                    id: "",
                    name: "",
                    startDate: "",
                    endDate: "",
                    order: 0,
                  };
                  onAddItem(newEducation);
                }}
                iconChild={
                  <div className="bg-op-dark max-w-[18px] max-h-[18px] min-w-[18px] min-h-[18px] flex justify-center items-center">
                    <Plus size={15} color="#fefefe" />
                  </div>
                }
              />
            </div>
          )}
        </div>
      ) : null}
    </SectionHolder>
  );
}

export default EducationSection;
