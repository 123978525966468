import {
  faAt,
  faBriefcase,
  faCircleInfo,
  faGlobe,
  faMagicWandSparkles,
  faPerson,
  faPhone,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { TooltipProvider } from "@radix-ui/react-tooltip";
import {
  type PropsWithChildren,
  forwardRef,
  isValidElement,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import { trim } from "validator";
import type { Lead } from "../../../../../client";
import {
  Button,
  Input,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  isEmailOrEmpty,
  isPhoneNumberOrEmpty,
  ImageButton,
  isUrlOrEmpty,
  SecondarySectionTitle,
  Image,
  TextButton,
  InputRef,
  isString,
} from "../../../../../components";
import {
  getIconByName,
  iconForName,
} from "../../../../../components/TechIcons";
import { useAnimate } from "../../../../../hooks";
import { LeadRowCard } from "../card/LeadRowCard";
import { LeadRowCardSection } from "../card/LeadRowCardSection";
import { LeadRowCardSectionWithLink } from "../card/LeadRowCardSectionWithLink";
import { PanelContainer } from "./PanelContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFaceFrown } from "@fortawesome/free-regular-svg-icons";
import { useGetMatch } from "../../../../../services/organtization/get-match";
import { useGetOrganization } from "../../../../../services";
import { getImageUrl } from "../../../../../utils/image";
import { getDateSectionConfigFromFormatToken } from "@mui/x-date-pickers/internals/hooks/useField/useField.utils";
import { useGetLeadMatch } from "../../../../../services/organtization/get-lead-match";
import SkillsIcon from "../../../../../components/atoms/SkillsIcon";

interface Props {
  lead?: Lead;
  disabled: boolean;
  onMutateLead: (lead: Partial<Lead>) => void;
  onRequestAssignmentInfo: (url: string) => void;
  onRequestAssignmentInfoWithInfo: (info: string) => void;
  onRequestWebsiteInfo: (url: string) => void;
  onSelectLogo: (logoUrl: string) => void;
  isEditingAssignmentLink: boolean;
  isEditingWebsiteLink: boolean;
  onEditAssigmentLinkClick: () => void;
  onEditWebsiteLinkClick: () => void;
  couldNotLoadFromUrl: boolean;
  couldNotLoadClientFromUrl: boolean;
  organizationId: string;
  onMatchingMembers: (numberOfMatchingMembers: number) => void;
}

const MAX_VISIBLE_SKILLS = 5;

export const AssignmentPanel = ({
  lead,
  disabled,
  onMutateLead,
  onRequestAssignmentInfo,
  onRequestAssignmentInfoWithInfo,
  onRequestWebsiteInfo,
  onSelectLogo,
  isEditingAssignmentLink,
  isEditingWebsiteLink,
  onEditAssigmentLinkClick,
  onEditWebsiteLinkClick,
  couldNotLoadFromUrl,
  couldNotLoadClientFromUrl,
  organizationId,
  onMatchingMembers,
}: Props) => {
  const [skillsParent] = useAnimate();
  const [expanded, toggleExpanded] = useReducer((expanded) => !expanded, false);

  const { data, error } = useGetOrganization(organizationId);

  const { data: skillMatchData, error: skillMatchError } = useGetLeadMatch(
    {
      organizationId: lead?.orgId!,
      leadId: lead?.id ?? "",
      skills: lead?.assignmentInfo?.skillTags?.map((sk) => sk.name) ?? [],
    },
    { enabled: !!lead?.assignmentInfo && !!lead.assignmentInfo.skillTags },
  );

  useEffect(() => {
    if (skillMatchData) {
      onMatchingMembers(skillMatchData.length);
    }
  }, [skillMatchData, onMatchingMembers]);

  const companyUrlInputRef = useRef<InputRef>(null);

  const handleCompanyUrlSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (companyUrlInputRef.current?.value) {
        onRequestWebsiteInfo(companyUrlInputRef.current.value);
      }
    },
    [onRequestWebsiteInfo],
  );

  const [localContactName, setLocalContactName] = useState(
    lead?.contactPersonName ?? "",
  );
  const [localContactEmail, setLocalContactEmail] = useState(
    lead?.contactPersonEmail ?? "",
  );
  const [localContactPhone, setLocalContactPhone] = useState(
    lead?.contactPersonPhone ?? "",
  );
  const [localCompanyName, setLocalCompanyName] = useState(
    lead?.companyName ?? "",
  );

  const [localNotes, setLocalNotes] = useState(lead?.notes ?? "");

  const [localAssignmentTitle, setLocalAssignmentTitle] = useState(
    lead?.assignmentInfo?.title ?? "",
  );

  useEffect(() => {
    // Update local state when lead data changes
    setLocalContactName(lead?.contactPersonName ?? "");
    setLocalContactEmail(lead?.contactPersonEmail ?? "");
    setLocalContactPhone(lead?.contactPersonPhone ?? "");
    setLocalCompanyName(lead?.companyName ?? "");
    setLocalNotes(lead?.notes ?? "");
    setLocalAssignmentTitle(lead?.assignmentInfo?.title ?? "");
  }, [
    lead?.contactPersonName,
    lead?.contactPersonEmail,
    lead?.contactPersonPhone,
    lead?.companyName,
    lead?.notes,
    lead?.assignmentInfo,
  ]);

  const getProfilePhotoIds = (profileId: string) => {
    return data.profiles.filter((p) => p.profileId === profileId)[0]
      .profilePhotoId;
  };

  return (
    <PanelContainer>
      <div className="flex flex-col gap-6 lg:justify-start">
        <LeadRowCard type="solid">
          <LeadRowCardSection title="Contact information">
            <Input
              leftIcon={faPerson}
              label="Contact name"
              value={localContactName}
              onChange={(e) => setLocalContactName(e.target.value)}
              onBlur={(e) => {
                if (!(!lead && localContactName === "")) {
                  onMutateLead({ contactPersonName: localContactName });
                }
              }}
              transformValue={trim}
            />
            <Input
              leftIcon={faAt}
              label="E-mail"
              value={lead?.contactPersonEmail}
              onChange={(e) => setLocalContactEmail(e.target.value)}
              onBlur={(e) => {
                if (!(!lead && localContactEmail === "")) {
                  onMutateLead({ contactPersonEmail: localContactEmail });
                }
              }}
              transformValue={trim}
              validate={isEmailOrEmpty}
              type="email"
            />
            <Input
              leftIcon={faPhone}
              label="Phone number"
              value={lead?.contactPersonPhone}
              onChange={(e) => setLocalContactPhone(e.target.value)}
              onBlur={(e) => {
                if (!(!lead && localContactPhone === "")) {
                  onMutateLead({ contactPersonPhone: localContactPhone });
                }
              }}
              transformValue={trim}
              validate={isPhoneNumberOrEmpty}
              type="tel"
            />
          </LeadRowCardSection>
        </LeadRowCard>
        <LeadRowCard type="solid">
          {lead?.companyUrl && !isEditingWebsiteLink ? (
            <>
              <LeadRowCardSectionWithLink
                title="Company"
                linkUrl={lead.companyUrl}
                onEditClick={() => onEditWebsiteLinkClick()}
              ></LeadRowCardSectionWithLink>
              <LeadRowCardSection title="Name" secondarySection>
                <Input
                  disabled={disabled}
                  leftIcon={faGlobe}
                  label="Company website link"
                  onChange={(e) => setLocalCompanyName(e.target.value)}
                  value={localCompanyName}
                  onBlur={(e) => {
                    if (!(!lead && localCompanyName === "")) {
                      onMutateLead({ companyName: localCompanyName });
                    }
                  }}
                />
              </LeadRowCardSection>
              <LeadRowCardSection title="Pick image">
                <div className="flex flex-row flex-wrap gap-2">
                  {lead?.logoSuggestions &&
                    lead?.logoSuggestions.map((logo, i) => (
                      <ImageButton
                        key={logo}
                        active={lead.logoSuggestionOrigin === logo}
                        alt={`Logo suggestion ${i}`}
                        imageUrl={logo}
                        onClick={() => onSelectLogo(logo)}
                      />
                    ))}
                  <TextButton
                    key={"custom-icon"}
                    active={
                      !lead.logoSuggestionOrigin ||
                      lead.logoSuggestionOrigin === ""
                    }
                    onClick={() => {
                      onSelectLogo("");
                    }}
                    text={lead.companyName ? lead.companyName[0] : ""}
                  />
                </div>
              </LeadRowCardSection>
              <LeadRowCardSection
                title="Description"
                secondarySection
                className="text-sm"
              >
                {lead.companyDesc}
              </LeadRowCardSection>
            </>
          ) : (
            <>
              <LeadRowCardSection title="Company">
                <form onSubmit={handleCompanyUrlSubmit}>
                  <Input
                    ref={companyUrlInputRef}
                    disabled={disabled}
                    leftIcon={faGlobe}
                    label="Company website link"
                    validate={isUrlOrEmpty}
                    value={lead?.companyUrl}
                    rightButton={{
                      label: "Go!",
                      onClick: () =>
                        onRequestWebsiteInfo(
                          companyUrlInputRef.current?.value || "",
                        ),
                    }}
                    // Add onKeyDown handler if you specifically want to handle Enter key
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        onRequestWebsiteInfo(
                          companyUrlInputRef.current?.value || "",
                        );
                      }
                    }}
                  />
                </form>
              </LeadRowCardSection>
              {couldNotLoadClientFromUrl && (
                <LeadRowCard
                  className="text-xs bg-red-2 border-red-3 mt-3"
                  type="dashed"
                >
                  <div className="flex flex-1 items-center justify-between gap-3">
                    <FontAwesomeIcon
                      width={14}
                      height={14}
                      icon={faFaceFrown}
                      className="w-4 h-4 shrink-0"
                    />
                    <p>
                      Something went wrong when fetching company information.
                      Please make sure that the url is valid and try again.
                    </p>
                  </div>
                </LeadRowCard>
              )}
              <LeadRowCard
                className="text-xs bg-red-1 border-red-2 mt-3"
                type="dashed"
              >
                <div className="flex flex-1 items-center gap-3">
                  <FontAwesomeIcon
                    width={14}
                    height={14}
                    icon={faMagicWandSparkles}
                    className="w-4 h-4 shrink-0"
                  />
                  <p>Add company domain to fetch info</p>
                </div>
              </LeadRowCard>
            </>
          )}
        </LeadRowCard>
        <LeadRowCard>
          <LeadRowCardSection title="Notes">
            <Input
              disabled={disabled}
              leftIcon={faGlobe}
              label="Whatever information you would like..."
              rows={3}
              onChange={(e) => setLocalNotes(e.target.value)}
              value={localNotes}
              onBlur={(e) => {
                if (!(!lead && localNotes === "")) {
                  onMutateLead({ notes: localNotes });
                }
              }}
            />
          </LeadRowCardSection>
        </LeadRowCard>
      </div>
      <LeadRowCard type="solid">
        {lead?.assignmentInfo &&
        !couldNotLoadFromUrl &&
        !isEditingAssignmentLink ? (
          <>
            <LeadRowCardSectionWithLink
              title="Add assignment"
              linkUrl={lead.assignmentInfo.assignmentUrl}
              onEditClick={() => onEditAssigmentLinkClick()}
            ></LeadRowCardSectionWithLink>
            <LeadRowCardSection title="Role" secondarySection>
              <Input
                leftIcon={faBriefcase}
                label="Role"
                transformValue={trim}
                onChange={(e) => setLocalAssignmentTitle(e.target.value)}
                value={localAssignmentTitle}
                onBlur={(e) => {
                  if (!(!lead && localAssignmentTitle === "")) {
                    if (lead.assignmentInfo) {
                      onMutateLead({
                        title: localAssignmentTitle,
                        assignmentInfo: {
                          ...lead.assignmentInfo!,
                          title: localAssignmentTitle,
                        },
                      });
                    }
                  }
                }}
              />
            </LeadRowCardSection>
            <LeadRowCardSection title="Technical skills" secondarySection>
              <div className="flex flex-row flex-wrap gap-2 px-4">
                {lead?.assignmentInfo?.skillTags?.map((skill, index) => (
                  <TooltipProvider key={skill?.name} delayDuration={0}>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <div className="w-6 h-6 flex items-center justify-center">
                          <SkillsIcon
                            key={index}
                            profileSkillTag={skill}
                            text=""
                          />
                        </div>
                      </TooltipTrigger>
                      <TooltipContent className="mb-2">
                        <p>{skill?.name}</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                ))}
              </div>
              {skillMatchData && (
                <LeadRowCard
                  className="flex flex-1 flex-col bg-red-1 text-sm mt-2"
                  type="dashed"
                >
                  <p>{`${skillMatchData.length} members have matching skills with this assignment`}</p>
                  <div className="flex flex-1 flex-row gap-3 overflow-y-scroll">
                    {skillMatchData.map((profileSkillMatch) => (
                      <Image
                        key={profileSkillMatch.profileId} // Ensure each item has a unique key
                        src={
                          getImageUrl(
                            getProfilePhotoIds(profileSkillMatch.profileId!)!,
                          )!
                        }
                        className="rounded-xl w-10 h-10 flex-shrink-0"
                        alt="Profile photo"
                      />
                    ))}
                  </div>
                </LeadRowCard>
              )}
            </LeadRowCardSection>
            <LeadRowCardSection title="All skills" secondarySection>
              <List ref={skillsParent}>
                {lead.assignmentInfo.skills
                  .slice(0, expanded ? undefined : MAX_VISIBLE_SKILLS)
                  .map((skill) => (
                    <li key={skill}>{skill}</li>
                  ))}
                {lead.assignmentInfo.skills.length > MAX_VISIBLE_SKILLS && (
                  <Button className="mt-2 w-max" onClick={toggleExpanded}>
                    {expanded ? "show less" : "show more"}
                  </Button>
                )}
              </List>
            </LeadRowCardSection>
            {lead.assignmentInfo.workplace !== "" && (
              <LeadRowCardSection
                title="Workplace"
                secondarySection
                className="text-sm"
              >
                <List>
                  <li>{lead.assignmentInfo.workplace}</li>
                  <li>{lead.assignmentInfo.workplaceInfo}</li>
                </List>
              </LeadRowCardSection>
            )}
            {lead.assignmentInfo.profile !== "" && (
              <LeadRowCardSection
                title="Profile"
                secondarySection
                className="text-sm"
              >
                <List>
                  <li>{lead.assignmentInfo.profile}</li>
                </List>
              </LeadRowCardSection>
            )}

            <LeadRowCardSection title="Responsibilities" secondarySection>
              <List>
                {lead.assignmentInfo.responsibilities.map((responsibility) => (
                  <li key={responsibility}>{responsibility}</li>
                ))}
              </List>
            </LeadRowCardSection>
            <LeadRowCardSection title="Location" secondarySection>
              <List>
                {lead.assignmentInfo.location.map((location) => (
                  <li key={location}>{location}</li>
                ))}
              </List>
            </LeadRowCardSection>
            <LeadRowCardSection title="Remote" secondarySection>
              <List>
                <li>{lead.assignmentInfo.remote}</li>
              </List>
            </LeadRowCardSection>
          </>
        ) : (
          <LeadRowCardSection title="Assignment">
            <Input
              disabled={disabled}
              leftIcon={faGlobe}
              label="Link"
              validate={isUrlOrEmpty}
              value={lead?.assignmentInfo?.assignmentUrl}
              rightButton={{
                label: "Go!",
                onClick: onRequestAssignmentInfo,
              }}
            />
            <SecondarySectionTitle className="text-sm">
              OR
            </SecondarySectionTitle>
            <Input
              disabled={disabled}
              leftIcon={faGlobe}
              label="Paste assignment text"
              validate={isString}
              value={lead?.assignmentInfo?.assignmentDescription}
              rows={5}
              rightButton={{
                label: "Go!",
                onClick: onRequestAssignmentInfoWithInfo,
              }}
            />
            {couldNotLoadFromUrl ? (
              <LeadRowCard
                className="text-xs bg-red-2 border-red-3 mt-3"
                type="dashed"
              >
                <div className="flex flex-1 items-center justify-between gap-3">
                  <FontAwesomeIcon
                    width={14}
                    height={14}
                    icon={faFaceFrown}
                    className="w-4 h-4 shrink-0"
                  />
                  <p>
                    Assignment info could not be fetched from the link. Please
                    try to paste the assignment description instead.
                  </p>
                </div>
              </LeadRowCard>
            ) : (
              <LeadRowCard
                className="text-xs bg-red-1 border-red-2 mt-3"
                type="dashed"
              >
                <div className="flex flex-1 items-center justify-between gap-3">
                  <FontAwesomeIcon
                    width={14}
                    height={14}
                    icon={faMagicWandSparkles}
                    className="w-4 h-4 shrink-0"
                  />
                  <p>
                    By adding an assignment link, VOCS will help you find a
                    matching candidate and help you write suiting resume
                    introductions.
                  </p>
                </div>
              </LeadRowCard>
            )}
          </LeadRowCardSection>
        )}
      </LeadRowCard>
    </PanelContainer>
  );
};

const List = forwardRef<HTMLUListElement, PropsWithChildren>(
  ({ children }, ref) => (
    <ul ref={ref} className="list-disc list-inside text-sm px-4">
      {children}
    </ul>
  ),
);

List.displayName = "List";

AssignmentPanel.displayName = "AssignmentPanel";
