import { StatsigClient, StatsigOptions, StatsigUser } from "@statsig/js-client";
import { StatsigSessionReplayPlugin } from "@statsig/session-replay";
import { StatsigAutoCapturePlugin } from "@statsig/web-analytics";
let statsigClient: StatsigClient | null = null;
let isInitializing = false;
let initializationPromise: Promise<void> | null = null;

export const initializeStatsig = async () => {
  if (isInitializing) {
    return initializationPromise;
  }

  if (statsigClient) {
    return;
  }

  isInitializing = true;

  initializationPromise = (async () => {
    var currentProfileId = "";
    if (localStorage.getItem("current-profile-id")) {
      currentProfileId = localStorage.getItem("current-profile-id")!;
    }

    const user: StatsigUser = { userID: currentProfileId };
    const options: StatsigOptions = {
      plugins: [
        new StatsigSessionReplayPlugin(),
        new StatsigAutoCapturePlugin(),
      ],
      environment: { tier: "production" },
    };

    const client = new StatsigClient(
      "client-NJWFAktYEXVzl2ifbQ04xjKza44e7S9qGV1F7s3Apxa",
      user,
      options,
    );

    await client.initializeAsync();
    statsigClient = client;
    isInitializing = false;
  })();

  return initializationPromise;
};

export const getStatsigClient = () => {
  if (!statsigClient) {
    throw new Error(
      "Statsig client not initialized. Call initializeStatsig() first.",
    );
  }
  return statsigClient;
};

export const setStatsigClient = (client: StatsigClient) => {
  statsigClient = client;
};
