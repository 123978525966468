import { useEffect, useState } from "react";
import SideProjectEditor from "./SideProjectEditor";
import SectionHolder from "./SectionHolder";

import {
  type SideProject,
  type ProfileComponentProps,
  type SideProjectsSession,
  Workplace,
  ProjectPhoto,
  type WorkplaceItem,
  type WorkplaceLinkConfig,
  EducationItem,
} from "../types";
import AddNewItemBlock from "./AddNewItemBlock";
import "./components.css";
import {
  DragDropContext,
  Draggable,
  Droppable,
  type DropResult,
} from "@hello-pangea/dnd";
import { AlignJustify, Plus } from "react-feather";
import { SkillsBlock } from "../pages/dashboard/home/components/profile/SkillsBlock";
import { SkillCategoryBlock, SkillCount, SkillsSummaryConfig } from "../client";
import { SkillSummary } from "../pages/dashboard/home/components/profile/SkillSummary";

interface SkillsSectionProps extends ProfileComponentProps {
  sectionCount: number;
  skillCategoryBlocks?: SkillCategoryBlock[];
  skillSummaryConfig?: SkillsSummaryConfig;
  pdfMode?: boolean;
}

function SkillsSection({
  sectionCount,
  skillCategoryBlocks,
  isDarkModeActive,
  pdfMode,
  skillSummaryConfig,
}: SkillsSectionProps) {
  const [workplaceSideProject, setWorkplaceSideProject] = useState<SideProject>(
    {
      id: "",
      title: "",
      pitch: "",
      order: -1,
      githubUrl: "",
      websiteUrl: "",
      workplace: null,
      skillTags: [],
      isSideProject: false,
    },
  );

  return (
    <SectionHolder
      isDarkModeActive={isDarkModeActive}
      onDeleteSectionClick={() => {}}
      title={""}
      order={2}
      totalSectionCount={sectionCount}
      isEditable={false}
    >
      {skillSummaryConfig?.sortedMode !== "" &&
      skillSummaryConfig?.skillCategoryBlocks ? (
        <SkillSummary
          skillCategoryBlocks={skillSummaryConfig?.skillCategoryBlocks ?? []}
          displayIcons={skillSummaryConfig?.displayIcons ?? true}
          pdfMode={pdfMode}
        />
      ) : (
        <div className="pl-6">
          <SkillsBlock
            skillCategoryBlocks={skillSummaryConfig?.skillCategoryBlocks ?? []}
            editable={false}
            hideIcons={!skillSummaryConfig?.displayIcons}
            pdfMode={pdfMode}
          />
        </div>
      )}
    </SectionHolder>
  );
}

export default SkillsSection;
