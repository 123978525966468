import {
  DndContext,
  DragOverlay,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  defaultDropAnimation,
  DragStartEvent,
  pointerWithin,
  useDroppable,
} from "@dnd-kit/core";
import {
  SortableContext,
  horizontalListSortingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import type {
  Profile,
  ProfileLinkConfig,
  ProfileSkillMatch,
  SkillCategoryBlock,
  SkillTag,
} from "../../../../../client";
import EditorSkillTagButton from "../../../../../components/atoms/EditorSkillTagButton";
import { ProjectSettingsContainer } from "./ProjectSettingsContainer";
import {
  BulletSectionTitle,
  Checkbox,
  Popover,
  PopoverTrigger,
  PopoverContent,
} from "../../../../../components";
import { useEffect, useMemo, useRef, useState } from "react";
import { SkillsBlock } from "../profile/SkillsBlock";
import { restrictToWindowEdges } from "@dnd-kit/modifiers";
import { createPortal } from "react-dom";
import { cn } from "../../../../../components/utils/cn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-regular-svg-icons";
import { TooltipProvider } from "@radix-ui/react-tooltip";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "../../../../../components";
import {
  faCirclePlus,
  faInfo,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { useGetSkillCategories } from "../../../../../services";

interface Props {
  linkConfigData: ProfileLinkConfig;
  disabled: boolean;
  handleUpdateLink: (data: Partial<ProfileLinkConfig>) => void;
  profileData?: Profile;
  nonMatchingSkillTags: SkillTag[];
  onAddSkill: (newSkillName: string) => void;
  leadSkillTags: SkillTag[];
  onShowAddSkillsDialog: (skillTags: SkillTag[]) => void;
  skillsAddedFromDialog: SkillTag[];
  onAddedCustomSkills: () => void;
}
interface DroppableAreaProps {
  category: SkillCategoryBlock;
  children: React.ReactNode;
  isDragging?: boolean;
}

interface SortableItemProps {
  id: string;
  skillTag: {
    name: string;
    customIconName: string;
  };
  categoryId: string;
  isPopoverOpen?: boolean;
  onPopoverChange?: (open: boolean) => void;
  onRemove?: (categoryId: string, skillName: string) => void;
  showIcons?: boolean;
}
const DroppableArea = ({
  category,
  children,
  isDragging,
}: DroppableAreaProps) => {
  const { setNodeRef, isOver } = useDroppable({
    id: category.title,
    data: {
      type: "category",
      category: category,
    },
  });

  return (
    <div
      ref={setNodeRef}
      className={cn(
        "flex flex-row flex-wrap w-full gap-2 rounded-lg transition-all duration-200",
        "border-2 border-dashed",
        isDragging
          ? isOver
            ? "border-blue-400 bg-blue-50/50"
            : "border-gray-200"
          : "border-transparent hover:border-gray-200",
      )}
    >
      <SortableContext
        items={
          category.skillTags?.map(
            (tag: { name: string }) => `${category.title}-${tag.name}`,
          ) || []
        }
        strategy={horizontalListSortingStrategy}
      >
        {children}
      </SortableContext>
    </div>
  );
};

const SortableItem = ({
  id,
  skillTag,
  categoryId,
  isPopoverOpen = false,
  onPopoverChange = () => {},
  onRemove,
  showIcons,
}: SortableItemProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id,
    disabled: isPopoverOpen,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.3 : 1,
    display: "inline-block",
  };

  const handleRemoveClick = () => {
    if (onRemove) {
      onRemove(categoryId, skillTag.name);
    }
    onPopoverChange(false);
  };

  if (categoryId === "") {
    return (
      <div>
        <Popover open={isPopoverOpen} onOpenChange={onPopoverChange}>
          <PopoverTrigger asChild>
            <div {...attributes} {...listeners} className="cursor-grab">
              <EditorSkillTagButton
                profileSkillTag={skillTag}
                text={skillTag.name}
                disableEditing={true}
                handleClick={() => {}}
                keepLeftPadding={true}
                isStatic={true}
                hideIcon={!showIcons}
              />
            </div>
          </PopoverTrigger>
          <PopoverContent className="z-[9999] pointer-events-auto">
            <div className="flex flex-col pointer-events-auto">
              <button
                className="flex items-center gap-2 rounded-lg px-4 py-2 hover:bg-gray-100 pointer-events-auto"
                onClick={handleRemoveClick}
                style={{ cursor: "pointer" }}
              >
                <FontAwesomeIcon
                  width={14}
                  height={14}
                  icon={faEdit}
                  className="shrink-0"
                />
                <span
                  className="text-sm text-black"
                  style={{ pointerEvents: "auto", cursor: "pointer" }}
                >
                  Remove
                </span>
              </button>
            </div>
          </PopoverContent>
        </Popover>
      </div>
    );
  }

  return (
    <div ref={setNodeRef} style={style} className="relative">
      <TooltipProvider delayDuration={300}>
        <Tooltip>
          <TooltipTrigger asChild>
            <div>
              <Popover open={isPopoverOpen} onOpenChange={onPopoverChange}>
                <PopoverTrigger asChild>
                  <div {...attributes} {...listeners} className="cursor-grab">
                    <EditorSkillTagButton
                      profileSkillTag={skillTag}
                      text={skillTag.name}
                      disableEditing={true}
                      handleClick={() => {}}
                      keepLeftPadding={true}
                      isStatic={true}
                      hideIcon={!showIcons}
                    />
                  </div>
                </PopoverTrigger>
                <PopoverContent className="z-[9999] pointer-events-auto">
                  <div className="flex flex-col pointer-events-auto">
                    <button
                      className="flex items-center gap-2 rounded-lg px-4 py-2 hover:bg-gray-100 pointer-events-auto"
                      onClick={handleRemoveClick}
                      style={{ cursor: "pointer" }}
                    >
                      <FontAwesomeIcon
                        width={14}
                        height={14}
                        icon={faEdit}
                        className="shrink-0"
                      />
                      <span
                        className="text-sm text-black"
                        style={{ pointerEvents: "auto", cursor: "pointer" }}
                      >
                        Remove
                      </span>
                    </button>
                  </div>
                </PopoverContent>
              </Popover>
            </div>
          </TooltipTrigger>
          <TooltipContent>
            <div className="p-4 flex flex-col gap-1">
              <div>
                <FontAwesomeIcon
                  width={14}
                  height={14}
                  icon={faInfoCircle}
                  className="shrink-0 mr-2"
                />
                <span
                  className="text-sm text-black"
                  style={{ pointerEvents: "auto", cursor: "pointer" }}
                >
                  Click for settings
                </span>
              </div>
              <div>
                <FontAwesomeIcon
                  width={14}
                  height={14}
                  icon={faInfoCircle}
                  className="shrink-0 mr-2"
                />
                <span
                  className="text-sm text-black"
                  style={{ pointerEvents: "auto", cursor: "pointer" }}
                >
                  Hold and drag to reorder
                </span>
              </div>
            </div>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    </div>
  );
};

export const LinkCreatorSkills = ({
  linkConfigData,
  disabled,
  handleUpdateLink,
  profileData,
  nonMatchingSkillTags,
  onAddSkill,
  leadSkillTags,
  onShowAddSkillsDialog,
  skillsAddedFromDialog,
  onAddedCustomSkills,
}: Props) => {
  const [skills, setSkills] = useState<SkillCategoryBlock[]>([]);
  const [activeId, setActiveId] = useState<string | null>(null);
  const [activeDragData, setActiveDragData] = useState<any>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [openPopoverId, setOpenPopoverId] = useState<string | null>(null);
  const [localNonMatchingSkillTags, setLocalNonMatchingSkillTags] = useState<
    SkillTag[]
  >([]);

  const preventDragging = (e: React.MouseEvent | React.TouchEvent) => {
    e.stopPropagation();
  };

  const languagesAndFrameworksSubCategories = useMemo(
    () => [
      "Frameworks & Libraries",
      "Mobile Development",
      "Programming Languages",
      "CSS Preprocessors",
      "CSS Frameworks",
      "JavaScript Frameworks",
      "Machine Learning",
      "Testing",
      "Frontend Development",
    ],
    [],
  );

  const technologiesSubCategories = useMemo(
    () => [
      "Software Architecture & System Design",
      "Cybersecurity & Compliance",
      "API Design",
      "Site Reliability Engineering",
      "Containerization & Virtualization",
      "Authentication & Authorization",
      "Infrastructure as Code",
      "API Management",
      "Cloud Platforms",
      "Protocols",
      "Networking",
      "Application Servers",
      "Build & Dependency Management",
      "Data Engineering",
      "Infrastructure & Operations",
      "Security & Quality Assurance",
      "Version Control & Collaboration",
      "Monitoring & Observability",
      "DevOps",
      "Machine Learning & AI",
      "Messaging & Event Streaming",
      "Operating Systems",
      "Architectural Patterns",
    ],
    [],
  );

  const databaseSubCategories = useMemo(
    () => [
      "Databases & Data Management",
      "Data Processing",
      "Database Management",
    ],
    [],
  );

  const methodsAndToolsSubCategories = useMemo(
    () => [
      "Development Tools",
      "Productivity Tools",
      "UI/UX Design",
      "Business & Specialized Skills",
      "IDE & Development Tools",
      "Agile Methodologies",
    ],
    [],
  );

  const containerRef = useRef<HTMLDivElement>(null);

  const { mutate: getSkillCategory, variables } = useGetSkillCategories({
    onSuccess: (response, vars) => {
      if (response.length === 0) {
        //Custom skill

        handleAddSkill(vars.body.skills[0], "Other");
      }
      if (response.length > 0) {
        const skillCategory = response[0];
        if (
          languagesAndFrameworksSubCategories.includes(
            skillCategory.subCategory,
          )
        ) {
          handleAddSkill(skillCategory.skill, "Languages and frameworks");
        } else if (
          technologiesSubCategories.includes(skillCategory.subCategory)
        ) {
          handleAddSkill(skillCategory.skill, "Technologies");
        } else if (
          methodsAndToolsSubCategories.includes(skillCategory.subCategory)
        ) {
          handleAddSkill(skillCategory.skill, "Tools and methods");
        } else if (databaseSubCategories.includes(skillCategory.subCategory)) {
          handleAddSkill(skillCategory.skill, "Databases");
        } else {
          handleAddSkill(skillCategory.skill, "Other");
        }
      }
    },
    onError: (error) => {},
  });

  useEffect(() => {
    skillsAddedFromDialog.forEach((skill) => {
      if (
        skills
          .flatMap((block) => block.skillTags.map((skillTag) => skillTag))
          .filter((sk) => sk.name === skill.name).length === 0
      ) {
        getSkillCategory({ body: { skills: [skill.name] } });
      }
    });
  }, [skillsAddedFromDialog]);

  const preventDrawerInteraction = (e: Event) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5,
      },
    }),
  );

  useEffect(() => {
    setLocalNonMatchingSkillTags(nonMatchingSkillTags);
  }, [nonMatchingSkillTags]);

  useEffect(() => {
    if (linkConfigData.skillsSummaryConfig?.skillCategoryBlocks) {
      setSkills(linkConfigData.skillsSummaryConfig?.skillCategoryBlocks);
    }
  }, [linkConfigData]);

  // Add click event listener to close popover when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      // Skip if no popover is open
      if (!openPopoverId) return;

      // Check if click was outside the popover content
      const target = event.target as Node;
      const popoverContent = document.querySelector(".popover-content");

      // Don't close if clicking on popover content or its children
      if (popoverContent && popoverContent.contains(target)) {
        return;
      }

      // Close popover if click was outside
      setOpenPopoverId(null);
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openPopoverId]);

  const checkIfEmpty = (skillCategoryBlocks: SkillCategoryBlock[]): boolean => {
    // Return true if ALL categories are empty (have no skill tags)
    // Return false if ANY category has at least one skill tag
    return skillCategoryBlocks.every(
      (category) => category.skillTags.length === 0,
    );
  };
  const handleRemoveSkill = (categoryTitle: string, skillName: string) => {
    if (categoryTitle === "") {
      const updatedSkills = skills.map((categoryBlock) => ({
        ...categoryBlock,
        skillTags: categoryBlock.skillTags.filter(
          (skillTag) => skillTag.name !== skillName,
        ),
      }));
      setSkills(updatedSkills);
      // Update via the API
      handleUpdateLink({
        skillsSummaryConfig: {
          ...linkConfigData?.skillsSummaryConfig,
          skillCategoryBlocks: updatedSkills,
        },
      });

      //Add the skill back to the non matching skills if it should be there
      if (
        nonMatchingSkillTags.filter((st) => st.name === skillName).length > 0
      ) {
        setLocalNonMatchingSkillTags([
          nonMatchingSkillTags.filter((st) => st.name === skillName)[0],
          ...localNonMatchingSkillTags,
        ]);
      }

      if (leadSkillTags.filter((st) => st.name === skillName).length > 0) {
        setLocalNonMatchingSkillTags([
          leadSkillTags.filter((st) => st.name === skillName)[0],
          ...localNonMatchingSkillTags,
        ]);
      }
      return;
    }

    // Find the category and remove the skill
    const updatedSkills = skills.map((category) => {
      if (category.title === categoryTitle) {
        return {
          ...category,
          skillTags:
            category.skillTags?.filter((tag) => tag.name !== skillName) || [],
        };
      }
      return category;
    });

    //Add the skill back to the non matching skills if it should be there
    if (nonMatchingSkillTags.filter((st) => st.name === skillName).length > 0) {
      setLocalNonMatchingSkillTags([
        nonMatchingSkillTags.filter((st) => st.name === skillName)[0],
        ...localNonMatchingSkillTags,
      ]);
    }

    if (leadSkillTags.filter((st) => st.name === skillName).length > 0) {
      setLocalNonMatchingSkillTags([
        leadSkillTags.filter((st) => st.name === skillName)[0],
        ...localNonMatchingSkillTags,
      ]);
    }

    setSkills(updatedSkills);
    // Update via the API
    handleUpdateLink({
      skillsSummaryConfig: {
        ...linkConfigData?.skillsSummaryConfig,
        skillCategoryBlocks: updatedSkills,
      },
    });
  };

  const missingSkillThatCanBeDisplayed = () => {
    const allSkillTags = skills.reduce((acc, block) => {
      return [...acc, ...block.skillTags];
    }, [] as SkillTag[]);

    return localNonMatchingSkillTags.filter(
      (nonMatchingSkill) =>
        !allSkillTags.some(
          (existingSkill) => existingSkill.name === nonMatchingSkill.name,
        ),
    );
  };

  const handleAddSkillFromDialog = (skillToAdd: string) => {};

  const handleAddSkill = (skillToAdd: string, categoryName: string) => {
    var skillTagToAdd: SkillTag = { name: "", customIconName: "" };
    if (
      localNonMatchingSkillTags.filter(
        (s) => s.name.toLowerCase() === skillToAdd.toLowerCase(),
      ).length > 0
    ) {
      skillTagToAdd = localNonMatchingSkillTags.filter(
        (s) => s.name.toLowerCase() === skillToAdd.toLowerCase(),
      )[0];
    } else if (
      skillsAddedFromDialog.filter((s) => s.name === skillToAdd).length > 0
    ) {
      skillTagToAdd = skillsAddedFromDialog.filter(
        (s) => s.name === skillToAdd,
      )[0];
    }

    const updatedSkills: SkillCategoryBlock[] = skills.map((category) => {
      if (category.title === categoryName) {
        const sk = [...category.skillTags, skillTagToAdd];

        return {
          ...category,
          skillTags: sk,
        };
      }
      return category;
    });

    // Update via the API
    handleUpdateLink({
      skillsSummaryConfig: {
        ...linkConfigData?.skillsSummaryConfig,
        skillCategoryBlocks: updatedSkills,
      },
    });

    setLocalNonMatchingSkillTags((prev) =>
      prev.filter((s) => s.name !== skillToAdd),
    );

    setSkills(updatedSkills);

    if (skillsAddedFromDialog.length > 0) {
      onAddedCustomSkills();
    }
  };

  const handleDragStart = (event: DragStartEvent) => {
    setIsDragging(true);
    const { active } = event;
    setActiveId(active.id.toString());

    // Add global event listeners
    document.addEventListener("touchmove", preventDrawerInteraction, {
      passive: false,
    });
    document.addEventListener("mousemove", preventDrawerInteraction, {
      passive: false,
    });

    const [categoryTitle, skillName] = active.id.toString().split("-");
    const category = skills.find((c) => c.title === categoryTitle);
    const skillTag = category?.skillTags?.find((tag) => tag.name === skillName);

    setActiveDragData(skillTag);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    document.removeEventListener("touchmove", preventDrawerInteraction);
    document.removeEventListener("mousemove", preventDrawerInteraction);

    setIsDragging(false);

    setIsDragging(false);
    setActiveId(null);
    setActiveDragData(null);

    const { active, over } = event;
    if (!over) return;

    const activeId = active.id.toString();
    const overId = over.id.toString();

    // Extract category and skill names
    const [activeCategory, activeSkill] = activeId.split("-");
    const isOverCategory = !overId.includes("-");
    const overCategory = isOverCategory ? overId : overId.split("-")[0];
    const overSkill = isOverCategory ? null : overId.split("-")[1];

    // If moving within the same category
    if (activeCategory === overCategory) {
      const category = skills.find((c) => c.title === activeCategory);
      if (!category?.skillTags) return;

      const oldIndex = category.skillTags.findIndex(
        (tag) => tag.name === activeSkill,
      );

      let newIndex;
      if (isOverCategory) {
        // If dropping on category, move to end
        newIndex = category.skillTags.length - 1;
      } else {
        // If dropping on specific skill
        newIndex = category.skillTags.findIndex(
          (tag) => tag.name === overSkill,
        );
      }

      if (oldIndex === newIndex) return;

      // Create new array and move item
      const newSkillTags = [...category.skillTags];
      const [movedItem] = newSkillTags.splice(oldIndex, 1);
      newSkillTags.splice(newIndex, 0, movedItem);

      const newSkills = skills.map((c) =>
        c.title === activeCategory ? { ...c, skillTags: newSkillTags } : c,
      );

      setSkills(newSkills);
      handleUpdateLink({
        skillsSummaryConfig: {
          ...linkConfigData?.skillsSummaryConfig,
          skillCategoryBlocks: newSkills,
        },
      });
      return;
    }

    const newSkills = skills.map((category) => {
      // Remove from source category
      if (category.title === activeCategory) {
        return {
          ...category,
          skillTags:
            category.skillTags?.filter((tag) => tag.name !== activeSkill) || [],
        };
      }

      // Add to destination category
      if (category.title === overCategory) {
        const movedSkill = skills
          .find((c) => c.title === activeCategory)
          ?.skillTags?.find((tag) => tag.name === activeSkill);

        if (movedSkill) {
          const newSkillTags = [...(category.skillTags || [])];

          if (isOverCategory || !overSkill) {
            // Add to end if dropping on category
            newSkillTags.push(movedSkill);
          } else {
            // Insert at specific position if dropping on a skill
            const targetIndex = category.skillTags?.findIndex(
              (tag) => tag.name === overSkill,
            );

            // If target index is found, insert at that position
            if (targetIndex !== undefined && targetIndex !== -1) {
              newSkillTags.splice(targetIndex, 0, movedSkill);
            } else {
              // Fallback to adding at the end
              newSkillTags.push(movedSkill);
            }
          }

          return {
            ...category,
            skillTags: newSkillTags,
          };
        }
      }

      return category;
    });

    setSkills(newSkills);

    handleUpdateLink({
      skillsSummaryConfig: {
        ...linkConfigData?.skillsSummaryConfig,
        skillCategoryBlocks: newSkills,
      },
    });
  };

  return (
    <div className="flex flex-col grow gap-6" ref={containerRef}>
      <div className="flex flex-row justify-between items-center">
        <BulletSectionTitle>Skill stack</BulletSectionTitle>
        <FontAwesomeIcon
          width={20}
          height={20}
          icon={faCirclePlus}
          className="text-red-3 w-6 h-6 hover:scale-125 transition-transform duration-200"
          onClick={() => {
            onShowAddSkillsDialog(
              skills.flatMap((block) =>
                block.skillTags.map((skillTag) => skillTag),
              ),
            );
          }}
        />
      </div>

      {missingSkillThatCanBeDisplayed().length > 0 ? (
        <div className="bg-red-1 border-2 border-dashed border-red-2 flex flex-col p-2 rounded-lg overflow-x-scroll">
          <span className="text-gray-6 text-sm">
            Missing skills (tap one to add)
          </span>
          <div className="flex flex-row gap-1">
            {missingSkillThatCanBeDisplayed().map((skill) => {
              return (
                <EditorSkillTagButton
                  profileSkillTag={skill}
                  text={skill.name}
                  disableEditing={true}
                  handleClick={() => {
                    getSkillCategory({ body: { skills: [skill.name] } });
                  }}
                  keepLeftPadding={true}
                  isStatic={true}
                  displayInfoOnHover={true}
                />
              );
            })}
          </div>
        </div>
      ) : null}
      <div className="flex flex-row items-start gap-4">
        <Checkbox
          checked={linkConfigData?.skillsSummaryConfig?.displaySkillsSummary}
          disabled={disabled}
          onCheckedChange={(checked) => {
            const isChecked = Boolean(checked);
            handleUpdateLink({
              skillsSummaryConfig: {
                ...linkConfigData?.skillsSummaryConfig,
                displaySkillsSummary: isChecked,
              },
            });
          }}
        />

        <ProjectSettingsContainer
          className="w-full"
          enabled={
            linkConfigData?.skillsSummaryConfig?.displaySkillsSummary ?? false
          }
        >
          {linkConfigData.skillsSummaryConfig?.sortedMode !== "" ? (
            <DndContext
              sensors={sensors}
              collisionDetection={pointerWithin}
              onDragEnd={handleDragEnd}
              modifiers={[restrictToWindowEdges]}
              onDragStart={(event) => {
                event.active.data.current?.event?.preventDefault();
                event.active.data.current?.event?.stopPropagation();
                handleDragStart(event);
                // Close any open popover when starting a drag
                setOpenPopoverId(null);
              }}
            >
              <div className="flex flex-col gap-4 mb-4 justify-between">
                {skills.map((category) => (
                  <div key={category.title} className="flex-1 min-w-0">
                    <h3 className="text-md font-semibold mb-2">
                      {category.title}
                    </h3>
                    <div className="relative">
                      <DroppableArea
                        category={category}
                        isDragging={isDragging}
                      >
                        {category.skillTags
                          .sort((a, b) => a.name.localeCompare(b.name))
                          .map((skillTag) => {
                            const skillId = `${category.title}-${skillTag.name}`;
                            return (
                              <SortableItem
                                key={skillId}
                                id={skillId}
                                skillTag={skillTag}
                                categoryId={category.title}
                                isPopoverOpen={openPopoverId === skillId}
                                onPopoverChange={(open) => {
                                  if (open) {
                                    setOpenPopoverId(skillId);
                                  } else if (openPopoverId === skillId) {
                                    setOpenPopoverId(null);
                                  }
                                }}
                                onRemove={() =>
                                  handleRemoveSkill(
                                    category.title,
                                    skillTag.name,
                                  )
                                }
                                showIcons={
                                  linkConfigData.skillsSummaryConfig
                                    ?.displayIcons ?? false
                                }
                              />
                            );
                          })}
                        {(!category.skillTags ||
                          category.skillTags.length === 0) && (
                          <div className="text-gray-400 italic w-full text-center">
                            Drop skills here
                          </div>
                        )}
                      </DroppableArea>
                    </div>
                  </div>
                ))}
              </div>
              {createPortal(
                <DragOverlay dropAnimation={defaultDropAnimation}>
                  {activeId && activeDragData ? (
                    <EditorSkillTagButton
                      profileSkillTag={activeDragData}
                      text={activeDragData.name}
                      disableEditing={true}
                      handleClick={() => {}}
                      keepLeftPadding={true}
                      isStatic={true}
                    />
                  ) : null}
                </DragOverlay>,
                document.body,
              )}
            </DndContext>
          ) : (
            <div className="flex flex-row flex-wrap w-full gap-1">
              {skills
                .flatMap((block) => block.skillTags.map((skillTag) => skillTag))
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((skill, index) => {
                  return (
                    <SortableItem
                      key={skill.name}
                      id={skill.name}
                      skillTag={skill}
                      categoryId={""}
                      isPopoverOpen={openPopoverId === skill.name}
                      onPopoverChange={(open) => {
                        if (open) {
                          setOpenPopoverId(skill.name);
                        } else if (openPopoverId === skill.name) {
                          setOpenPopoverId(null);
                        }
                      }}
                      onRemove={() => handleRemoveSkill("", skill.name)}
                      showIcons={
                        linkConfigData.skillsSummaryConfig?.displayIcons ??
                        false
                      }
                    />
                  );
                })}
            </div>
          )}
        </ProjectSettingsContainer>
      </div>
    </div>
  );
};
