import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import type {
  AccountProfile,
  Lead,
  PostV1SkillsCategoriesResponse,
  Profile,
  ProfileLinkConfig,
  SkillCategoryBlock,
  SkillTag,
} from "../../../../../client";
import {
  useGetLink,
  useGetProfile,
  useGetSkillCategories,
  useUpdateLink,
} from "../../../../../services";
import { LinkCreatorPersonalBlock } from "./LinkCreatorPersonalBlock";
import { LinkCreatorProjects } from "./LinkCreatorProjects";
import { LinkCreatorSideMenu } from "./LinkCreatorSideMenu";
import { LinkCreatorWork } from "./LinkCreatorWork";
import { LinkCreatorEducationBlock } from "./LinkCreatorEducationBlock";
import { LinkCreatorSkills } from "./LinkCreatorSkills";
import { getStatsigClient } from "../../../../../statsig";
import { useGetOrganizationProfileSkills } from "../../../../../services/organtization/skills/get-organization-profile-skills";
import { StatsigClient } from "@statsig/js-client";
import { useStatsigClient } from "../../../../../hooks";
import { useResetLink } from "../../../../../services/link/reset-link";

interface Props {
  lead: Lead;
  organizationId: string;
  onShowAddSkillsDialog: (skillTags: SkillTag[]) => void;
  skillsAddedFromDialog: SkillTag[];
  onAddedCustomSkills: () => void;
}

type OrganizedSkills = {
  mainCategory: string;
  skills: string[];
}[];

export const LinkCreatorDrawerContent = memo(
  ({
    lead,
    organizationId,
    onShowAddSkillsDialog,
    skillsAddedFromDialog,
    onAddedCustomSkills,
  }: Props) => {
    const { data: profile } = useGetProfile(
      {
        organizationId,
        profileId: lead.profileId ?? "",
      },
      {
        enabled: !!lead.profileId,
      },
    );

    const { data: link } = useGetLink(lead.profileLinkId ?? "", {
      enabled: !!lead.profileLinkId,
    });

    // Fix profileData extraction with proper null checking
    const profileData = link?.profile_data as Profile | undefined;

    const { data: profileSkillMatch } = useGetOrganizationProfileSkills(
      organizationId,
      lead?.profileId ?? "",
      lead.assignmentInfo?.skillTags?.map((skill) => skill.name),
      {
        enabled: !!lead?.profileId,
      },
    );

    const { mutate: updateLink, variables, reset } = useUpdateLink();

    // Reset variables when component mounts or when link changes
    useEffect(() => {
      reset();
    }, [reset, link?.profileLinkId]);

    const mergedLinkConfig = useMemo(() => {
      if (!link?.link_config_data) return undefined;

      // Only merge if there's an active mutation
      if (
        variables?.body &&
        variables?.path.profileLink === link.profileLinkId
      ) {
        return {
          ...link.link_config_data,
          ...variables.body,
        };
      }

      return link.link_config_data;
    }, [link?.link_config_data, variables, link?.profileLinkId]);

    // Use mergedLinkConfig instead of creating link_config_data directly
    const link_config_data = mergedLinkConfig;

    // Use mergedLinkConfig instead of creating link_config_data directly

    const handleUpdateLink = (update: Partial<ProfileLinkConfig>) => {
      if (!lead.profileLinkId) return;

      if (!link?.link_config_data) return;
      updateLink({
        path: { profileLink: lead.profileLinkId },
        body: {
          ...link.link_config_data,
          ...update,
        },
      });
    };

    const disabled = false;

    if (!link_config_data) {
      return <div></div>;
    }

    return (
      <div className="flex flex-col xl:flex-row gap-8 xl:gap-32 p-4 sm:px-10 sm:py-12 overflow-y-auto max-w-full">
        <div className="flex flex-col flex-1 gap-10 h-max min-w-0">
          <LinkCreatorPersonalBlock
            linkConfigData={link_config_data}
            disabled={disabled}
            handleUpdateLink={handleUpdateLink}
            profile={profile}
            organizationId={organizationId}
            leadId={lead.id!}
            linkId={lead?.profileLinkId!}
            assignmentInfoAdded={!!lead.assignmentInfo}
          />

          {link_config_data &&
          link_config_data.skillsSummaryConfig?.placeInTop === true ? (
            <LinkCreatorSkills
              disabled={false}
              linkConfigData={link_config_data}
              handleUpdateLink={handleUpdateLink}
              profileData={profileData}
              nonMatchingSkillTags={lead.nonMatchingSkillTags ?? []}
              onAddSkill={(newSkillName: string) => {}}
              leadSkillTags={lead.assignmentInfo?.skillTags ?? []}
              onShowAddSkillsDialog={onShowAddSkillsDialog}
              skillsAddedFromDialog={skillsAddedFromDialog}
              onAddedCustomSkills={onAddedCustomSkills}
            />
          ) : null}

          <LinkCreatorWork
            profileData={profileData}
            linkConfigData={link_config_data}
            handleUpdateLink={handleUpdateLink}
          />

          <LinkCreatorProjects
            profileData={profileData}
            linkConfigData={link_config_data}
            handleUpdateLink={handleUpdateLink}
          />

          <LinkCreatorEducationBlock
            disabled={disabled}
            linkConfigData={link_config_data}
            handleUpdateLink={handleUpdateLink}
            organizationId={organizationId}
            leadId={lead.id!}
            linkId={lead.profileLinkId!}
            profileData={profileData}
          />

          {link_config_data &&
          !link_config_data.skillsSummaryConfig?.placeInTop ? (
            <LinkCreatorSkills
              disabled={false}
              linkConfigData={link_config_data}
              handleUpdateLink={handleUpdateLink}
              profileData={profileData}
              nonMatchingSkillTags={lead.nonMatchingSkillTags ?? []}
              onAddSkill={(newSkillName: string) => {}}
              leadSkillTags={lead.assignmentInfo?.skillTags ?? []}
              onShowAddSkillsDialog={onShowAddSkillsDialog}
              skillsAddedFromDialog={skillsAddedFromDialog}
              onAddedCustomSkills={onAddedCustomSkills}
            />
          ) : null}
        </div>

        <LinkCreatorSideMenu
          disabled={disabled}
          linkConfig={link_config_data}
          onUpdate={handleUpdateLink}
          organizationId={organizationId}
          leadId={lead.id!}
          linkId={lead.profileLinkId!}
        />
      </div>
    );
  },
);
