import type { PropsWithChildren } from "react";
import { cn } from "../utils/cn";
import { SmartPopoverClose, SmartPopoverContent } from "./SmartPopover";

type Props = PropsWithChildren<{
  className?: string;
}>;

export const SmartPopoverMenu = ({ children, className }: Props) => (
  <SmartPopoverContent className={cn("flex flex-col p-4", className)}>
    {children}
  </SmartPopoverContent>
);

type ItemProps = PropsWithChildren<{
  onClick: () => void;
  className?: string;
}>;

const SmartPopoverMenuItem = ({ children, className, onClick }: ItemProps) => (
  <SmartPopoverClose asChild>
    <button
      type="button"
      onClick={onClick}
      className={cn(
        "flex flex-row items-center gap-2 rounded-2xl px-4 py-5 text-sm transition-all hover:bg-gray-1 active:scale-95",
        className,
      )}
    >
      {children}
    </button>
  </SmartPopoverClose>
);

SmartPopoverMenu.Item = SmartPopoverMenuItem;

SmartPopoverMenu.displayName = "PopoverMenu";
