import "../App.css";
import VkImageCropper from "./legacy/VkImageCropper";
import { useState, useEffect, useRef } from "react";
import PersonalDetailsSection from "../components/PersonalDetailsSection";
import { useNavigate } from "react-router-dom";
import type {
  Profile,
  SectionTypeDescription,
  RolesSection,
  SkillsSection,
  SideProjectsSession,
  ProfileTag,
  Skill,
  ProfileSkillTag,
  Role,
  Workplace,
  SideProject,
  ImageUpload,
  AccountProfile,
  ProjectPhoto,
  WorkplaceItem,
  EducationItem,
} from "../types";
import {
  fetchProfile,
  updateProfile,
  deleteSection,
  updateProfileFlag,
  signUpFromTryProfile,
  updateProfilePhoto,
} from "../requests";
import SideProjectSection from "../components/SideProjectSection";
import "../components/components.css";
import SectionOptionItem from "../components/SectionOptionItem";
import SignUpBottomBanner from "../components/SignUpBottomBanner";
import { Briefcase, PenTool, Box, Phone, Mail } from "react-feather";
import { Dialog, DialogTitle } from "@mui/material";
import { darkModeBlack } from "../colors";

import LinkButton from "../components/LinkButton";
import {
  categoryForName,
  categoryTitleForCategory,
} from "../components/TechIcons";

import DateInputDialog from "./dialogs/DateInputDialog";
import TextInputDialog from "./dialogs/TextInputDialog";
import TagsInputDialog from "./dialogs/TagsInputDialog";
import ProjectPhotoViewer from "../components/ProjectPhotoViewer";
import WorkplaceSection from "../components/WorkplaceSection";
import WorkplaceDatesDialog from "./dialogs/WorkplaceDatesDialog";
import EditorTitle from "../components/EditorTitle";
import { getOrganizationOptions } from "../services";
import { useQueryClient } from "@tanstack/react-query";
import EducationSection from "./EducationSection";

interface Props {
  profileId: string;
  onPhotoUpdated?: (newPhotoAssetId: string) => void;
}

function Editor(props: Props) {
  const navigate = useNavigate();

  const [showAddSectionDialog, setShowAddSectionDialog] = useState(false);

  const [showInputDialog, setShowInputDialog] = useState(false);
  const [showWorkplaceInputDialog, setShowWorkplaceInputDialog] =
    useState(false);
  const [showWorkplaceDatesInputDialog, setShowWorkplaceDatesInputDialog] =
    useState(false);
  const [showEducationDatesInputDialog, setShowEducationDatesInputDialog] =
    useState(false);
  const [showTagsInputDialog, setShowTagsInputDialog] = useState(false);
  const [showWorkplaceTagsInputDialog, setShowWorkplaceTagsInputDialog] =
    useState<boolean>(false);
  const [showAddProfileTagDialog, setShowAddProfileTagDialog] = useState(false);
  const [showWelcomeDialog, setShowWelcomeDialog] = useState(false);
  const [showCropTool, setShowCropTool] = useState(false);
  const [darkModeActive, setDarkModeActive] = useState<boolean>(false);
  const [useProfilePicture, setUseProfilePicture] = useState<boolean>(true);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [selectedProjectPhotoAssetId, setSelectedProjectPhotoAssetId] =
    useState<string>("");
  const [viewDialogFullScreen, setViewDialogFullScreen] =
    useState<boolean>(false);
  const [isOnTryAccount, setisOnTryAccount] = useState<boolean>(false);
  const [didSignUpFromTryAccount, setDidSignUpFromTryAccount] =
    useState<boolean>(false);
  const [didDismissBottomBanner, setDidDismissBottomBanner] =
    useState<boolean>(false);
  const [updatedPhotoId, setUpdatedPhotoId] = useState<string | null>(null);
  const [isUploadingPhoto, setIsUploadingPhoto] = useState<boolean>(false);
  const [sortedWorkplaces, setSortedWorkplaces] = useState<WorkplaceItem[]>([]);

  const [currentlyUsedSkillTags, setCurrentlyUsedSkillTags] = useState<
    string[]
  >([]);

  const [currentlySelectedWorkplace, setCurrentlySelectedWorkplace] = useState<
    Workplace | null | undefined
  >(null);
  const [activeProjectOrder, setActiveProjectOrder] = useState<number>(-1);
  const [showProjectSidebar, setShowProjectSidebar] = useState<boolean>(false);
  const [accountProfile, setAccountProfile] = useState<AccountProfile | null>(
    null,
  );

  const [selectedWorkplaceId, setSelectedWorkplaceId] = useState<string>("");
  const [selectedEducationId, setSelectedEducationId] = useState<string>("");

  const [currentProfileSideProjects, setCurrentProfileSideProjects] = useState<
    SideProject[]
  >([]);
  const [profile, setProfile] = useState<Profile>({
    id: "",
    profile_name: "",
    name: "",
    title: "",
    pitch: "",
    ui_config: { dark_mode: false, use_profile_picture: true },
    roles_section: { order: 0, title: "", roles: [] },
    skills_section: { order: 0, title: "", skill_blocks: [] },
    side_projects_section: { order: 0, title: "", side_projects: [] },
    profile_tags: [],
    workplaces: [],
    educations: [],
  });

  const infoBoxRef = useRef<HTMLDivElement>(null);

  const queryClient = useQueryClient();

  useEffect(() => {
    if (props.profileId !== undefined) {
      fetchProfile(props.profileId).then((accountProfile) => {
        setProfile(accountProfile.profile);

        setDarkModeActive(accountProfile.profile.ui_config.dark_mode);
        setUseProfilePicture(
          accountProfile.profile.ui_config.use_profile_picture,
        );
        setCurrentProfileSideProjects(
          accountProfile.profile.side_projects_section?.side_projects || [],
        );

        setAccountProfile(accountProfile);

        if (accountProfile.profile.workplaces) {
          setSortedWorkplaces(
            accountProfile.profile.workplaces.sort((a, b) => a.order - b.order),
          );
        }
      });
    }
  }, []);

  const updateWorkplaceAndSave = (updatedWorkplace: WorkplaceItem) => {
    const otherWps = profile.workplaces.filter(
      (x) => x.id !== updatedWorkplace.id,
    );
    otherWps.push(updatedWorkplace);
    profile.workplaces = otherWps;
    saveAndRefresh(profile);
  };

  const updateEducationAndSave = (updatedEducation: EducationItem) => {
    const otherEducations = profile.educations.filter(
      (x) => x.id !== updatedEducation.id,
    );
    otherEducations.push(updatedEducation);
    profile.educations = otherEducations;
    saveAndRefresh(profile);
  };

  function getActiveProject() {
    if (profile.side_projects_section) {
      return profile.side_projects_section.side_projects[activeProjectOrder];
    }
    return null;
  }

  function getSelectedWorkplace() {
    if (profile.workplaces) {
      return profile.workplaces.filter(
        (wp) => wp.id === selectedWorkplaceId,
      )[0];
    }
    return null;
  }

  function getNonActiveProjects() {
    if (activeProjectOrder === -1) {
      return profile.side_projects_section?.side_projects;
    }

    if (profile.side_projects_section) {
      return profile.side_projects_section.side_projects.filter(
        (item, index) => {
          if (index !== activeProjectOrder) {
            return item;
          }
        },
      );
    }
  }

  function getNonActiveWorkplaces() {
    if (selectedWorkplaceId === "") {
      return profile.workplaces ?? [];
    }

    return profile.workplaces.filter((wp) => wp.id !== selectedWorkplaceId);
  }

  function parseJwt(token: string | null) {
    if (token) {
      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
          .join(""),
      );

      return JSON.parse(jsonPayload);
    }
  }

  function checkForNewSkillTags(roles: Role[] | null) {
    var roleSkillTags: ProfileSkillTag[] = [];
    roles?.map((role) => {
      role?.skillTags?.map((skill) => {
        if (!(roleSkillTags.filter((s) => s.name === skill.name).length > 0)) {
          roleSkillTags.push(skill);
        }
      });
    });

    //1. If a new skill is detected, get the category of it.
    //2. If the profile have a skill block of that category, add the skill to that block.
    //3. If the user does not have a skill block of that category, add a skill block of that category and add the skill to it.

    const skillBlocks = profile.skills_section?.skill_blocks;
    var skillSectionSkills: string[] = [];
    if (skillBlocks) {
      for (var i = 0; i < skillBlocks.length; i++) {
        for (var j = 0; j < skillBlocks[i].skills.length; j++) {
          const skillName = skillBlocks[i].skills[j].title;
          if (skillName !== "") {
            skillSectionSkills.push(skillBlocks[i].skills[j].title);
          }
        }
      }

      const newSkills = roleSkillTags
        .map((roleSkillTag) => {
          if (
            skillSectionSkills.filter(
              (sectionSkill) => sectionSkill === roleSkillTag.name,
            ).length === 0
          ) {
            return roleSkillTag.name;
          }
          return null;
        })
        .filter((item) => item !== null);
      var skillBlockCategoriesToCreate = new Map<string, string[]>();

      const currentSkillBlocks = profile.skills_section?.skill_blocks
        ? [...profile.skills_section?.skill_blocks!]
        : [];

      for (var i = 0; i < newSkills.length; i++) {
        const categoryName = categoryForName(newSkills[i]!);
        if (
          skillBlocks.filter((block) => block.skillCategory === categoryName)
            .length === 0
        ) {
          if (!skillBlockCategoriesToCreate.has(categoryName ?? "Other")) {
            skillBlockCategoriesToCreate.set(categoryName ?? "Other", [
              newSkills[i]!,
            ]);
          } else {
            var currentSkillsInCategory = skillBlockCategoriesToCreate.get(
              categoryName ?? "Other",
            );
            currentSkillsInCategory?.push(newSkills[i]!);
            skillBlockCategoriesToCreate.set(
              categoryName ?? "Other",
              currentSkillsInCategory!,
            );
          }
        } else {
          const skillBlockInCategory = currentSkillBlocks.filter(
            (block) => block.skillCategory === categoryName,
          )[0];
          const indexOfSkillBlockForCategory =
            currentSkillBlocks.indexOf(skillBlockInCategory);
          skillBlockInCategory.skills = [
            ...skillBlockInCategory.skills,
            {
              title: newSkills[i]!,
              order: skillBlockInCategory.skills.length,
              skill_level: 1,
              isPlaceholder: false,
            },
          ];
          currentSkillBlocks[indexOfSkillBlockForCategory] =
            skillBlockInCategory;
        }
      }

      skillBlockCategoriesToCreate.forEach((value: string[], key: string) => {
        currentSkillBlocks.push({
          title: categoryTitleForCategory(key),
          skills: value.map((skill, index) => {
            return {
              title: skill,
              order: index,
              skill_level: 1,
              isPlaceholder: false,
            };
          }) as Skill[],
          order: currentSkillBlocks.length,
          skillCategory: key,
        });
      });

      if (!profile.skills_section) {
        const section: SkillsSection = {
          order: getSectionsInOrder().length,
          title: "Skills",
          skill_blocks: [],
        };
        profile.skills_section = section;
      }

      profile.skills_section!.skill_blocks = currentSkillBlocks;

      return currentSkillBlocks;
    }

    if (profile.skills_section) {
      return profile.skills_section!.skill_blocks;
    }
    return null;
  }

  const getAvailableProfileTags = () => {
    const currentProfileTags = profile.profile_tags ?? [];
    const allProfileTagTypes = ["phone", "email"];
    const availableProfileTags = allProfileTagTypes.filter((tag) => {
      return (
        currentProfileTags.filter(
          (currentProfileTag) => currentProfileTag.tag_type === tag,
        ).length === 0
      );
    });
    return availableProfileTags;
  };

  const getIconForTagType = (tagType: string) => {
    if (tagType === "phone") {
      return <Phone />;
    }

    if (tagType === "email") {
      return <Mail />;
    }
  };

  const getAvailableSectionsToAdd = () => {
    const availableSections: SectionTypeDescription[] = [];

    if (profile.roles_section === null || profile.roles_section === undefined) {
      const role: SectionTypeDescription = {
        title: "Work Experience",
        info: "Describe your previous roles and workplaces",
        icon: <Briefcase size={38} />,
        type: "roles",
        order: 0,
      };
      availableSections.push(role);
    }

    if (
      profile.skills_section === null ||
      profile.skills_section === undefined
    ) {
      const skills: SectionTypeDescription = {
        title: "Skills",
        info: "Express your skills",
        icon: <PenTool size={38} />,
        type: "skills",
        order: 0,
      };
      availableSections.push(skills);
    }

    if (
      profile.side_projects_section === null ||
      profile.side_projects_section === undefined
    ) {
      const skills: SectionTypeDescription = {
        title: "Projects",
        info: "Add any other project that does not fit under work experience",
        icon: <Box size={38} />,
        type: "side-projects",
        order: 0,
      };
      availableSections.push(skills);
    }

    return availableSections;
  };

  const getSectionsInOrder = () => {
    const availableSections: SectionTypeDescription[] = [];
    if (profile.roles_section !== null && profile.roles_section !== undefined) {
      const role: SectionTypeDescription = {
        title: "Work Experience",
        info: "Describe your previous roles and workplaces",
        icon: <Briefcase size={38} />,
        type: "roles",
        order: profile.roles_section!.order,
      };
      availableSections.push(role);
    }

    if (
      profile.skills_section !== null &&
      profile.skills_section !== undefined
    ) {
      const skills: SectionTypeDescription = {
        title: "Skills",
        info: "Express your skills",
        icon: <PenTool size={38} />,
        type: "skills",
        order: profile.skills_section!.order,
      };
      availableSections.push(skills);
    }

    if (
      profile.side_projects_section !== null &&
      profile.side_projects_section !== undefined
    ) {
      const skills: SectionTypeDescription = {
        title: "Projects",
        info: "Add any other project that does not fit under work experience",
        icon: <Box size={38} />,
        type: "side-projects",
        order: profile.side_projects_section!.order,
      };
      availableSections.push(skills);
    }

    const sectionsInOrder = availableSections.sort((a, b) => a.order - b.order);
    return sectionsInOrder;
  };

  const getSectionTypeAtOrder = (order: number) => {
    const sectionsInOrder = getSectionsInOrder();
    return sectionsInOrder[order].type;
  };

  const onShowCropTool = (image: any) => {
    setUploadedImage(image);
    setShowCropTool(true);
  };

  const onImageCropperCancelled = () => {
    setUploadedImage(null);
    setShowCropTool(false);
  };

  const saveAndRefresh = (updatedProfile: Profile) => {
    setProfile(updatedProfile);
    updateProfile(updatedProfile).then((newProfileData) => {
      setProfile(newProfileData);
      if (newProfileData.workplaces) {
        setSortedWorkplaces(
          newProfileData.workplaces.sort((a, b) => a.order - b.order),
        );
      }
    });
  };

  if (profile.id === "" || accountProfile === null) {
    return <span />;
  }

  if (showCropTool) {
    return (
      <VkImageCropper
        originalImage={uploadedImage}
        onImageCropperCancelled={onImageCropperCancelled}
        onImageCropperComplete={(base64ImageString: string) => {
          const formatInfo = base64ImageString.split(",")[0];
          const base64String = base64ImageString.split(",")[1];
          const format = formatInfo.split(";")[0].split(":")[1];

          const imageUpload: ImageUpload = {
            fileType: format,
            base64File: base64String,
          };

          setIsUploadingPhoto(true);

          updateProfilePhoto(profile.id, imageUpload)
            .then((newPhotoAssetId: string) => {
              if (props.onPhotoUpdated) {
                props.onPhotoUpdated(newPhotoAssetId);
              }
              setUpdatedPhotoId(newPhotoAssetId);
              setIsUploadingPhoto(false);
            })
            .catch((error) => {
              setIsUploadingPhoto(false);
            });

          setShowCropTool(false);
        }}
      />
    );
  }

  return (
    <div className="flex-1">
      <div id="editor-wrapper">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        ></div>

        <div
          className="profile-editor-content"
          style={{
            paddingBottom: "150px",
            maxWidth: showProjectSidebar ? 920 : 818,
          }}
        >
          <div id="content" style={{ minWidth: "100%" }}>
            <PersonalDetailsSection
              isDarkModeActive={darkModeActive}
              roundedProfilePicture={false}
              profilePhotoId={accountProfile.profilePhotoId}
              profile={profile}
              isEditable={true}
              onShowCropTool={onShowCropTool}
              onProfileUpdate={(updatedProfile: Profile) => {
                setProfile(updatedProfile);
                saveAndRefresh(updatedProfile);
              }}
              useProfilePicture={true}
              onAddProfileTagClick={() => {
                setShowAddProfileTagDialog(true);
              }}
              onProfileTagsUpdated={(updatedTags: ProfileTag[]) => {
                const updatedProfile = {
                  ...profile,
                  profile_tags: updatedTags,
                };
                saveAndRefresh(updatedProfile);
              }}
              hidePitch={false}
              updatedPhotoId={updatedPhotoId}
              isUploadingPhoto={isUploadingPhoto}
            />

            <div className="mt-8">
              <EditorTitle title="Work experience" />
            </div>

            <WorkplaceSection
              key="workplace-section"
              configurable={false}
              workplaces={sortedWorkplaces}
              sideProjectSection={profile.side_projects_section!}
              isDarkModeActive={darkModeActive}
              sectionCount={getSectionsInOrder().length}
              isEditable={true}
              onAddItem={(newWorkplace: WorkplaceItem) => {
                let currentWorkplaces = profile.workplaces;
                if (!currentWorkplaces) {
                  newWorkplace.order = 0;
                  currentWorkplaces = [newWorkplace];
                } else {
                  newWorkplace.order = currentWorkplaces.length;
                  currentWorkplaces.push(newWorkplace);
                }
                profile.workplaces = currentWorkplaces;

                saveAndRefresh(profile);
              }}
              onUpdateItem={(updatedWorkplace) => {
                const otherWps =
                  profile.workplaces?.filter(
                    (x) => x.id !== updatedWorkplace.id,
                  ) ?? [];
                otherWps.push(updatedWorkplace);

                profile.workplaces = otherWps;
                saveAndRefresh(profile);
              }}
              onRemoveItem={(workplaceToRemove) => {
                const otherWps = profile.workplaces?.filter(
                  (x) => x.id !== workplaceToRemove.id,
                );

                profile.workplaces = otherWps;
                saveAndRefresh(profile);
              }}
              onDeleteSectionClick={() => {
                const updatedProfile = Object.assign({}, profile, {
                  side_projects_section: null,
                });
                setProfile(updatedProfile);

                deleteSection(profile.id, "side-projects").then(
                  (response) => {},
                );
              }}
              onShowDialog={(dialogType: string, workplaceId: string) => {
                setSelectedWorkplaceId(workplaceId);
                if (dialogType === "workplace") {
                  setShowWorkplaceInputDialog(true);
                } else if (dialogType === "tags") {
                  setShowWorkplaceTagsInputDialog(true);
                } else if (dialogType === "workplace-dates") {
                  setShowWorkplaceDatesInputDialog(true);
                } else {
                  setShowInputDialog(true);
                }
              }}
              onProjectOrderChange={(sideProjects: SideProject[]) => {
                const updatedProfile = {
                  ...profile,
                  side_projects_section: {
                    ...profile.side_projects_section!,
                    side_projects: sideProjects,
                  },
                };
                saveAndRefresh(updatedProfile);
              }}
            />
            <EditorTitle title="Projects" />

            <SideProjectSection
              key="project-section"
              profileId={profile.id}
              configurable={false}
              sideProjectSection={profile.side_projects_section!}
              isDarkModeActive={darkModeActive}
              sectionCount={getSectionsInOrder().length}
              isEditable={true}
              workplaces={profile.workplaces ?? []}
              onAddSideProject={(newSideProject) => {
                newSideProject.order =
                  profile.side_projects_section!.side_projects.length;

                profile.side_projects_section!.side_projects.push(
                  newSideProject,
                );

                saveAndRefresh(profile);
              }}
              onUpdateSideProject={(updatedSideProject) => {
                const sideProjects = [
                  ...profile.side_projects_section!.side_projects,
                ];
                sideProjects[updatedSideProject.order] = updatedSideProject;
                profile.side_projects_section!.side_projects = sideProjects;
                saveAndRefresh(profile);
              }}
              onRemoveSideProject={(sideProjectToRemove) => {
                const currentSideProjects =
                  profile.side_projects_section!.side_projects.filter(
                    (sp) => sp.order !== sideProjectToRemove.order,
                  );
                profile.side_projects_section!.side_projects =
                  currentSideProjects;
                saveAndRefresh(profile);
              }}
              onDeleteSectionClick={() => {
                const updatedProfile = Object.assign({}, profile, {
                  side_projects_section: null,
                });
                setProfile(updatedProfile);

                deleteSection(profile.id, "side-projects").then(
                  (response) => {},
                );
              }}
              onShowDialog={(dialogType: string, projectOrder: number) => {
                setActiveProjectOrder(projectOrder);
                if (dialogType === "workplace") {
                  setShowWorkplaceInputDialog(true);
                } else if (dialogType === "tags") {
                  setShowTagsInputDialog(true);
                } else {
                  setShowInputDialog(true);
                }
              }}
              currentlySelectedWorkplace={currentlySelectedWorkplace}
              onProjectOrderChange={(sideProjects: SideProject[]) => {
                const updatedProfile = {
                  ...profile,
                  side_projects_section: {
                    ...profile.side_projects_section!,
                    side_projects: sideProjects,
                  },
                };
                saveAndRefresh(updatedProfile);
              }}
              projectPhotos={accountProfile.projectPhotos}
              onProjectPhotoSelect={(assetId: string) => {
                setSelectedProjectPhotoAssetId(assetId);
              }}
              onProjectPhotosUpdate={(updatedProjectPhotos: ProjectPhoto[]) => {
                setAccountProfile({
                  ...accountProfile,
                  projectPhotos: updatedProjectPhotos,
                });
              }}
              onMoveProjectUp={(projectOrder: number) => {
                if (projectOrder <= 0) return;

                const currentProjects = [
                  ...profile.side_projects_section!.side_projects,
                ];

                // Swap the order numbers of this project and the one above it
                const projectToMove = currentProjects[projectOrder];
                const projectAbove = currentProjects[projectOrder - 1];

                // Swap their order properties
                const tempOrder = projectToMove.order;
                projectToMove.order = projectAbove.order;
                projectAbove.order = tempOrder;

                // Swap their positions in the array
                currentProjects[projectOrder] = projectAbove;
                currentProjects[projectOrder - 1] = projectToMove;

                // Update the profile and save
                profile.side_projects_section!.side_projects = currentProjects;
                saveAndRefresh(profile);
              }}
              onMoveProjectDown={(projectOrder: number) => {
                const currentProjects = [
                  ...profile.side_projects_section!.side_projects,
                ];

                // Can't move down if it's already last
                if (projectOrder >= currentProjects.length - 1) return;

                // Swap the order numbers of this project and the one below it
                const projectToMove = currentProjects[projectOrder];
                const projectBelow = currentProjects[projectOrder + 1];

                // Swap their order properties
                const tempOrder = projectToMove.order;
                projectToMove.order = projectBelow.order;
                projectBelow.order = tempOrder;

                // Swap their positions in the array
                currentProjects[projectOrder] = projectBelow;
                currentProjects[projectOrder + 1] = projectToMove;

                // Update the profile and save
                profile.side_projects_section!.side_projects = currentProjects;
                saveAndRefresh(profile);
              }}
            />
            <EditorTitle title="Educations, courses & certifications" />

            <EducationSection
              key="education-section"
              educations={profile.educations}
              isDarkModeActive={darkModeActive}
              sectionCount={getSectionsInOrder().length}
              isEditable={true}
              onAddItem={(newEducation: EducationItem) => {
                let currentEducations = profile.educations;
                if (!currentEducations) {
                  newEducation.order = 0;
                  currentEducations = [newEducation];
                } else {
                  newEducation.order = currentEducations.length;
                  currentEducations.push(newEducation);
                }
                profile.educations = currentEducations;

                saveAndRefresh(profile);
              }}
              onUpdateItem={(updatedEducation) => {
                const otherEducations =
                  profile.educations?.filter(
                    (x) => x.id !== updatedEducation.id,
                  ) ?? [];
                otherEducations.push(updatedEducation);

                profile.educations = otherEducations;
                saveAndRefresh(profile);
              }}
              onRemoveItem={(educationToRemove) => {
                const otherEducations = profile.educations?.filter(
                  (x) => x.id !== educationToRemove.id,
                );

                profile.educations = otherEducations;
                saveAndRefresh(profile);
              }}
              onDeleteSectionClick={() => {
                const updatedProfile = Object.assign({}, profile, {
                  side_projects_section: null,
                });
                setProfile(updatedProfile);

                deleteSection(profile.id, "side-projects").then(
                  (response) => {},
                );
              }}
              onShowDialog={(dialogType: string, educationId: string) => {
                setSelectedEducationId(educationId);
                if (dialogType === "workplace") {
                  setShowWorkplaceInputDialog(true);
                } else if (dialogType === "tags") {
                  setShowWorkplaceTagsInputDialog(true);
                } else if (dialogType === "workplace-dates") {
                  setShowWorkplaceDatesInputDialog(true);
                } else if (dialogType === "education-dates") {
                  setShowEducationDatesInputDialog(true);
                } else {
                  setShowInputDialog(true);
                }
              }}
              onMoveUp={(order: number) => {
                if (order <= 0) return;

                const currentEducations = [...profile.educations];

                // Swap the order numbers of this project and the one above it
                const projectToMove = currentEducations[order];
                const projectAbove = currentEducations[order - 1];

                // Swap their order properties
                const tempOrder = projectToMove.order;
                projectToMove.order = projectAbove.order;
                projectAbove.order = tempOrder;

                // Swap their positions in the array
                currentEducations[order] = projectAbove;
                currentEducations[order - 1] = projectToMove;

                // Update the profile and save
                profile.educations = currentEducations;
                saveAndRefresh(profile);
              }}
              onMoveDown={(order: number) => {
                const currentEducations = [...profile.educations];

                // Can't move down if it's already last
                if (order >= currentEducations.length - 1) return;

                // Swap the order numbers of this project and the one below it
                const projectToMove = currentEducations[order];
                const projectBelow = currentEducations[order + 1];

                // Swap their order properties
                const tempOrder = projectToMove.order;
                projectToMove.order = projectBelow.order;
                projectBelow.order = tempOrder;

                // Swap their positions in the array
                currentEducations[order] = projectBelow;
                currentEducations[order + 1] = projectToMove;

                // Update the profile and save
                profile.educations = currentEducations;
                saveAndRefresh(profile);
              }}
            />

            <Dialog
              open={showAddProfileTagDialog}
              onBackdropClick={() => setShowAddProfileTagDialog(false)}
            >
              <DialogTitle
                style={{
                  backgroundColor: darkModeActive ? darkModeBlack : "#fff",
                  color: darkModeActive ? "#fff" : darkModeBlack,
                }}
              >
                Add a new tag
              </DialogTitle>
              {getAvailableProfileTags().map((tagType, index) => {
                return (
                  <SectionOptionItem
                    isDarkModeActive={darkModeActive}
                    key={index}
                    title={tagType}
                    info={""}
                    icon={getIconForTagType(tagType)}
                    onSelect={() => {
                      const currentProfileTags = profile.profile_tags ?? [];
                      const newTag: ProfileTag = {
                        order: currentProfileTags.length,
                        value: "",
                        tag_type: tagType,
                      };
                      profile.profile_tags = [...currentProfileTags, newTag];
                      saveAndRefresh(profile);
                      setShowAddProfileTagDialog(false);
                    }}
                  />
                );
              })}
            </Dialog>

            {selectedProjectPhotoAssetId !== "" ? (
              <div
                className="bg-op-dark absolute top-0 left-0 w-full h-full bg-opacity-50"
                onClick={() => setSelectedProjectPhotoAssetId("")}
              >
                <div
                  className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-8 rounded-lg max-h-[90vh] overflow-y-auto min-w-[600px] max-w-[600px]"
                  onClick={(e) => e.stopPropagation()}
                >
                  <ProjectPhotoViewer
                    profileId={profile.id}
                    projectPhotos={accountProfile.projectPhotos}
                    isEditable={true}
                    onToggleFullScreen={(toggleFullSceenOn: boolean) => {
                      setViewDialogFullScreen(toggleFullSceenOn);
                    }}
                    assetId={selectedProjectPhotoAssetId}
                    onProjectPhotosUpdate={(
                      updatedProjectPhotos: ProjectPhoto[],
                    ) => {
                      setAccountProfile({
                        ...accountProfile,
                        projectPhotos: updatedProjectPhotos,
                      });
                    }}
                    onClose={() => {
                      setSelectedProjectPhotoAssetId("");
                      setViewDialogFullScreen(false);
                    }}
                  />
                </div>
              </div>
            ) : null}

            <Dialog
              open={showAddSectionDialog}
              onBackdropClick={() => setShowAddSectionDialog(false)}
            >
              <DialogTitle
                style={{
                  backgroundColor: darkModeActive ? darkModeBlack : "#fff",
                  color: darkModeActive ? "#fff" : darkModeBlack,
                }}
              >
                Add a new section
              </DialogTitle>
              {getAvailableSectionsToAdd().map((sectionType, index) => {
                return (
                  <SectionOptionItem
                    isDarkModeActive={darkModeActive}
                    key={index}
                    title={sectionType.title}
                    info={sectionType.info}
                    icon={sectionType.icon}
                    onSelect={() => {
                      if (sectionType.type === "roles") {
                        const rolesSection: RolesSection = {
                          title: "Work Experience",
                          order: getSectionsInOrder().length,
                          roles: [],
                        };
                        profile.roles_section = rolesSection;
                      } else if (sectionType.type === "skills") {
                        const skillsSection: SkillsSection = {
                          title: "Skills",
                          order: getSectionsInOrder().length,
                          skill_blocks: [],
                        };
                        profile.skills_section = skillsSection;
                      } else if (sectionType.type === "side-projects") {
                        const skillsSection: SideProjectsSession = {
                          title: "Projects",
                          order: getSectionsInOrder().length,
                          side_projects: [],
                        };
                        profile.side_projects_section = skillsSection;
                      }
                      saveAndRefresh(profile);
                      setShowAddSectionDialog(false);
                    }}
                  />
                );
              })}
            </Dialog>

            <DateInputDialog
              open={showInputDialog}
              onBackdropClick={() => setShowInputDialog(false)}
              darkModeActive={darkModeActive}
            />
            <TextInputDialog
              open={showWorkplaceInputDialog}
              onClose={() => setShowWorkplaceInputDialog(false)}
              onBackdropClick={() => setShowWorkplaceInputDialog(false)}
              darkModeActive={darkModeActive}
              workplaces={profile.workplaces ?? []}
              onCancel={() => {
                setShowWorkplaceInputDialog(false);
              }}
              isSideProject={getActiveProject()?.isSideProject ?? false}
              onSave={(workplace: WorkplaceItem) => {
                if (activeProjectOrder !== -1) {
                  const activeProject: SideProject = {
                    ...profile.side_projects_section!.side_projects[
                      activeProjectOrder
                    ],
                  };
                  if (activeProject) {
                    activeProject.workplaceId = workplace.id;
                    const currentProjects = [
                      ...profile.side_projects_section!.side_projects,
                    ];
                    currentProjects[activeProjectOrder] = activeProject;
                    profile.side_projects_section!.side_projects =
                      currentProjects;
                    const newProfile = Object.assign({}, profile, {
                      side_projects_section: { side_projects: currentProjects },
                    });
                    saveAndRefresh(newProfile);
                  }
                  setActiveProjectOrder(-1);
                }
                setShowWorkplaceInputDialog(false);
              }}
              onSetAsSideProject={(checked: boolean) => {
                const activeProject = getActiveProject();
                if (activeProject) {
                  activeProject.isSideProject = checked;
                  const currentProjects = [
                    ...profile.side_projects_section!.side_projects,
                  ];
                  currentProjects[activeProjectOrder] = activeProject;
                  profile.side_projects_section!.side_projects =
                    currentProjects;
                  const newProfile = Object.assign({}, profile, {
                    side_projects_section: { side_projects: currentProjects },
                  });
                  saveAndRefresh(newProfile);
                }
              }}
            />

            {showWorkplaceDatesInputDialog ? (
              <WorkplaceDatesDialog
                open={true}
                onCancel={() => {}}
                onClose={() => setShowWorkplaceDatesInputDialog(false)}
                onBackdropClick={() => setShowWorkplaceDatesInputDialog(false)}
                darkModeActive={false}
                onDateUpdate={(startDate: string, endDate: string) => {
                  const selectedWorkplace = profile.workplaces?.filter(
                    (x) => x.id === selectedWorkplaceId,
                  )[0];
                  if (!selectedWorkplace) return;

                  selectedWorkplace.startDate = startDate;
                  selectedWorkplace.endDate = endDate;
                  updateWorkplaceAndSave(selectedWorkplace);
                }}
                selectedStartDate={
                  profile.workplaces?.filter(
                    (x) => x.id === selectedWorkplaceId,
                  )[0]?.startDate ?? ""
                }
                selectedEndDate={
                  profile.workplaces?.filter(
                    (x) => x.id === selectedWorkplaceId,
                  )[0]?.endDate ?? ""
                }
              />
            ) : null}

            {showEducationDatesInputDialog ? (
              <WorkplaceDatesDialog
                open={true}
                onCancel={() => {}}
                onClose={() => setShowEducationDatesInputDialog(false)}
                onBackdropClick={() => setShowEducationDatesInputDialog(false)}
                darkModeActive={false}
                onDateUpdate={(startDate: string, endDate: string) => {
                  const selectedEducation = profile.educations?.filter(
                    (x) => x.id === selectedEducationId,
                  )[0];
                  if (!selectedEducation) return;

                  selectedEducation.startDate = startDate;
                  selectedEducation.endDate = endDate;
                  updateEducationAndSave(selectedEducation);
                }}
                selectedStartDate={
                  profile.educations?.filter(
                    (x) => x.id === selectedEducationId,
                  )[0]?.startDate ?? ""
                }
                selectedEndDate={
                  profile.educations?.filter(
                    (x) => x.id === selectedEducationId,
                  )[0]?.endDate ?? ""
                }
              />
            ) : null}

            {showTagsInputDialog ? (
              <TagsInputDialog
                open={true}
                onBackdropClick={() => setShowInputDialog(false)}
                darkModeActive={darkModeActive}
                onCancel={() => {
                  setShowWorkplaceInputDialog(false);
                }}
                onSave={(skillTags: ProfileSkillTag[]) => {
                  const activeProject: SideProject | null = getActiveProject();

                  activeProject!.skillTags = skillTags;
                  const currentProjects = [
                    ...profile.side_projects_section!.side_projects,
                  ];
                  currentProjects[activeProjectOrder] = activeProject!;
                  profile.side_projects_section!.side_projects =
                    currentProjects;
                  saveAndRefresh(profile);
                }}
                selectedProfileSkillTags={
                  getActiveProject() ? getActiveProject()!.skillTags : []
                }
                onClose={() => {
                  setActiveProjectOrder(-1);
                  setShowTagsInputDialog(false);
                }}
              />
            ) : (
              <div />
            )}
            {showWorkplaceTagsInputDialog ? (
              <TagsInputDialog
                open={true}
                onBackdropClick={() => setShowWorkplaceTagsInputDialog(false)}
                darkModeActive={darkModeActive}
                onCancel={() => {
                  setShowWorkplaceTagsInputDialog(false);
                }}
                onSave={(skillTags: ProfileSkillTag[]) => {
                  if (selectedWorkplaceId !== "") {
                    const selectedWorkplace: WorkplaceItem =
                      profile.workplaces?.filter(
                        (wp) => wp.id === selectedWorkplaceId,
                      )[0];

                    selectedWorkplace.skillTags = skillTags;

                    updateWorkplaceAndSave(selectedWorkplace);

                    return;
                  }
                }}
                selectedProfileSkillTags={
                  getSelectedWorkplace() &&
                  getSelectedWorkplace()?.skillTags != undefined
                    ? getSelectedWorkplace()!.skillTags
                    : []
                }
                onClose={() => {
                  setSelectedWorkplaceId("");
                  setShowWorkplaceTagsInputDialog(false);
                }}
              />
            ) : (
              <div />
            )}
          </div>
          <div id="right-side-panel"></div>
        </div>
      </div>

      {isOnTryAccount ||
      (didSignUpFromTryAccount && !didDismissBottomBanner) ? (
        <SignUpBottomBanner
          isDarkModeActive={darkModeActive}
          onSubmitClick={(email: string) => {
            signUpFromTryProfile(email).then((_) => {
              setDidSignUpFromTryAccount(true);
              setisOnTryAccount(false);
            });
          }}
          onDismissClick={() => setDidDismissBottomBanner(true)}
          signUpSuccessfull={didSignUpFromTryAccount}
        />
      ) : null}
    </div>
  );
}

export default Editor;
