import { useMemo } from "react";
import type {
  Profile,
  ProfileLinkConfig,
  WorkplaceItem,
} from "../../../../../client";
import { BulletSectionTitle } from "../../../../../components";
import { Workplace } from "./Workplace";

interface Props {
  profileData?: Profile;
  linkConfigData: ProfileLinkConfig;
  handleUpdateLink: (data: Partial<ProfileLinkConfig>) => void;
}

export const LinkCreatorWork = ({
  profileData,
  linkConfigData,
  handleUpdateLink,
}: Props) => {
  const sortedWorkplaces = useMemo(() => {
    const editedWorkplaces = linkConfigData?.editedWorkplaces ?? [];

    return profileData?.workplaces.map((wp) => {
      const editedWorkplace = editedWorkplaces.find((w) => w.id === wp.id);
      return editedWorkplace ?? wp;
    });
  }, [profileData?.workplaces, linkConfigData?.editedWorkplaces]);

  const handleCheckedChange = (checked: boolean, workplaceId: string) => {
    const currentIds = new Set(
      linkConfigData.profileLinkWorkplaceIds ??
        profileData?.workplaces.map((workplace) => workplace.id),
    );

    if (checked) {
      currentIds.add(workplaceId);
    } else {
      currentIds.delete(workplaceId);
    }

    handleUpdateLink({ profileLinkWorkplaceIds: Array.from(currentIds) });
  };

  const handleUpdatedWorkplace = (workplace: WorkplaceItem) => {
    if (!linkConfigData) {
      return;
    }

    if (!linkConfigData.editedWorkplaces) {
      linkConfigData.editedWorkplaces = [];
    }

    var currentEditedWorkplaces = [...linkConfigData.editedWorkplaces];

    var found = false;
    for (var i = 0; i < currentEditedWorkplaces.length; i++) {
      if (currentEditedWorkplaces[i].id === workplace.id) {
        currentEditedWorkplaces[i] = workplace;
        found = true;
      }
    }

    if (!found) {
      currentEditedWorkplaces.push(workplace);
    }

    handleUpdateLink({ editedWorkplaces: currentEditedWorkplaces });
  };

  return (
    profileData?.workplaces && (
      <div className="flex flex-col gap-4">
        <BulletSectionTitle>Work experience</BulletSectionTitle>
        <div className="flex flex-col gap-6">
          {sortedWorkplaces &&
            sortedWorkplaces.map((workplace) => (
              <Workplace
                key={workplace.id}
                checked={
                  !linkConfigData.profileLinkWorkplaceIds ||
                  linkConfigData.profileLinkWorkplaceIds?.includes(workplace.id)
                }
                onCheckedChange={(checked) =>
                  handleCheckedChange(checked, workplace.id)
                }
                name={workplace.name}
                skillTags={workplace.skillTags}
                title={workplace.role}
                description={workplace.description}
                startDate={workplace.startDate}
                endDate={workplace.endDate}
                showSkills={linkConfigData.workplaceLinkConfig?.displaySkills}
                showDescription={
                  linkConfigData.workplaceLinkConfig?.displayDescription
                }
                isEditiable={true}
                onWorkplaceEdit={(description: string) => {
                  var updatedWorkplace = { ...workplace };
                  updatedWorkplace.description = description;
                  handleUpdatedWorkplace(updatedWorkplace);
                }}
                isEdited={
                  linkConfigData?.editedWorkplaces?.find(
                    (w) => w.id === workplace.id,
                  ) !== undefined
                }
              />
            ))}
        </div>
      </div>
    )
  );
};
