import type { ProfileComponentProps, ProfileSkillTag } from "../../types";
import "../components.css";
import { getIconByName, iconForName } from "../TechIcons";
import { useState } from "react";
import { getSkillInfo } from "../../requests";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TooltipProvider } from "@radix-ui/react-tooltip";
import { Tooltip, TooltipContent, TooltipTrigger } from "../../components";

interface Props extends ProfileComponentProps {
  text: string;
  profileSkillTag?: ProfileSkillTag;
  handleClick: () => void;
  disableEditing?: boolean;
  keepLeftPadding?: boolean;
  pdfMode?: boolean;
  isStatic?: boolean;
  hideIcon?: boolean;
  displayInfoOnHover?: boolean;
}

const EditorSkillTagButton = (props: Props) => {
  const [isHovering, setIsHovering] = useState(false);
  const [skillDescription, setSkillDescription] = useState<string | null>(null);

  const renderIcon = () => {
    if (props.profileSkillTag) {
      if (iconForName(props.profileSkillTag?.name)) {
        return iconForName(props.profileSkillTag?.name);
      }
      if (props.profileSkillTag?.customIconName) {
        if (iconForName(props.profileSkillTag?.customIconName)) {
          return iconForName(props.profileSkillTag?.customIconName);
        }
        if (getIconByName(props.profileSkillTag?.customIconName)) {
          const customIcon = getIconByName(
            props.profileSkillTag?.customIconName,
          );
          return <FontAwesomeIcon icon={customIcon!} size="lg" />;
        }
      }
    }

    if (iconForName(props.text)) {
      return iconForName(props.text);
    }

    return iconForName("DefaultTechIcon");
  };

  var classNames = "editor-skill-tag-button-static";

  if (props.isDarkModeActive) {
    classNames += " editor-skill-tag-button-light light-color";
  }

  const handleMouseEnter = async () => {
    setIsHovering(true);

    if (!skillDescription) {
      getSkillInfo(props.text)
        .then((skillInfo) => {
          setSkillDescription(skillInfo);
        })
        .catch((error) => {
          setSkillDescription(
            "We don´t have any more information about this skill at the moment but we are working on it!",
          );
        });
    }
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  if (props.displayInfoOnHover === true) {
    return (
      <TooltipProvider delayDuration={300}>
        <Tooltip>
          <TooltipTrigger asChild>
            <div
              className={`${classNames} transform transition-transform hover:scale-110`}
              onClick={props.handleClick}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              {!props.hideIcon ? (
                <div
                  style={{
                    marginTop: "2px",
                    width: props.text === "" ? "0px" : "20px",
                    marginRight: props.text === "" ? "0px" : "3px",
                    visibility: props.hideIcon ? "collapse" : "visible",
                  }}
                >
                  {renderIcon()}
                </div>
              ) : null}

              <label
                className="text-xs whitespace-nowrap"
                style={
                  props.pdfMode ? { marginBottom: 15 } : { marginBottom: 0 }
                }
              >
                {props.text}
              </label>
            </div>
          </TooltipTrigger>
          <TooltipContent className="max-w-[300px]">
            <p className="whitespace-pre-wrap break-words text-sm">
              {skillDescription}
            </p>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  }

  return (
    <div
      className={`${classNames} transform transition-transform hover:scale-110`}
      onClick={props.handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {!props.hideIcon ? (
        <div
          style={{
            marginTop: "2px",
            width: props.text === "" ? "0px" : "20px",
            marginRight: props.text === "" ? "0px" : "3px",
            visibility: props.hideIcon ? "collapse" : "visible",
          }}
        >
          {renderIcon()}
        </div>
      ) : null}

      <label
        className="text-xs whitespace-nowrap"
        style={props.pdfMode ? { marginBottom: 15 } : { marginBottom: 0 }}
      >
        {props.text}
      </label>
    </div>
  );
};

export default EditorSkillTagButton;
