import type { ProfileComponentProps, ProfileSkillTag } from "../../types";
import "../components.css";
import { getIconByName, iconForName } from "../TechIcons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props extends ProfileComponentProps {
  text: string;
  profileSkillTag?: ProfileSkillTag;
  keepLeftPadding?: boolean;
  pdfMode?: boolean;
  isStatic?: boolean;
}

const SkillsIcon = (props: Props) => {
  const renderIcon = () => {
    if (props.profileSkillTag) {
      if (iconForName(props.profileSkillTag?.name)) {
        return iconForName(props.profileSkillTag?.name);
      }
      if (props.profileSkillTag?.customIconName) {
        if (iconForName(props.profileSkillTag?.customIconName)) {
          return iconForName(props.profileSkillTag?.customIconName);
        }
        if (getIconByName(props.profileSkillTag?.customIconName)) {
          const customIcon = getIconByName(
            props.profileSkillTag?.customIconName,
          );
          return <FontAwesomeIcon icon={customIcon!} size="lg" />;
        }
      }
    }

    if (iconForName(props.text)) {
      return iconForName(props.text);
    }

    return iconForName("DefaultTechIcon");
  };

  return (
    <div>
      <div className="w-7 flex items-center justify-center">{renderIcon()}</div>
    </div>
  );
};

export default SkillsIcon;
