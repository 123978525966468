import * as VisuallyHidden from "@radix-ui/react-visually-hidden";
import { type PropsWithChildren, Suspense, useState } from "react";
import { useParams } from "react-router-dom";
import { useSnapshot } from "valtio";
import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerTitle,
} from "../../../../../components/drawer";
import { LoadingSpinner } from "../../../../../components/loading";
import {
  useGetClient,
  useGetLead,
  useGetProfile,
} from "../../../../../services";
import { homeState } from "../../../../../state/home";
import { LinkCreatorDrawerContent } from "./LinkCreatorDrawerContent";
import { LinkCreatorHeader } from "./LinkCreatorHeader";
import { useGetOrganizationProfileSkills } from "../../../../../services/organtization/skills/get-organization-profile-skills";
import TagsInputDialog from "../../../../../components/dialogs/TagsInputDialog";
import { ProfileSkillTag } from "../../../../../types";
import { SkillTag } from "../../../../../client";
import { useResetLink } from "../../../../../services/link/reset-link";

export const LinkCreatorDrawer = ({ children }: PropsWithChildren) => {
  const { organizationId } = useParams();
  if (!organizationId) throw new Error("Organization ID is required");

  const [showTagDialog, setShowTagDialog] = useState<boolean>(false);
  const [selectedSkillTags, setSelectedSkillTags] = useState<ProfileSkillTag[]>(
    [],
  );
  const { linkCreatorLeadId } = useSnapshot(homeState);
  const [skillsAddedFromDialog, setSkillsAddedFromDialog] = useState<
    SkillTag[]
  >([]);

  const { data: lead } = useGetLead(
    {
      leadID: linkCreatorLeadId ?? "",
      organizationID: organizationId,
    },
    { enabled: !!linkCreatorLeadId },
  );

  const { data: profile } = useGetProfile(
    {
      organizationId,
      profileId: lead?.profileId ?? "",
    },
    {
      enabled: !!lead?.profileId,
    },
  );

  const { data: client } = useGetClient(
    {
      organizationID: organizationId,
      clientId: lead?.clientId ?? "",
    },
    {
      enabled: !!lead?.clientId,
    },
  );

  const { mutate: resetLink, variables: linkVariables } = useResetLink();

  const handleResetLink = () => {
    if (lead && lead.profileLinkId) {
      resetLink({
        path: { profileLink: lead.profileLinkId },
      });
    }
  };

  const handleLinkCreatorClose = () => {
    // TODO: Save link
    homeState.linkCreatorLeadId = null;
  };

  return (
    <Drawer
      direction="right"
      open={!!linkCreatorLeadId}
      onClose={handleLinkCreatorClose}
      shouldScaleBackground={false}
    >
      {children}

      <DrawerContent
        className="min-w-[80%] max-w-[80%]"
        direction="right"
        data-vaul-no-drag
      >
        <VisuallyHidden.Root>
          <DrawerTitle>Edit link</DrawerTitle>
          <DrawerDescription>
            Edit the link for the Lead Profile
          </DrawerDescription>
        </VisuallyHidden.Root>
        {lead && (
          <>
            <LinkCreatorHeader
              profile={profile}
              client={client}
              handleClose={handleLinkCreatorClose}
              handleReset={handleResetLink}
            />
            <Suspense fallback={<Loading />}>
              <LinkCreatorDrawerContent
                lead={lead}
                organizationId={organizationId}
                onShowAddSkillsDialog={(currentSkillTags: SkillTag[]) => {
                  setShowTagDialog(true);

                  setSelectedSkillTags(
                    currentSkillTags.map((skillTag) => {
                      let ps: ProfileSkillTag = {
                        name: skillTag.name,
                        customIconName: skillTag.customIconName,
                      };
                      return ps;
                    }),
                  );
                }}
                skillsAddedFromDialog={skillsAddedFromDialog}
                onAddedCustomSkills={() => {
                  setSkillsAddedFromDialog([]);
                }}
              />
            </Suspense>
          </>
        )}
        {showTagDialog ? (
          <TagsInputDialog
            open={true}
            onBackdropClick={() => setShowTagDialog(false)}
            darkModeActive={false}
            onCancel={() => {
              setShowTagDialog(false);
            }}
            onSave={() => {}}
            onSkillAdded={(skillAdded: ProfileSkillTag) => {
              setSkillsAddedFromDialog([
                ...skillsAddedFromDialog,
                {
                  name: skillAdded.name,
                  customIconName: skillAdded.customIconName,
                },
              ]);
            }}
            selectedProfileSkillTags={[]}
            onClose={() => {
              setShowTagDialog(false);
            }}
          />
        ) : (
          <div />
        )}
      </DrawerContent>
    </Drawer>
  );
};

const Loading = () => (
  <div className="flex justify-center items-center h-full w-full">
    <LoadingSpinner size="small" />
  </div>
);
