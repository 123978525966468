import {
  faCircle,
  faQuestion,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import type { MouseEventHandler } from "react";
import { forwardRef } from "react";
import { cn } from "../../utils/cn";
import { IndicatorButton } from "../indicator-button";

type Size = "small" | "medium";

type BaseIndicatorProps = {
  onClick?: () => void;
};

type IconIndicatorProps = BaseIndicatorProps & {
  variant: "active" | "close";
};

type IndicatorProps = IconIndicatorProps;

interface Props {
  text: string;
  active?: boolean;
  size?: Size;
  indicatorProps?: IndicatorProps;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  className?: string;
  disableHover?: boolean; // Add this new prop
}

const TextButton = forwardRef<HTMLButtonElement, Props>(
  (
    {
      text,
      active,
      size = "medium",
      disableHover,
      className,
      indicatorProps,
      onClick,
    },
    ref,
  ) => (
    <button
      ref={ref}
      type="button"
      className={cn(
        "group relative flex items-center justify-center transition-all",
        size === "medium" && "w-14 h-14 p-2 min-w-[56px]",
        size === "small" && "w-8 h-8 p-1 min-w-[32px]",
        !disableHover && active && "p-0", // Only remove padding when not disableHover
        className,
      )}
      onClick={onClick}
    >
      <div
        className={cn(
          "bg-gray-5 absolute z-0 transition-all duration-200",
          size === "medium" && "top-2 left-2 right-2 bottom-2 rounded-2xl",
          size === "small" && "top-1 left-1 right-1 bottom-1 rounded-xl",
          !disableHover &&
            "group-hover:top-0 group-hover:bottom-0 group-hover:left-0 group-hover:right-0",
          active && "hidden",
        )}
      />
      <div
        className={cn(
          !disableHover && "group-active:scale-90", // Only apply scale effect when not disableHover
          "transition-all z-10 flex items-center justify-center w-full h-full",
          size === "medium" && "rounded-xl",
          size === "medium" && active && "rounded-2xl",
          size === "small" && "rounded-lg",
          "bg-gray-5",
        )}
      >
        <span
          className={cn(
            "text-white font-semibold transition-all",
            size === "medium" && "text-2xl",
            !disableHover && size === "medium" && active && "text-3xl", // Only increase text size when not disableHover
            size === "small" && "text-xl",
            !disableHover && size === "small" && active && "text-2xl", // Only increase text size when not disableHover
          )}
        >
          {text}
        </span>
      </div>
      {indicatorProps && (
        <IndicatorButton
          className={cn(
            "absolute top-0 right-0 z-10 overflow-hidden",
            indicatorProps.variant === "active" && "bg-gray-4",
          )}
          onClick={(e) => {
            // Prevent the click event from bubbling up to the parent button
            // if the indicator is a close button
            indicatorProps.variant === "close" && e.stopPropagation();
            indicatorProps.onClick?.();
          }}
        >
          <FontAwesomeIcon
            icon={
              indicatorProps.variant === "close"
                ? faXmark
                : indicatorProps.variant === "active"
                  ? faCircle
                  : faQuestion
            }
            className={cn(
              indicatorProps.variant === "close" && "text-black",
              indicatorProps.variant === "active" && "text-red-3 w-3 h-3",
            )}
          />
        </IndicatorButton>
      )}
    </button>
  ),
);

TextButton.displayName = "TextButton";

export { TextButton };
