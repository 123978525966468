import { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import type {
  Profile,
  ProfileLinkConfig,
  UserProfile,
} from "../../../../../client";
import {
  Avatar,
  BulletSectionTitle,
  Checkbox,
  Input,
} from "../../../../../components";
import { cn } from "../../../../../components/utils/cn";
import { getImageUrl } from "../../../../../utils/image";
import { ProjectSettingsContainer } from "./ProjectSettingsContainer";
import { useUpdateProfileLinkMotivation } from "../../../../../services/organtization/update-profile-link-motivation";
import { LeadRowCard } from "../card/LeadRowCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import EducationEditor from "../../../../../components/EducationEditor";
import { EducationItem } from "../../../../../types";

interface Props {
  linkConfigData: ProfileLinkConfig;
  disabled: boolean;
  handleUpdateLink: (data: Partial<ProfileLinkConfig>) => void;
  profile?: UserProfile;
  organizationId: string;
  leadId: string;
  linkId: string;
  profileData?: Profile;
}

export const LinkCreatorEducationBlock = ({
  linkConfigData,
  disabled,
  handleUpdateLink,
  profile,
  organizationId,
  leadId,
  linkId,
  profileData,
}: Props) => {
  const [customPitch, setCustomPitch] = useState(
    linkConfigData?.customPitch ?? "",
  );
  const [assignmentPitch, setAssignmentPitch] = useState(
    linkConfigData?.assignmentPitch ?? "",
  );
  const [isGeneratingAssigmentPitch, setIsGeneratingAssigmentPitch] =
    useState(false);

  useEffect(() => {
    if (linkConfigData?.assignmentPitch) {
      setAssignmentPitch(linkConfigData.assignmentPitch);
    }
  }, [linkConfigData]);

  const debounced = useDebouncedCallback<(val: string) => void>((value) => {
    handleUpdateLink({
      customPitch: value,
    });
  }, 500);

  const debouncedAssignmentPitch = useDebouncedCallback<(val: string) => void>(
    (value) => {
      handleUpdateLink({
        assignmentPitch: value,
      });
    },
    500,
  );

  const updateProfileAssignmentMotivation = useUpdateProfileLinkMotivation({
    onMutate: () => {
      setIsGeneratingAssigmentPitch(true);
    },
    onSuccess: (config) => {
      setIsGeneratingAssigmentPitch(false);
      if (config.assignmentPitch) {
        handleUpdateLink({ assignmentPitch: config.assignmentPitch });
        setAssignmentPitch(config.assignmentPitch);
      }
    },
    onSettled(data, error, variables, context) {
      setIsGeneratingAssigmentPitch(false);
    },
  });

  if (!profileData?.educations) return null;

  return (
    <div className="flex flex-col grow gap-6">
      <BulletSectionTitle>
        Education, courses & certifications
      </BulletSectionTitle>
      <div className="flex flex-row items-center gap-4">
        <Checkbox
          checked={linkConfigData?.displayEducationSection}
          disabled={disabled}
          onCheckedChange={(checked) => {
            if (typeof checked !== "boolean") return;

            handleUpdateLink({
              displayEducationSection: checked,
            });
          }}
        />

        <ProjectSettingsContainer
          className="flex w-full"
          enabled={linkConfigData?.displayEducationSection ?? false}
        >
          <div className="flex flex-col w-full">
            {profileData?.educations &&
              profileData.educations.map((education, index) => {
                return (
                  <EducationEditor
                    isEditable={false}
                    isDarkModeActive={false}
                    key={index}
                    isBeingEdited={true}
                    isNew={false}
                    education={education}
                    onSaveItem={(education: EducationItem) => {
                      //onUpdateSideProject(savedSideProject);
                    }}
                    onCancelEdit={() => {}}
                    onRemove={() => {}}
                    onShowDialog={() => {}}
                    isLastInOrder={false}
                    onContentImprovementStarted={() => {}}
                    onContentImprovementEnded={() => {}}
                    pdfMode={false}
                    removePadding={true}
                    staticMode={true}
                  />
                );
              })}
          </div>
        </ProjectSettingsContainer>
      </div>
    </div>
  );
};
