import { type ComponentProps, useMemo } from "react";
import { useGetProfile } from "../../../../../services";
import type { EventTemplate } from "./EventTemplate";
import type { EventProps } from "./types";
import {
  LEAD_CREATED_EVENT_TYPE,
  LEAD_PROFILE_CHANGE_EVENT_TYPE,
  PRIORITY_CHANGE,
  PROFILE_LINK_VISIT_EVENT_TYPE,
  PROFILE_LINK_VISIT_RECURRING_EVENT_TYPE,
  NOTES_CHANGE,
} from "./utils";

type TemplateProps = ComponentProps<typeof EventTemplate>;

export const useLeadEventProps = ({
  organizationId,
  triggeredByProfileId,
  createdAt,
  eventType,
  diff,
  visitorNumber,
}: EventProps) => {
  const { data } = useGetProfile(
    {
      organizationId,
      profileId:
        typeof diff?.newProfileId === "string" ? diff.newProfileId : "",
    },
    {
      enabled:
        eventType === LEAD_PROFILE_CHANGE_EVENT_TYPE && !!diff?.newProfileId,
    },
  );

  return useMemo(() => {
    const props: TemplateProps = {
      organizationId,
      profileId: triggeredByProfileId,
      createdAt,
      message: "",
    };

    switch (eventType) {
      case LEAD_CREATED_EVENT_TYPE:
        props.message = "Created this lead!";
        break;

      case LEAD_PROFILE_CHANGE_EVENT_TYPE:
        props.message = `Assigned ${data?.profileData.name || "someone"} to this lead!`;
        break;

      case NOTES_CHANGE:
        props.message = `Changed lead to notes to "${diff?.newNotes}"`;
        break;

      case PROFILE_LINK_VISIT_EVENT_TYPE:
        props.title = "New view!";
        props.message = diff?.city
          ? `Visitor #${visitorNumber || "?"} in ${diff.city} visited your profile!`
          : `Visitor #${visitorNumber || "?"} visited your profile!`;
        break;

      case PROFILE_LINK_VISIT_RECURRING_EVENT_TYPE:
        props.title = `New view from visitor #${visitorNumber}!`;
        props.message =
          (diff?.city
            ? `Visitor #${visitorNumber || "?"} in ${diff.city} visited your profile again!`
            : `Visitor #${visitorNumber || "?"} visited your profile again!`) +
          (diff?.visitCount
            ? ` This is their visit number ${diff.visitCount}.`
            : "");
        break;

      case PRIORITY_CHANGE:
        props.message = `Changed priority to ${
          diff?.newPriority === 0
            ? "cold"
            : diff?.newPriority === 1
              ? "medium"
              : diff?.newPriority === 2
                ? "hot"
                : "unknown"
        }`;
        break;
    }

    return props;
  }, [
    organizationId,
    triggeredByProfileId,
    createdAt,
    eventType,
    data?.profileData.name,
    diff?.city,
    diff?.visitCount,
    diff?.newPriority,
    visitorNumber,
  ]);
};
